//HOOKS
import {getCampaignStatusIcon, formatUnderscoreString} from '../../utils/helpers.js'

//ATOMS
import {ListGroup, Stack, Col} from 'react-bootstrap'

//MOLECULES
import {AudienceProgressBar} from './AudienceProgressBars.js'

export const CampaignListItem = ({
  onClick,
  campaignName,
  campaignStatus,
  campaignReviewReason,
  companiesSkipped,
  companiesSent,
  companiesInAudience
}) => {
  return (
    <ListGroup.Item
      as='li'
      className='transition-fast clickable t-scale--large t-box-shadow--shallow'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
      onClick={onClick}
    >
      <Col xs={2}>
        <Stack
          direction='horizontal'
          gap={1}
        >
          <span className={'material-symbols-outlined color--p-dark'}>
            {'campaign'}
          </span>
          <span
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {campaignName}
          </span>
        </Stack>
      </Col>
      <Col xs={8}>
        <AudienceProgressBar
          skipped={companiesSkipped}
          sent={companiesSent}
          audienceSize={companiesInAudience}
        />
      </Col>
      <Col xs={2}>
        <Stack
          direction='horizontal'
          gap={1}
        >
          <div className='p-1 ms-auto' />
          <span>{formatUnderscoreString(campaignStatus)}</span>
          {getCampaignStatusIcon(campaignStatus, campaignReviewReason)}
        </Stack>
      </Col>
    </ListGroup.Item>
  )
}
