import {useState} from 'react'

//ATOMS
import {Container, ListGroup} from 'react-bootstrap'
import PersonBoundingBoxIcon from '../atoms/assets/person-bounding-box.svg'
import BuildingIcon from '../atoms/assets/building.svg'
import {Link} from '../atoms/Link.js'

//Molecules
import {WarningIconWithTooltip} from '../molecules/WarningIconWithTooltip.js'
import {PlaceholderRow} from '../molecules/PlaceholderRow.js'
import {PersonRowItem} from '../molecules/PersonRowItem.js'
import {MoreInfoCanvas} from '../molecules/MoreInfoCanvas.js'

export const PeopleSearchResults = ({
  isLoading,
  error,
  searchResults,
  loadMoreCallback,
  hasNextPage,
  isFetchingNextPage
}) => {
  const [showCanvas, setShowCanvas] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState(false)

  if (isLoading) {
    return (
      <ListGroup variant='flush'>
        {[...Array(10)].map((_, index) => (
          <ListGroup.Item key={index}>
            <PlaceholderRow
              minHeight={'5rem'}
              maxHeight={'8rem'}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    )
  }

  if (error) {
    return <WarningIconWithTooltip message={error.message} />
  }

  if (searchResults) {
    return (
      <Container
        fluid
        style={{display: 'flex', flexDirection: 'column', padding: 0}}
      >
        <ListGroup variant='flush'>
          {searchResults.map((entry) => (
            <ListGroup.Item key={entry.id}>
              <PersonRowItem
                personName={`${entry?.person?.first_name} ${entry?.person?.last_name}`}
                personTitle={entry?.role_title}
                personLIProfileUrl={
                  entry?.person?.linkedin_info?.public_profile_url
                }
                personLocation={entry?.person?.location?.name}
                personImage={entry?.person?.photo || PersonBoundingBoxIcon}
                employerImage={entry?.organization?.logo || BuildingIcon}
                employerName={entry?.organization?.name}
                employerDescription={entry?.organization?.description}
                employerLinkedin={
                  entry?.organization?.linkedin_info?.public_identifier
                }
                employerWebsite={entry?.organization?.website}
                onClick={() => {
                  setSelectedPerson(entry)
                  setShowCanvas(true)
                }}
              />
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Link
          isLoading={isFetchingNextPage}
          children={'Load More'}
          onClick={loadMoreCallback}
          isEnabled={!hasNextPage}
          disabledMessage='No more results available'
        />
        <MoreInfoCanvas
          show={showCanvas}
          onHide={() => setShowCanvas(false)}
          headerImage={selectedPerson?.person?.photo || PersonBoundingBoxIcon}
          headerText={`${selectedPerson?.person?.first_name} ${selectedPerson?.person?.last_name}`}
          headerSubText={selectedPerson?.person?.headline}
          //   headerSubText={selectedPerson?.role_title}
          bodyText={
            selectedPerson?.person?.description || selectedPerson?.description
          }
        />
      </Container>
    )
  }
}
