//ATOMS
import Papa from 'papaparse'
import {Form} from 'react-bootstrap'

export const FileUploader = ({id, onFileLoaded}) => {
  const triggerFileInput = () => {
    document.getElementById(id).click()
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          const newValues = results.data.map((row) => row[0]).filter(Boolean)
          onFileLoaded(newValues)
        },
        error: (error) => {
          console.error('Error parsing CSV:', error.message)
        },
        skipEmptyLines: true
      })
    }
  }

  return (
    <>
      <span
        className={
          'material-symbols-outlined color--p-dark clickable transition-fast t-scale--small t-color--accent'
        }
        onClick={triggerFileInput}
      >
        {'upload'}
      </span>

      <Form.Control
        type='file'
        id={id}
        style={{display: 'none'}}
        accept='.csv'
        onChange={handleFileChange}
      />
    </>
  )
}
