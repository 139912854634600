//ATOMS
import {Row, Col, Container, Stack} from 'react-bootstrap'
import {Heading} from '../atoms/Heading.js'
import {Text} from '../atoms/Text.js'
import {Image} from '../atoms/Image.js'
import {Link} from '../atoms/Link.js'
import LinkedInIcon from '../atoms/assets/linkedin.svg'
import GlobeEAIcon from '../atoms/assets/globe-europe-africa.svg'

//Molecules
import {ImageWithHyperlink} from '../molecules/ImageWithHyperlink.js'

export const PersonRowItem = ({
  personImage,
  personName,
  personTitle,
  personLIProfileUrl,
  personLocation,
  employerImage,
  employerName,
  employerWebsite,
  employerDescription,
  employerLinkedin,
  onClick
}) => {
  return (
    <Container fluid>
      <Row style={{maxHeight: '8rem', minHeight: '5rem'}}>
        <Col xs={1}>
          <Image
            src={personImage}
            rounded={true}
          />
        </Col>
        <Col xs={5}>
          <Stack
            direction='horizontal'
            gap={2}
          >
            <Heading
              size={4}
              children={personName}
            />
            <ImageWithHyperlink
              src={LinkedInIcon}
              href={personLIProfileUrl}
              style={{height: '1.25rem', width: 'auto'}}
            />
          </Stack>
          <Text
            size={4}
            children={personTitle}
            maxLines={1}
          />
          <hr style={{margin: '0px'}} />
          <Stack
            direction='horizontal'
            gap={2}
          >
            <Image
              src={GlobeEAIcon}
              rounded={true}
              style={{height: '1rem', width: 'auto'}}
            />
            <Text
              size={4}
              children={personLocation}
              maxLines={1}
            />
          </Stack>
        </Col>

        <Col xs={5}>
          <Stack
            direction='horizontal'
            gap={2}
          >
            <ImageWithHyperlink
              src={employerImage}
              href={employerWebsite}
              style={{height: '2rem', width: 'auto'}}
            />
            <Heading
              size={4}
              children={employerName}
            />
            {employerLinkedin ? (
              <ImageWithHyperlink
                src={LinkedInIcon}
                href={`https://www.linkedin.com/company/${employerLinkedin}`}
                style={{height: '1.25rem', width: 'auto'}}
              />
            ) : null}
          </Stack>
          <Text
            size={4}
            children={employerDescription}
            maxLines={2}
          />
        </Col>
        <Col
          xs={1}
          style={{display: 'flex'}}
        >
          <Link
            children={'Learn More'}
            onClick={onClick}
          />
        </Col>
      </Row>
    </Container>
  )
}
