import {Spinner} from './Spinner.js'

export const Link = ({
  size = 4,
  children,
  onClick,
  styles = {},
  isLoading = false,
  disabled = false,
  disabledMessage = ''
}) => {
  if (isLoading) {
    return (
      <Spinner
        animation='grow'
        size='sm'
      />
    )
  }

  return (
    <button
      onClick={onClick}
      className={`text-size-${size}`}
      style={{
        cursor: disabled ? 'auto' : 'pointer',
        textDecoration: 'underline',
        background: 'none',
        border: 'none',
        padding: 0,
        ...styles
      }}
      disabled={disabled}
      title={disabled ? '' : disabledMessage}
      children={children}
    />
  )
}
