//ATOMS
import {useState, useRef, useEffect} from 'react'
import {
  Form,
  InputGroup,
  Container,
  Stack,
  Collapse,
  Row,
  Col,
  FloatingLabel,
  Dropdown
} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'
import {Button} from '../atoms/Button.js'

//ASSETS
import {ReactComponent as LinkedinIcon} from '../atoms/assets/linkedin.svg'

const ConversationInformation = ({messages, setMessageList}) => {
  const initialMessages = messages?.filter(
    (m) => m.outreach_stage === 'initial_message'
  )
  const followUpMessages = messages?.filter(
    (m) => m.outreach_stage === 'follow_up_message'
  )

  const [selectedInitialMessage, setSelectedInitialMessage] = useState()
  const [followUps, setFollowUps] = useState([])

  useEffect(() => {
    const allSelectedMessages = []

    if (selectedInitialMessage) {
      const message = messages.find((msg) => msg.message_id === selectedInitialMessage)
      if (message) {
        allSelectedMessages.push(message)
      }
    }

    followUps.forEach((followUpId) => {
      if (followUpId) {
        const message = messages.find((msg) => msg.message_id === followUpId)
        if (message) {
          allSelectedMessages.push(message)
        }
      }
    })

    setMessageList(allSelectedMessages)
  }, [selectedInitialMessage, followUps, messages, setMessageList])

  return (
    <Container
      fluid
      style={{
        border: 'var(--bs-border-width) solid var(--bs-border-color)',
        padding: '1rem',
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <h5 style={{textAlign: 'center'}}>Conversation</h5>

      <Row className='mb-3'>
        <InputGroup as={Col}>
          <FloatingLabel label='Initial Message'>
            <Form.Select
              value={selectedInitialMessage}
              onChange={(e) => setSelectedInitialMessage(e.target.value)}
            >
              <option>Select An Initial Message</option>
              {initialMessages?.map((msg) => (
                <option
                  key={msg.message_id}
                  value={msg.message_id}
                >
                  {msg.label}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </InputGroup>
      </Row>
      {followUps?.map((followUp, index) => (
        <Row
          className='mb-3'
          key={index}
        >
          <InputGroup as={Col}>
            <FloatingLabel label={`Follow-Up Message ${index + 1}`}>
              <Form.Select
                value={followUp}
                onChange={(e) =>
                  setFollowUps(
                    followUps.map((fu, i) => (i === index ? e.target.value : fu))
                  )
                }
              >
                <option>Select A Follow-Up Message</option>
                {followUpMessages?.map((msg) => (
                  <option
                    key={msg.message_id}
                    value={msg.message_id}
                  >
                    {msg.label}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <Button
              variant='outline-primary'
              onClick={() => setFollowUps(followUps.filter((_, i) => i !== index))}
              className='inputFieldButton'
              children={'-'}
            />
          </InputGroup>
        </Row>
      ))}
      {followUps?.length < 3 && (
        <Button
          style={{alignSelf: 'center'}}
          onClick={() => setFollowUps([...followUps, ''])}
          children={'Add Follow-Up'}
        />
      )}
    </Container>
  )
}

const RecipientInformation = ({
  enrichmentCompanyFetchStatus,
  enrichmentCompanyQueryStatus,
  enrichmentCompanyUrl,
  enrichmentCompanyUrlIsDebouncing,
  enrichmentCompanyUrlOnChange,
  enrichmentPersonFetchStatus,
  enrichmentPersonQueryStatus,
  enrichmentPersonUrl,
  enrichmentPersonUrlIsDebouncing,
  enrichmentPersonUrlOnChange
}) => {
  const personUrlIsLoading =
    enrichmentPersonUrlIsDebouncing || enrichmentPersonFetchStatus === 'fetching'
  const companyUrlIsLoading =
    enrichmentCompanyUrlIsDebouncing || enrichmentCompanyFetchStatus === 'fetching'
  return (
    <Container
      fluid
      style={{
        border: 'var(--bs-border-width) solid var(--bs-border-color)',
        padding: '1rem',
        marginTop: '1rem'
      }}
    >
      <h5 style={{textAlign: 'center'}}>Recipient Information</h5>

      <Row>
        <InputGroup style={{marginBottom: '0.5rem'}}>
          <InputGroup.Text>
            <Stack
              direction='horizontal'
              gap={2}
            >
              <LinkedinIcon
                style={{
                  minWidth: '1.5rem',
                  minHeight: '1.5rem',
                  maxWidth: '1.5rem',
                  maxHeight: '1.5rem',
                  fill: '#0a66c2'
                }}
              />
              {'Recipient Profile'}
            </Stack>
          </InputGroup.Text>
          <Form.Control
            type='text'
            value={enrichmentPersonUrl || ''}
            onChange={enrichmentPersonUrlOnChange}
            name='recipient-profile'
            placeholder='https://www.linkedin.com/in/charlieroos/'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.target.blur()
              }
            }}
          />
          {personUrlIsLoading && (
            <InputGroup.Text style={{borderLeft: 'none'}}>
              <Spinner
                size='sm'
                animation='grow'
              />
            </InputGroup.Text>
          )}
          {enrichmentPersonQueryStatus === 'success' && !personUrlIsLoading && (
            <InputGroup.Text style={{borderLeft: 'none'}}>
              <span
                className='material-symbols-outlined'
                style={{color: 'green'}}
              >
                {'check'}
              </span>
            </InputGroup.Text>
          )}
          {enrichmentPersonQueryStatus === 'error' && !personUrlIsLoading && (
            <InputGroup.Text style={{borderLeft: 'none'}}>
              <span
                className='material-symbols-outlined'
                style={{color: 'red'}}
              >
                {'close'}
              </span>
            </InputGroup.Text>
          )}
        </InputGroup>
      </Row>
      <Collapse in={enrichmentPersonQueryStatus === 'success' && !personUrlIsLoading}>
        <div style={{width: 'inherit'}}>
          <Row>
            <InputGroup>
              <InputGroup.Text>
                <Stack
                  direction='horizontal'
                  gap={2}
                >
                  <LinkedinIcon
                    style={{
                      minWidth: '1.5rem',
                      minHeight: '1.5rem',
                      maxWidth: '1.5rem',
                      maxHeight: '1.5rem',
                      fill: '#0a66c2'
                    }}
                  />
                  {'Employer Profile'}
                </Stack>
              </InputGroup.Text>
              <Form.Control
                type='text'
                value={enrichmentCompanyUrl || ''}
                onChange={enrichmentCompanyUrlOnChange}
                name='employer-profile'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
                placeholder='https://www.linkedin.com/in/pitchr/'
              />
              {companyUrlIsLoading && (
                <InputGroup.Text style={{borderLeft: 'none'}}>
                  <Spinner
                    size='sm'
                    animation='grow'
                  />
                </InputGroup.Text>
              )}
              {enrichmentCompanyQueryStatus === 'success' && !companyUrlIsLoading && (
                <InputGroup.Text style={{borderLeft: 'none'}}>
                  <span
                    className='material-symbols-outlined'
                    style={{color: 'green'}}
                  >
                    {'check'}
                  </span>
                </InputGroup.Text>
              )}
              {enrichmentCompanyQueryStatus === 'error' && !companyUrlIsLoading && (
                <InputGroup.Text style={{borderLeft: 'none'}}>
                  <span
                    className='material-symbols-outlined'
                    style={{color: 'red'}}
                  >
                    {'close'}
                  </span>
                </InputGroup.Text>
              )}
            </InputGroup>
          </Row>
        </div>
      </Collapse>
    </Container>
  )
}

const EmailDialogBox = ({
  sender,
  recipient,
  messageLabel,
  messageBody,
  messageHeader,
  handlePostMessageGenerate,
  postMessageGenerateIsPending,
  postMessageGenerateButtonIsEnabled,
  handlePostMessage,
  postMessageIsPending,
  outreachStage,
  loadMessage,
  handlePostEmailSend,
  postCampaignEmailsSendIsPending
}) => {
  const displayPostGenerateMessageButton = !!handlePostMessageGenerate
  const displayPostMessageButton = !!handlePostMessage
  const displayPostSendMessageButton = !!handlePostEmailSend
  const displayLoadMessageButton = !!loadMessage
  const displayMessage =
    !postMessageGenerateIsPending && !!messageBody && !!messageHeader
  const displayMoreHoriz = displayLoadMessageButton || displayPostMessageButton
  const postMessageRef = useRef(null)
  const postSendMessageRef = useRef(null)
  return (
    <Container
      fluid
      style={{
        border: 'var(--bs-border-width) solid var(--bs-border-color)',
        marginTop: '1rem'
      }}
    >
      {!!messageLabel && <h5 style={{textAlign: 'center'}}>{`<${messageLabel}>`}</h5>}

      <Stack
        direction='horizontal'
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          borderBottom: 'var(--bs-border-width) solid var(--bs-border-color)',
          padding: '0.5rem'
        }}
      >
        <Stack
          direction='vertical'
          gap={0}
        >
          <p style={{marginBottom: 0}}>{`From: ${sender}`}</p>
          <p style={{marginBottom: 0}}>{`To: ${recipient}`}</p>
          <Collapse in={displayMessage}>
            <div style={{width: 'inherit', display: 'flex'}}>
              <span className='material-symbols-outlined color--accent'>{'mail'}</span>
              <p style={{marginLeft: '0.5rem', marginBottom: 0}}>
                {outreachStage === 'initial_message'
                  ? ` Subject: ${messageHeader}`
                  : ` Subject: Re: ${messageHeader}`}
              </p>
            </div>
          </Collapse>
        </Stack>
        {displayMoreHoriz && (
          <Dropdown
            drop='start'
            autoClose='outside'
          >
            <Dropdown.Toggle
              variant='none'
              bsPrefix='dropdownToggle'
              style={{border: 'none'}}
              children={
                <span
                  className='material-symbols-outlined color--p-dark transition-fast clickable t-scale--small'
                  style={{fontSize: '2rem'}}
                >
                  {'more_horiz'}
                </span>
              }
            />
            <Dropdown.Menu style={{minWidth: '300px'}}>
              {displayPostMessageButton && (
                <div
                  className='dropdown-item'
                  style={{backgroundColor: 'transparent'}}
                >
                  <InputGroup>
                    <Form.Control
                      ref={postMessageRef}
                      placeholder={`Example: "Message to ${recipient}"`}
                    />
                    <Button
                      variant='outline-primary'
                      onClick={() => handlePostMessage(postMessageRef.current.value)}
                      children='Save'
                      className='inputFieldButton'
                      isLoading={postMessageIsPending}
                    />
                  </InputGroup>
                </div>
              )}
              {displayPostSendMessageButton && (
                <div
                  className='dropdown-item'
                  style={{backgroundColor: 'transparent'}}
                >
                  <InputGroup>
                    <Form.Control
                      ref={postSendMessageRef}
                      placeholder={'Example: "charlie@pitchr.co"'}
                    />
                    <Button
                      variant='outline-primary'
                      onClick={() =>
                        handlePostEmailSend(postSendMessageRef.current.value)
                      }
                      children='Send'
                      className='inputFieldButton'
                      isLoading={postCampaignEmailsSendIsPending}
                    />
                  </InputGroup>
                </div>
              )}
              {displayLoadMessageButton && (
                <Dropdown.Item onClick={() => loadMessage()}>
                  Load Message
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Stack>
      <Stack
        direction='vertical'
        style={{alignItems: 'center', padding: '0.5rem'}}
      >
        <Collapse in={displayMessage}>
          <div
            style={{
              marginBottom: '0.5rem',
              width: 'inherit',
              whiteSpace: 'pre-wrap'
            }}
            dangerouslySetInnerHTML={{__html: messageBody}}
          />
        </Collapse>

        {displayPostGenerateMessageButton && (
          <Button
            disabled={postMessageGenerateButtonIsEnabled}
            isLoading={postMessageGenerateIsPending}
            variant='outline-primary'
            onClick={() => handlePostMessageGenerate()}
            children={
              displayMessage ? (
                <>
                  {'Try Again '}
                  <span className='material-symbols-outlined'>{'draw'}</span>
                </>
              ) : (
                <>
                  {'Create Message '}
                  <span className='material-symbols-outlined'>{'draw'}</span>
                </>
              )
            }
          />
        )}
      </Stack>
    </Container>
  )
}

export const MessageInstructionsOutput = ({
  allInputInformationIsCorrect,
  enrichmentCompany,
  enrichmentCompanyFetchStatus,
  enrichmentCompanyQueryStatus,
  enrichmentCompanyUrl,
  enrichmentCompanyUrlIsDebouncing,
  enrichmentCompanyUrlOnChange,
  enrichmentPerson,
  enrichmentPersonFetchStatus,
  enrichmentPersonQueryStatus,
  enrichmentPersonUrl,
  enrichmentPersonUrlIsDebouncing,
  enrichmentPersonUrlOnChange,
  generatedMessage,
  handlePostEmailSend,
  handlePostMessage,
  handlePostMessageGenerate,
  messageList,
  messages,
  outreachMethod,
  outreachStage,
  postCampaignEmailsSendIsPending,
  postMessageGenerateIsPending,
  postMessageIsPending,
  setMessageList,
  userFirstName
}) => {
  const [openRecipientInformation, setOpenRecipientInformation] = useState(false)
  const [openConversationInformation, setOpenConversationInformation] = useState(false)

  const openMessageDialog =
    enrichmentPersonUrlIsDebouncing === false &&
    enrichmentCompanyUrlIsDebouncing === false &&
    enrichmentPersonFetchStatus !== 'fetching' &&
    enrichmentCompanyFetchStatus !== 'fetching' &&
    enrichmentPersonQueryStatus === 'success' &&
    enrichmentCompanyQueryStatus === 'success'

  return (
    <Container
      fluid
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '1rem'
      }}
    >
      <h2>Message</h2>

      <Stack
        direction='horizontal'
        gap={3}
        style={{alignSelf: 'end'}}
      >
        <Button
          variant='outline-primary'
          onClick={() => setOpenRecipientInformation(!openRecipientInformation)}
          children={
            enrichmentPersonQueryStatus === 'success' &&
            enrichmentCompanyQueryStatus === 'success' &&
            !!enrichmentCompany &&
            !!enrichmentPerson ? (
              <>
                {'Recipient Selected '}
                <span className='material-symbols-outlined'>{'check'}</span>
              </>
            ) : (
              'Select Recipient'
            )
          }
        />
        <Collapse
          in={outreachStage === 'follow_up_message'}
          dimension='width'
        >
          <div style={{width: 'inherit'}}>
            <Button
              variant='outline-primary'
              style={{textWrap: 'nowrap'}}
              onClick={() =>
                setOpenConversationInformation(!openConversationInformation)
              }
              children={
                messageList?.length > 0 ? (
                  <>
                    {'Conversation Defined '}
                    <span className='material-symbols-outlined'>{'check'}</span>
                  </>
                ) : (
                  <>{'Define Conversation'}</>
                )
              }
            />
          </div>
        </Collapse>
      </Stack>

      <Collapse in={openRecipientInformation}>
        <div style={{width: 'inherit'}}>
          <RecipientInformation
            enrichmentCompanyFetchStatus={enrichmentCompanyFetchStatus}
            enrichmentCompanyQueryStatus={enrichmentCompanyQueryStatus}
            enrichmentCompanyUrl={enrichmentCompanyUrl}
            enrichmentCompanyUrlIsDebouncing={enrichmentCompanyUrlIsDebouncing}
            enrichmentCompanyUrlOnChange={enrichmentCompanyUrlOnChange}
            enrichmentPersonFetchStatus={enrichmentPersonFetchStatus}
            enrichmentPersonQueryStatus={enrichmentPersonQueryStatus}
            enrichmentPersonUrl={enrichmentPersonUrl}
            enrichmentPersonUrlIsDebouncing={enrichmentPersonUrlIsDebouncing}
            enrichmentPersonUrlOnChange={enrichmentPersonUrlOnChange}
          />
        </div>
      </Collapse>

      <Collapse
        in={openConversationInformation && outreachStage === 'follow_up_message'}
      >
        <div style={{width: 'inherit'}}>
          <ConversationInformation
            messages={messages}
            setMessageList={setMessageList}
          />
          {messageList?.map((m) => (
            <EmailDialogBox
              messageLabel={m?.label}
              sender={m?.sender_first_name}
              recipient={enrichmentPerson?.first_name}
              messageBody={m?.message_body}
              messageHeader={m?.message_header || messageList?.[0]?.message_header}
              outreachStage={m?.outreach_stage}
              handlePostEmailSend={handlePostEmailSend}
              postCampaignEmailsSendIsPending={postCampaignEmailsSendIsPending}
            />
          ))}
        </div>
      </Collapse>

      <Collapse
        in={
          openMessageDialog &&
          outreachMethod === 'email' &&
          outreachStage === 'initial_message'
        }
      >
        <div style={{width: 'inherit'}}>
          <EmailDialogBox
            sender={userFirstName}
            recipient={enrichmentPerson?.first_name}
            handlePostMessageGenerate={handlePostMessageGenerate}
            handlePostMessage={handlePostMessage}
            postMessageGenerateButtonIsEnabled={!allInputInformationIsCorrect}
            messageBody={generatedMessage?.body}
            messageHeader={generatedMessage?.header}
            postMessageGenerateIsPending={postMessageGenerateIsPending}
            postMessageIsPending={postMessageIsPending}
            outreachStage={outreachStage}
            handlePostEmailSend={handlePostEmailSend}
            postCampaignEmailsSendIsPending={postCampaignEmailsSendIsPending}
          />
        </div>
      </Collapse>

      <Collapse
        in={
          openMessageDialog &&
          messageList?.length > 0 &&
          outreachMethod === 'email' &&
          outreachStage === 'follow_up_message'
        }
      >
        <div style={{width: 'inherit'}}>
          <EmailDialogBox
            sender={userFirstName}
            recipient={enrichmentPerson?.first_name}
            handlePostMessageGenerate={handlePostMessageGenerate}
            handlePostMessage={handlePostMessage}
            postMessageGenerateButtonIsEnabled={!allInputInformationIsCorrect}
            messageBody={generatedMessage?.body}
            messageHeader={messageList[0]?.message_header}
            postMessageGenerateIsPending={postMessageGenerateIsPending}
            postMessageIsPending={postMessageIsPending}
            outreachStage={outreachStage}
            handlePostEmailSend={handlePostEmailSend}
            postCampaignEmailsSendIsPending={postCampaignEmailsSendIsPending}
          />
        </div>
      </Collapse>
    </Container>
  )
}
