import {Fragment} from 'react'
import {Link} from '../atoms/Link.js'
import {Stack} from 'react-bootstrap'

export const FakeBreadcrumbs = ({workflowSteps, onWorkflowStepSelect, style = {}}) => {
  return (
    <Stack
      direction='horizontal'
      gap={1}
      style={style}
    >
      {Object.entries(workflowSteps).map(([key, step], index) => (
        <Fragment key={key}>
          <Link
            children={step.label}
            onClick={() =>
              onWorkflowStepSelect(key, {...step, selected: !step.selected})
            }
            className='d-text__placement--center color--s-dark'
            styles={{
              textDecoration: step.selected ? 'underline' : ''
            }}
            disabled={!step.enabled}
          />
          {index < Object.entries(workflowSteps).length - 1 && <span>{'>'}</span>}
        </Fragment>
      ))}
    </Stack>
  )
}
