//ATOMS
import {Button} from '../atoms/Button.js'
import {WarningIconWithTooltip} from './WarningIconWithTooltip.js'

export const LoadingButton = ({
  children = 'Search',
  onClick,
  isLoading,
  error,
  disabled = false,
  style = {},
  type = 'button'
}) => {
  if (error) {
    return <WarningIconWithTooltip message={error?.message} />
  }

  return (
    <Button
      onClick={onClick}
      style={style}
      disabled={disabled || isLoading}
      type={type}
      isLoading={isLoading}
    >
      {children}
    </Button>
  )
}
