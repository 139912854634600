//ATOMS
import {Container} from 'react-bootstrap'
import {Offcanvas} from '../atoms/Offcanvas.js'
import {Image} from '../atoms/Image.js'
import {Heading} from '../atoms/Heading.js'
import {Text} from '../atoms/Text.js'

export const MoreInfoCanvas = ({
  show,
  onHide,
  headerImage,
  headerText,
  headerSubText,
  bodyText
}) => {
  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      header={
        <Container
          fluid
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Image
            src={headerImage}
            rounded={true}
          />
          <Heading
            size={4}
            children={headerText}
            maxLines={1}
          />
          <Heading
            size={5}
            children={headerSubText}
            maxLines={2}
          />
        </Container>
      }
      body={
        <Container
          fluid
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Text
            children={bodyText}
            maxLines={99}
            style={{textAlign: 'center'}}
          />
        </Container>
      }
    />
  )
}
