import {Offcanvas as BootstrapOffcanvas} from 'react-bootstrap'

export const Offcanvas = ({show, onHide, header, body}) => {
  return (
    <BootstrapOffcanvas
      show={show}
      onHide={onHide}
      placement='end'
    >
      <BootstrapOffcanvas.Header>{header}</BootstrapOffcanvas.Header>
      <BootstrapOffcanvas.Body>{body}</BootstrapOffcanvas.Body>
    </BootstrapOffcanvas>
  )
}
