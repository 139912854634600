import {useRecoilState} from 'recoil'
import {campaignBuilderAtom} from '../../states/atoms/campaigns.js'

export const useCampaignBuilderAtom = () => {
  const [campaignClientState, setCampaignClientState] =
    useRecoilState(campaignBuilderAtom)

  const setCampaignClientStateKey = (key, value) => {
    setCampaignClientState((prevState) => ({...prevState, [key]: value}))
  }

  return {campaignClientState, setCampaignClientState, setCampaignClientStateKey}
}
