import CreatableSelect from 'react-select/creatable'

const colors = {
  primaryLight: 'var(--primary-light)',
  accent: 'var(--accent)',
  primaryDark: 'var(--primary-dark)'
}

const CustomMultiValueLabel = ({children}) => {
  const truncate = (str, num) => {
    if (str.length <= num) return str
    return str.slice(0, num) + '...'
  }

  return <div className=' css-wsp0cs-MultiValueGeneric'>{truncate(children, 18)}</div>
}

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.primaryLight,
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--accent)'
    },
    '&:focus-within': {
      borderColor: 'var(--accent)'
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'auto',
    maxHeight: '100px',
    overflowY: 'auto'
  }),
  multiValue: (styles) => ({
    ...styles,
    height: 'auto',
    maxHeight: '24px'
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    maxWidth: '100%'
  }),
  menu: (styles) => ({
    ...styles,
    display: 'none'
  })
}

export const MultiInputField = ({onChange, selectedValues}) => {
  const formattedSelectedValues = selectedValues.map((option) => ({
    label: option,
    value: option
  }))

  const handleChange = (_selectedValues) => {
    onChange(_selectedValues.map((option) => option.value))
  }

  return (
    <CreatableSelect
      isMulti
      styles={customStyles}
      components={{
        DropdownIndicator: null,
        MultiValueLabel: CustomMultiValueLabel
      }}
      onChange={handleChange}
      options={formattedSelectedValues}
      value={formattedSelectedValues}
      placeholder='Type and press enter...'
    />
  )
}
