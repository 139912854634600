//UTILS
import {formatDashedString} from '../../utils/helpers.js'

//ATOMS
import {Row, Col, Container, Stack} from 'react-bootstrap'
import {Heading} from '../atoms/Heading.js'
import {Text} from '../atoms/Text.js'
import {Image} from '../atoms/Image.js'
import {Link} from '../atoms/Link.js'

import CashIcon from '../atoms/assets/cash.svg'
import PeopleFillIcon from '../atoms/assets/people-fill.svg'
import GlobeEAIcon from '../atoms/assets/globe-europe-africa.svg'
import BuildingsIcon from '../atoms/assets/buildings.svg'
import FacebookIcon from '../atoms/assets/facebook.svg'
import GlobeIcon from '../atoms/assets/globe.svg'
import LinkedInIcon from '../atoms/assets/linkedin.svg'
import TwitterIcon from '../atoms/assets/twitter.svg'
import YoutubeIcon from '../atoms/assets/youtube.svg'
import InstagramIcon from '../atoms/assets/instagram.svg'

//Molecules
import {ImageWithHyperlink} from '../molecules/ImageWithHyperlink.js'

export const CompanyRowItem = ({
  companylogo,
  companyName,
  companyDescriptionShort,
  companyRevenue,
  companyEmployees,
  companyCountry,
  companyIndustry,
  companyDomain,
  companyFacebook,
  companyInstagram,
  companyLinkedin,
  companyTwitter,
  companyYoutube,
  onClick
}) => {
  return (
    <Container fluid>
      <Row style={{maxHeight: '8rem', minHeight: '5rem'}}>
        <Col xs={1}>
          <Image
            src={companylogo}
            rounded={true}
          />
        </Col>
        <Col xs={6}>
          <Heading
            size={4}
            children={companyName}
          />
          <Text
            size={4}
            children={companyDescriptionShort}
            maxLines={2}
          />
          <hr style={{margin: '0px'}} />
          <Stack
            direction='horizontal'
            gap={3}
          >
            {companyDomain ? (
              <ImageWithHyperlink
                src={GlobeIcon}
                href={`https://www.${companyDomain}`}
                key={companyDomain}
                style={{height: '1rem'}}
              />
            ) : null}
            {companyFacebook ? (
              <ImageWithHyperlink
                src={FacebookIcon}
                href={companyFacebook}
                key={companyFacebook}
                style={{height: '1rem'}}
              />
            ) : null}
            {companyInstagram ? (
              <ImageWithHyperlink
                src={InstagramIcon}
                href={companyInstagram}
                key={companyInstagram}
                style={{height: '1rem'}}
              />
            ) : null}
            {companyLinkedin ? (
              <ImageWithHyperlink
                src={LinkedInIcon}
                href={companyLinkedin}
                key={companyLinkedin}
                style={{height: '1rem'}}
              />
            ) : null}
            {companyTwitter ? (
              <ImageWithHyperlink
                src={TwitterIcon}
                href={companyTwitter}
                key={companyTwitter}
                style={{height: '1rem'}}
              />
            ) : null}
            {companyYoutube ? (
              <ImageWithHyperlink
                src={YoutubeIcon}
                href={companyYoutube}
                key={companyYoutube}
                style={{height: '1rem'}}
              />
            ) : null}
          </Stack>
        </Col>

        <Col
          xs={4}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column'
          }}
        >
          {[
            {
              key: 0,
              image: CashIcon,
              children: companyRevenue?.toUpperCase()
            },
            {
              key: 1,
              image: PeopleFillIcon,
              children: companyEmployees
            },
            {key: 2, image: GlobeEAIcon, children: companyCountry},
            {
              key: 3,
              image: BuildingsIcon,
              children: formatDashedString(companyIndustry)
            }
          ].map((i) => (
            <Stack
              key={i.key}
              direction='horizontal'
              style={{justifyContent: 'flex-start'}}
              gap={2}
            >
              <Image
                src={i.image}
                style={{height: '1.25rem', width: 'auto'}}
              />
              <Text
                size={4}
                maxLines={1}
                children={i.children}
              />
            </Stack>
          ))}
        </Col>
        <Col
          xs={1}
          style={{display: 'flex'}}
        >
          <Link
            children={'Learn More'}
            onClick={onClick}
          />
        </Col>
      </Row>
    </Container>
  )
}
