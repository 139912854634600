import {useLocation, useNavigate} from 'react-router-dom'
import {useState, useEffect, useRef, useCallback} from 'react'
import {
  Stack,
  Row,
  Col,
  InputGroup,
  Form,
  FloatingLabel,
  Container,
  ButtonGroup,
  ToggleButton,
  Nav
} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'

import * as CampaignSS from '../../hooks/server/useCampaignsServerState.js'
import * as AudienceSS from '../../hooks/server/useAudiencesServerState.js'
import * as AgentSS from '../../hooks/server/useAgentServerState.js'
import {useQueryClient} from '@tanstack/react-query'

import {MainTemplate} from '../templates/MainTemplate.js'

//HOOKS
import {
  getCheckedValues,
  getCampaignStatusMessage,
  getCampaignStatusControllerIcon,
  getCampaignStatusIcon
} from '../../utils/helpers.js'

//MOLECULES
import {ActionWithConfirmation} from '../molecules/ActionWithConfirmation.js'
import {AudienceProgressBar} from '../molecules/AudienceProgressBars.js'
import {AudienceProgressBarLegend} from '../molecules/AudienceProgressBarLegend.js'
import {
  CampaignSearchForm,
  CampaignSearchFormFooter
} from '../molecules/CampaignSearchForm.js'
import {
  PersonSearchResults,
  CompanySearchResults
} from '../molecules/CampaignSearchResults.js'
import {MessageModal} from '../organisms/MessageModal.js'
import {AudienceModal} from '../organisms/AudienceModal.js'
import {MessageInstructionsModal} from '../organisms/MessageInstructionsModal.js'
import {ConversationTimeline} from '../organisms/CampaignBuilderInput.js'

export const CampaignInsights = () => {
  //LOCAL UI STATE
  const location = useLocation()
  const navigate = useNavigate()

  const [campaign, setCampaign] = useState(null)
  const [audience, setAudience] = useState(null)
  const [messageInstruction, setMessageInstructionsAtom] = useState(null)

  const [campaignFormState, setCampaignFormState] = useState({})

  const [activeProgressTab, setActiveProgressTab] = useState('companies')
  const [activeInsightsTab, setActiveInsightsTab] = useState('companies')

  const [activePerson, setActivePerson] = useState(null)
  const [setMessageInstructionsModal, setShowMessageInstructionsModal] = useState(false)
  const [showAudienceModal, setShowAudienceModal] = useState(false)

  const companySearchFormRef = useRef(null)
  const companySearchFormDebounceTimeoutRef = useRef(null)
  const [companySearchIsDebouncing, setCompanySearchIsDebouncing] = useState(false)
  const [companyOutreachParams, setCompanyOutreachParams] = useState(null)

  const personSearchFormRef = useRef(null)
  const personSearchFormDebounceTimeoutRef = useRef(null)
  const [personSearchIsDebouncing, setPersonSearchIsDebouncing] = useState(false)
  const [personOutreachParams, setPersonOutreachParams] = useState(null)

  //SERVER STATES
  const queryClient = useQueryClient()

  const {
    data: campaigns,
    isLoading: campaignIsLoading,
    isFetching: campaignIsFetching
  } = CampaignSS.useGetCampaigns({
    params: {compute_statistics: true, per_page: 10000, page: 0}
  })
  const {mutate: patchCampaign, isPending: patchCampaignIsPending} =
    CampaignSS.usePatchCampaign()
  const {mutateAsync: deleteCampaign, isPending: deleteCampaignIsPending} =
    CampaignSS.useDeleteCampaign()
  const {mutateAsync: patchAudience, isPending: patchAudienceIsPending} =
    AudienceSS.usePatchAudience({showToast: false})
  const {
    mutateAsync: patchMessageInstructions,
    isPending: patchMessageInstructionsIsPending
  } = AgentSS.usePatchMessageInstructions({
    showToast: false,
    invalidateQuery: false
  })

  const {
    data: companyOutreachTracking,
    fetchNextPage: companyOutreachTrackingFetchNextPage,
    hasNextPage: companyOutreachTrackingHasNextPage,
    isFetching: companyOutreachTrackingIsFetching,
    isLoading: companyOutreachTrackingIsLoading
  } = CampaignSS.useGetCampaignsOutreachTrackerCompanies({
    params: companyOutreachParams || {
      campaign_id: campaign?.campaign_id,
      per_page: 10
    },
    enabled: campaign?.campaign_id?.length > 1
  })
  const {
    data: personOutreachTracking,
    fetchNextPage: personOutreachTrackingFetchNextPage,
    hasNextPage: personOutreachTrackingHasNextPage,
    isFetching: personOutreachTrackingIsFetching,
    isLoading: personOutreachTrackingIsLoading
  } = CampaignSS.useGetCampaignsOutreachTrackerPeople({
    params: personOutreachParams || {campaign_id: campaign?.campaign_id, per_page: 10},
    enabled: campaign?.campaign_id?.length > 1
  })
  const {
    data: audiences,
    isFetching: audiencesIsFetching,
    isLoading: audiencesIsLoading
  } = AudienceSS.useGetAudiences()
  const {
    data: messageInstructions,
    isFetching: messageInstructionsIsFetching,
    isLoading: messageInstructionsIsLoading
  } = AgentSS.useGetMessageInstructions()
  const {data: companyCountryOptions} = AudienceSS.useGetSearchFilterOptions({
    params: {field: 'company_countries'}
  })
  const {data: personCountryOptions} = AudienceSS.useGetSearchFilterOptions({
    params: {field: 'person_countries'}
  })
  const {refetch: refetchCampaignReport, isFetching: campaignReportIsFetching} =
    CampaignSS.useGetCampaignReport({
      params: {campaign_id: campaign?.campaign_id}
    })

  //LISTENERS
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const campaignId = urlParams.get('campaign_id')
    const campaign = campaigns?.items?.find((c) => c?.campaign_id === campaignId)
    const audience = audiences?.find((a) => a?.audience_id === campaign?.audience_id)
    const messageInstruction = messageInstructions?.find(
      (mi) => mi?.message_instructions_id === campaign?.steps[0].message_instructions_id
    )
    setCampaign(campaign)
    setAudience(audience)
    setMessageInstructionsAtom(messageInstruction)
  }, [location.search, audiences, messageInstructions, campaigns])

  useEffect(() => {
    setCampaignFormState((c) => ({
      ...c,
      description: campaign?.campaign_description,
      sortingInstructions: campaign?.campaign_sorting_instructions,
      maxOutreach: campaign?.max_people_outreach,
      maxOutreachPerDay: campaign?.max_people_outreach_per_day,
      maxOutreachPerCompany: campaign?.max_people_outreach_per_company
    }))
  }, [campaign, setCampaignFormState])

  //HANDLERS
  const handleDeleteCampaign = async () => {
    const campaignParams = {campaign_id: campaign?.campaign_id}
    const audienceParams = {audience_id: campaign?.audience_id}

    const miInUse = new Set()
    campaigns.items.forEach((c) => {
      if (c.campaign_id !== campaign?.campaign_id) {
        c.steps.forEach((step) => {
          miInUse.add(step.message_instructions_id)
        })
      }
    })
    let agentParams = []
    campaign?.steps.forEach((step) => {
      if (!miInUse.has(step.message_instructions_id)) {
        agentParams.push({message_instructions_id: step.message_instructions_id})
      }
    })

    try {
      await deleteCampaign({params: campaignParams})

      const tasks = [
        patchAudience({params: audienceParams, payload: {used_in_campaign: false}})
      ].concat(
        agentParams.map((params) =>
          patchMessageInstructions({params, payload: {used_in_campaign: false}})
        )
      )
      await Promise.all(tasks)
      queryClient.invalidateQueries({queryKey: ['messageInstructions']})
      navigate('/dashboard')
    } catch (e) {
      console.error('Error during campaign deletion:', e)
    }
  }

  const handleReportDownload = () => {
    refetchCampaignReport().then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {type: 'text/csv'})
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${campaign?.campaign_name} - report`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }

  const handleCompaniesSearchFormChange = useCallback(() => {
    const form = companySearchFormRef.current

    let companySearchSetting = {
      campaign_id: campaign?.campaign_id,
      per_page: 10
    }

    const outreachStatus = getCheckedValues(form, 'company-outreach-status')
    if (outreachStatus.length > 0) {
      companySearchSetting.outreach_status = outreachStatus
    }

    const skipReason = getCheckedValues(form, 'company-outreach-skip-reason')
    if (skipReason.length > 0) {
      companySearchSetting.skip_reason = skipReason
    }

    clearTimeout(companySearchFormDebounceTimeoutRef.current)
    setCompanySearchIsDebouncing(true)
    companySearchFormDebounceTimeoutRef.current = setTimeout(() => {
      setCompanyOutreachParams({...companySearchSetting})
      setCompanySearchIsDebouncing(false)
    }, 2000)
  }, [campaign, setCompanyOutreachParams])

  const handlePersonSearchFormChange = useCallback(() => {
    const form = personSearchFormRef.current

    let personSearchSetting = {
      campaign_id: campaign?.campaign_id,
      per_page: 10
    }

    const outreachStatus = getCheckedValues(form, 'person-outreach-status')
    if (outreachStatus.length > 0) {
      personSearchSetting.outreach_status = outreachStatus
    }

    const skipReason = getCheckedValues(form, 'person-outreach-skip-reason')
    if (skipReason.length > 0) {
      personSearchSetting.skip_reason = skipReason
    }

    clearTimeout(personSearchFormDebounceTimeoutRef.current)
    setPersonSearchIsDebouncing(true)
    personSearchFormDebounceTimeoutRef.current = setTimeout(() => {
      setPersonOutreachParams({...personSearchSetting})
      setPersonSearchIsDebouncing(false)
    }, 2000)
  }, [campaign, setPersonOutreachParams])

  const personSearchFormItems = [
    {
      header: 'Outreach Status',
      type: 'checkbox',
      name: 'person-outreach-status',
      values: [
        'awaiting_message,awaiting_outreach',
        'skipped',
        'sent,received_no_response',
        'received_bounce',
        'received_response_from_recipient'
      ],
      labels: ['In Queue', 'Skipped', 'Sent', 'Bounce', 'Response']
    },
    {
      header: 'Skip Reason',
      type: 'checkbox',
      name: 'person-outreach-skip-reason',
      values: [
        'missing_person_linkedin_id,missing_person_summary,missing_company_linkedin_id,missing_company_summary',
        'missing_person_email',
        'found_better_candidate,person_exists_for_multiple_companies',
        'failed_sending_email'
      ],
      labels: [
        'Insufficient Information',
        'Missing Email',
        'Found Better Candidate',
        'Failed Sending Email'
      ]
    }
  ]

  const companySearchFormItems = [
    {
      header: 'Outreach Status',
      type: 'checkbox',
      name: 'company-outreach-status',
      values: [
        'awaiting_people,awaiting_outreach',
        'skipped',
        'sent,received_no_response',
        'received_bounce',
        'received_response_from_recipient'
      ],
      labels: ['In Queue', 'Skipped', 'Sent', 'Bounce', 'Response']
    },
    {
      header: 'Skip Reason',
      type: 'checkbox',
      name: 'company-outreach-skip-reason',
      values: [
        'missing_company_summary,missing_company_name,missing_company_linkedin_id',
        'missing_relevant_people'
      ],
      labels: ['Insufficient Information', 'Missing relevant people']
    }
  ]

  return (
    <>
      <MessageInstructionsModal
        show={setMessageInstructionsModal}
        onHide={() => {
          setShowMessageInstructionsModal(false)
        }}
        label={messageInstruction?.label || ''}
        title={messageInstruction?.title || ''}
        companyName={messageInstruction?.company_name || ''}
        intention={messageInstruction?.intention || ''}
        language={messageInstruction?.language || ''}
        outreachMethod={messageInstruction?.outreach_method || ''}
        outreachStage={messageInstruction?.outreach_stage || ''}
        preferences={messageInstruction?.message_preferences || []}
        usedInCampaign={messageInstruction?.used_in_campaign || false}
        valueProposition={messageInstruction?.value_proposition || ''}
        messageSignature={messageInstruction?.message_signature || ''}
        updatedAt={messageInstruction?.updated_at || ''}
      />
      <AudienceModal
        show={showAudienceModal}
        onHide={() => {
          setShowAudienceModal(false)
        }}
        label={audience?.label || ''}
        companiesInAudience={audience?.companies_in_audience || ''}
        peopleInAudience={audience?.people_in_audience || ''}
        companyEmployees={audience?.company_search_filters?.totalEmployees || {}}
        companyRevenue={audience?.company_search_filters?.revenue || {}}
        companyCountryCode={audience?.company_search_filters?.country_code || {}}
        companyCountryOptions={companyCountryOptions || {}}
        companyIndustryMain={audience?.company_search_filters?.industryMain || {}}
        companyTechnologies={audience?.company_search_filters?.technologies || {}}
        companyName={audience?.company_search_filters?.name || {}}
        personSeniorities={audience?.person_search_filters?.seniorities || []}
        personDepartments={audience?.person_search_filters?.departments || []}
        personCountries={audience?.person_search_filters?.countries || []}
        personCountryOptions={personCountryOptions || []}
        personKeywords={audience?.person_search_filters?.keywords || []}
        updatedAt={audience?.updated_at || ''}
        usedInCampaign={audience?.used_in_campaign || false}
      />
      <MessageModal
        show={activePerson}
        onHide={() => {
          setActivePerson(null)
        }}
        label={activePerson?.email_address}
        emailSubject={activePerson?.message_header || ''}
        emailBody={activePerson?.message_body || ''}
        updatedAt={activePerson?.updated_at || ''}
      />
      <MainTemplate>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '2rem',
            paddingBottom: '0.5rem',
            borderBottom: 'var(--bs-border-width) solid var(--bs-border-color)'
          }}
        >
          <Col xs='auto'>
            <h1
              className='d-flex'
              style={{marginBottom: 0}}
            >
              {campaign?.campaign_name}

              <span
                className={'material-symbols-outlined color--p-dark'}
                style={{marginLeft: '0.5rem', fontSize: '3.5rem'}}
              >
                {'campaign'}
              </span>
            </h1>
            <h6
              className='d-flex align-items-center'
              style={{paddingLeft: '1rem'}}
            >
              {campaign &&
                getCampaignStatusIcon(
                  campaign?.campaign_status,
                  campaign?.campaign_review_reason
                )}
              <div style={{width: '0.25em'}}></div>
              {campaign &&
                getCampaignStatusMessage(
                  campaign?.campaign_status,
                  campaign?.campaign_review_reason
                )}
            </h6>
          </Col>
          <Col xs='auto'>
            <Stack
              direction='horizontal'
              gap={3}
            >
              {campaign &&
                getCampaignStatusControllerIcon({
                  campaignId: campaign?.campaign_id,
                  campaignStatus: campaign?.campaign_status,
                  campaignReviewReason: campaign?.campaign_review_reason,
                  patchCampaignCallback: patchCampaign,
                  patchCampaignIsPending:
                    patchCampaignIsPending || campaignIsFetching || campaignIsLoading
                })}
              {campaignReportIsFetching ? (
                <Spinner
                  size='sm'
                  animation='grow'
                />
              ) : (
                <span
                  className={
                    'material-symbols-outlined color--p-dark transition-fast clickable t-scale--small t-color--accent'
                  }
                  style={{fontSize: '2.2rem'}}
                  onClick={handleReportDownload}
                >
                  {'download'}
                </span>
              )}
              <ActionWithConfirmation
                callback={() => handleDeleteCampaign()}
                disabled={false}
                placement='left'
                style={{fontSize: '2rem'}}
                isPending={deleteCampaignIsPending}
              />
            </Stack>
          </Col>
        </Row>

        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '2rem',
            borderBottom: 'var(--bs-border-width) solid var(--bs-border-color)'
          }}
        >
          <Col
            xs={12}
            xxl={11}
          >
            <Row style={{display: 'flex', justifyContent: 'center'}}>
              <Col xs={6}>
                <h5 style={{padding: '1rem'}}>Information</h5>
                <Row>
                  <Row style={{paddingBottom: '1.5rem'}}>
                    <InputGroup>
                      <FloatingLabel label='Campaign Description'>
                        <Form.Control
                          type='text'
                          value={campaignFormState?.description || ''}
                          onChange={(e) =>
                            setCampaignFormState((c) => ({
                              ...c,
                              description: e.target.value
                            }))
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              e.target.blur()
                            }
                          }}
                          onBlur={() => {
                            if (
                              campaign?.campaign_description !==
                              campaignFormState?.description
                            ) {
                              patchCampaign({
                                params: {
                                  campaign_id: campaign?.campaign_id
                                },
                                payload: {
                                  campaign_description: campaignFormState.description
                                }
                              })
                            }
                          }}
                          style={{borderRight: 'none'}}
                        />
                      </FloatingLabel>
                      <InputGroup.Text>
                        {(campaignIsLoading ||
                          campaignIsFetching ||
                          patchCampaignIsPending) && (
                          <Spinner
                            size='sm'
                            animation='grow'
                          />
                        )}
                        {!campaignIsLoading &&
                          !campaignIsFetching &&
                          !patchCampaignIsPending && (
                            <span
                              className={'material-symbols-outlined color--p-dark'}
                              style={{fontSize: '1.5rem'}}
                            >
                              {'draw'}
                            </span>
                          )}
                      </InputGroup.Text>
                    </InputGroup>
                  </Row>
                  <Row>
                    <InputGroup as={Col}>
                      <FloatingLabel label='Audience Used'>
                        <Form.Control
                          type='text'
                          value={audience?.label || ''}
                          style={{borderRight: 'none', cursor: 'pointer'}}
                          onClick={() => setShowAudienceModal(true)}
                          readOnly
                        />
                      </FloatingLabel>
                      <InputGroup.Text>
                        {(audiencesIsLoading || audiencesIsFetching) && (
                          <Spinner
                            size='sm'
                            animation='grow'
                          />
                        )}
                        {!audiencesIsLoading && !audiencesIsFetching && (
                          <span
                            className={'material-symbols-outlined color--p-dark'}
                            style={{fontSize: '2rem'}}
                          >
                            {'groups'}
                          </span>
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </Row>
                </Row>
              </Col>
              <Col xs={6}>
                <h5 style={{padding: '1rem'}}>Outreach</h5>
                <Row style={{paddingBottom: '1.5rem'}}>
                  <InputGroup as={Col}>
                    <FloatingLabel label='Messages Limit'>
                      <Form.Control
                        type='text'
                        value={campaignFormState?.maxOutreach || ''}
                        style={{borderRight: 'none'}}
                        onChange={(e) =>
                          setCampaignFormState((c) => ({
                            ...c,
                            maxOutreach: e.target.value
                          }))
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            e.target.blur()
                          }
                        }}
                        onBlur={() => {
                          if (
                            campaign?.max_people_outreach !==
                            campaignFormState?.maxOutreach
                          ) {
                            patchCampaign({
                              params: {
                                campaign_id: campaign?.campaign_id
                              },
                              payload: {
                                max_people_outreach: campaignFormState.maxOutreach
                              }
                            })
                          }
                        }}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      {(campaignIsLoading ||
                        campaignIsFetching ||
                        patchCampaignIsPending) && (
                        <Spinner
                          size='sm'
                          animation='grow'
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  <InputGroup as={Col}>
                    <FloatingLabel label='Per Company'>
                      <Form.Control
                        type='text'
                        value={campaignFormState?.maxOutreachPerCompany || ''}
                        onChange={(e) =>
                          setCampaignFormState((c) => ({
                            ...c,
                            maxOutreachPerCompany: e.target.value
                          }))
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            e.target.blur()
                          }
                        }}
                        onBlur={() => {
                          if (
                            campaign?.max_people_outreach_per_company !==
                            campaignFormState?.maxOutreachPerCompany
                          ) {
                            patchCampaign({
                              params: {
                                campaign_id: campaign?.campaign_id
                              },
                              payload: {
                                max_people_outreach_per_company:
                                  campaignFormState.maxOutreachPerCompany
                              }
                            })
                          }
                        }}
                        style={{borderRight: 'none'}}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      {(campaignIsLoading ||
                        campaignIsFetching ||
                        patchCampaignIsPending) && (
                        <Spinner
                          size='sm'
                          animation='grow'
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  <InputGroup as={Col}>
                    <FloatingLabel label='Per Day'>
                      <Form.Control
                        type='text'
                        value={campaignFormState?.maxOutreachPerDay || ''}
                        style={{borderRight: 'none'}}
                        onChange={(e) =>
                          setCampaignFormState((c) => ({
                            ...c,
                            maxOutreachPerDay: e.target.value
                          }))
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            e.target.blur()
                          }
                        }}
                        onBlur={() => {
                          if (
                            campaign?.max_people_outreach_per_day !==
                            campaignFormState?.maxOutreachPerDay
                          ) {
                            patchCampaign({
                              params: {
                                campaign_id: campaign?.campaign_id
                              },
                              payload: {
                                max_people_outreach_per_day:
                                  campaignFormState.maxOutreachPerDay
                              }
                            })
                          }
                        }}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      {(campaignIsLoading ||
                        campaignIsFetching ||
                        patchCampaignIsPending) && (
                        <Spinner
                          size='sm'
                          animation='grow'
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Row>
                <Row>
                  <InputGroup>
                    <FloatingLabel label='Prioritize people based on'>
                      <Form.Control
                        type='text'
                        value={campaignFormState?.sortingInstructions || ''}
                        onChange={(e) =>
                          setCampaignFormState((c) => ({
                            ...c,
                            sortingInstructions: e.target.value
                          }))
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            e.target.blur()
                          }
                        }}
                        onBlur={() => {
                          if (
                            campaign?.campaign_sorting_instructions !==
                            campaignFormState?.sortingInstructions
                          ) {
                            patchCampaign({
                              params: {
                                campaign_id: campaign?.campaign_id
                              },
                              payload: {
                                campaign_sorting_instructions:
                                  campaignFormState.sortingInstructions
                              }
                            })
                          }
                        }}
                        style={{borderRight: 'none'}}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      {(campaignIsLoading ||
                        campaignIsFetching ||
                        patchCampaignIsPending) && (
                        <Spinner
                          size='sm'
                          animation='grow'
                        />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '2rem',
            borderBottom: 'var(--bs-border-width) solid var(--bs-border-color)'
          }}
        >
          <Col className='d-flex flex-column justify-content-center align-items-center'>
            <h5>Cadence</h5>
            <ConversationTimeline
              conversationState={campaign?.steps}
              setConversationState={(e) => {
                patchCampaign({
                  params: {
                    campaign_id: campaign?.campaign_id
                  },
                  payload: {
                    steps: e
                  }
                })
              }}
              stepsAreValid={true}
              messageInstructions={messageInstructions}
              messageInstructionsIsFetching={messageInstructionsIsFetching}
              messageInstructionsIsLoading={messageInstructionsIsLoading}
            />
          </Col>
        </Row>

        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '2rem',
            borderBottom: 'var(--bs-border-width) solid var(--bs-border-color)'
          }}
        >
          <Col
            xs={12}
            xxl={11}
          >
            <h5>Progress</h5>
            <Row style={{display: 'flex', justifyContent: 'center'}}>
              <Col>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingBottom: '1.5rem'
                  }}
                >
                  <Col
                    xs='auto'
                    className='d-flex align-items-center'
                  >
                    <ButtonGroup onClick={(e) => setActiveProgressTab(e.target.value)}>
                      <ToggleButton
                        id='progressTabButtonCampaigns1'
                        type='radio'
                        name='radio'
                        variant='outline-primary'
                        value='companies'
                        checked={activeProgressTab === 'companies'}
                      >
                        Companies
                      </ToggleButton>
                      <ToggleButton
                        id='progressTabButtonCampaigns2'
                        type='radio'
                        name='radio'
                        variant='outline-primary'
                        value='people'
                        checked={activeProgressTab === 'people'}
                      >
                        People
                      </ToggleButton>
                    </ButtonGroup>
                  </Col>
                  <Col xs='auto'>
                    {activeProgressTab === 'companies' ? (
                      <AudienceProgressBarLegend
                        skipped={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates?.skipped
                        }
                        sent={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates?.sent +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates
                            ?.received_response_from_recipient +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates?.received_no_response +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates?.received_bounce
                        }
                        audienceSize={campaign?.companies_in_audience}
                      />
                    ) : (
                      <AudienceProgressBarLegend
                        skipped={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.skipped
                        }
                        sent={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.sent +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates
                            ?.received_response_from_recipient +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.received_no_response +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.received_bounce
                        }
                        audienceSize={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.skipped +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.sent +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.awaiting_message +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.awaiting_outreach +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates
                            ?.received_response_from_recipient +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.received_no_response +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.received_bounce
                        }
                      />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {activeProgressTab === 'companies' ? (
                      <AudienceProgressBar
                        sent={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates?.sent +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates
                            ?.received_response_from_recipient +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates?.received_no_response +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates?.received_bounce
                        }
                        skipped={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.company_status_aggregates?.skipped
                        }
                        audienceSize={campaign?.companies_in_audience}
                      />
                    ) : (
                      <AudienceProgressBar
                        sent={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.sent +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates
                            ?.received_response_from_recipient +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.received_no_response +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.received_bounce
                        }
                        audienceSize={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.skipped +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.sent +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.awaiting_message +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.awaiting_outreach +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates
                            ?.received_response_from_recipient +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.received_no_response +
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.received_bounce
                        }
                        skipped={
                          campaign?.statistics?.outreach_tracking_meta_data
                            ?.person_status_aggregates?.skipped
                        }
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='d-flex align-items-center justify-content-center'>
                {campaign?.campaign_status === 'completed' ? (
                  <>
                    <h5 className='d-text__placement--center'>Campaign completed!</h5>
                    <span
                      className={'material-symbols-outlined'}
                      style={{fontSize: '2rem', marginRight: '0.25em', color: 'green'}}
                    >
                      {'verified'}
                    </span>
                  </>
                ) : campaign?.messages_generated ? (
                  <>
                    <span
                      className={'material-symbols-outlined'}
                      style={{fontSize: '2rem', marginRight: '0.25em', color: 'green'}}
                    >
                      {'verified'}
                    </span>
                    <h5 className='d-text__placement--center'>
                      Ready for next outreach!
                    </h5>
                  </>
                ) : (
                  <>
                    <span
                      className={'material-symbols-outlined'}
                      style={{fontSize: '2rem', marginRight: '0.25em', color: 'orange'}}
                    >
                      {'find_replace'}
                    </span>
                    <h5 className='d-text__placement--center'>
                      Preparing next outreach...
                    </h5>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '2rem',
            borderBottom: 'var(--bs-border-width) solid var(--bs-border-color)'
          }}
        >
          <Col
            xs={12}
            xxl={11}
          >
            <h5 style={{padding: '2rem'}}>Insights</h5>
            <Container
              fluid
              className='searchContainer'
            >
              <Nav
                variant='tabs'
                activeKey={activeInsightsTab}
                onSelect={(k) => {
                  setActiveInsightsTab(k)
                  if (personSearchFormRef.current) {
                    personSearchFormRef.current.reset()
                  }
                  if (companySearchFormRef.current) {
                    companySearchFormRef.current.reset()
                  }
                }}
              >
                <Nav.Item>
                  <Nav.Link eventKey='companies'>
                    <Stack
                      direction='horizontal'
                      gap={2}
                    >
                      Companies
                      <span
                        className={
                          activeInsightsTab === 'companies'
                            ? 'material-symbols-outlined color--accent'
                            : 'material-symbols-outlined color--s-dark'
                        }
                      >
                        {'source_environment'}
                      </span>
                    </Stack>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='people'>
                    <Stack
                      direction='horizontal'
                      gap={2}
                    >
                      People
                      <span
                        className={
                          activeInsightsTab === 'people'
                            ? 'material-symbols-outlined color--accent'
                            : 'material-symbols-outlined color--s-dark'
                        }
                        style={{fontSize: '2rem'}}
                      >
                        {'groups'}
                      </span>
                    </Stack>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {activeInsightsTab === 'companies' && (
                <Row>
                  <Col
                    className='searchCol'
                    xs={3}
                  >
                    <CampaignSearchForm
                      formRef={companySearchFormRef}
                      onFormChange={handleCompaniesSearchFormChange}
                      listOfItems={companySearchFormItems}
                    />
                    <CampaignSearchFormFooter
                      totalResults={companyOutreachTracking?.pages[0]?.total}
                      isLoading={companyOutreachTrackingIsLoading}
                    />
                  </Col>
                  <Col className='resultCol'>
                    <CompanySearchResults
                      companyOutreachTracking={
                        companyOutreachTracking?.pages?.flatMap(
                          (page) => page?.items
                        ) || []
                      }
                      isLoading={companyOutreachTrackingIsLoading}
                      isFetching={
                        companyOutreachTrackingIsFetching || companySearchIsDebouncing
                      }
                      hasNextPage={companyOutreachTrackingHasNextPage}
                      fetchNextPage={companyOutreachTrackingFetchNextPage}
                    />
                  </Col>
                </Row>
              )}
              {activeInsightsTab === 'people' && (
                <Row>
                  <Col
                    className='searchCol'
                    xs={3}
                  >
                    <CampaignSearchForm
                      formRef={personSearchFormRef}
                      onFormChange={handlePersonSearchFormChange}
                      listOfItems={personSearchFormItems}
                    />
                    <CampaignSearchFormFooter
                      totalResults={personOutreachTracking?.pages[0]?.total}
                      isLoading={personOutreachTrackingIsLoading}
                    />
                  </Col>
                  <Col className='resultCol'>
                    <PersonSearchResults
                      personOutreachTracking={
                        personOutreachTracking?.pages.flatMap((page) => page?.items) ||
                        []
                      }
                      isLoading={personOutreachTrackingIsLoading}
                      isFetching={
                        personOutreachTrackingIsFetching || personSearchIsDebouncing
                      }
                      hasNextPage={personOutreachTrackingHasNextPage}
                      fetchNextPage={personOutreachTrackingFetchNextPage}
                      setActivePerson={setActivePerson}
                    />
                  </Col>
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </MainTemplate>
    </>
  )
}
