import {useRecoilState} from 'recoil'
import {
  generatedMessageAtom,
  inputMessagesAtom,
  enrichmentPersonUrlAtom,
  enrichmentCompanyUrlAtom
} from '../../states/atoms/messages.js'
import {messageInstructions} from '../../states/atoms/messageInstructions.js'

export const useGeneratedMessageAtom = () => {
  const [value, setValue] = useRecoilState(generatedMessageAtom)
  return [value, setValue]
}

export const useInputMessagesAtom = () => {
  const [value, setValue] = useRecoilState(inputMessagesAtom)
  return [value, setValue]
}

export const useEnrichmentPersonUrlAtom = () => {
  const [value, setValue] = useRecoilState(enrichmentPersonUrlAtom)
  return [value, setValue]
}

export const useEnrichmentCompanyUrlAtom = () => {
  const [value, setValue] = useRecoilState(enrichmentCompanyUrlAtom)
  return [value, setValue]
}

export const useMessageInstructionsAtom = () => {
  const [messageInstructionsAtom, setMessageInstructionsAtom] =
    useRecoilState(messageInstructions)

  const setMessageInstructionsKey = (key, value) => {
    setMessageInstructionsAtom((_) => ({..._, [key]: value}))
  }

  return {
    messageInstructionsAtom,
    setMessageInstructionsAtom,
    setMessageInstructionsKey
  }
}
