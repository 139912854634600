import {Placeholder as BootstrapPlaceholder} from 'react-bootstrap'

export const Placeholder = ({cols = 12, size = 'lg', style = {}, ...props}) => {
  return (
    <BootstrapPlaceholder
      xs={cols}
      size={size}
      bg='info'
      as='p'
      animation='wave'
      style={{margin: '0px', padding: '0px', opacity: 0.5, ...style}}
      {...props}
    />
  )
}
