import {Spinner} from '../atoms/Spinner.js'

export const CenteredLoadingSpinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Spinner animation='grow' />
    </div>
  )
}
