import {useRecoilState} from 'recoil'
import {
  companySearchSettingsAtom,
  personSearchSettingsAtom
} from '../../states/atoms/audiences.js'
import {nestedSelectorFamily} from '../../states/selectors/generic.js'

export const usePersonSearchSettings = () => {
  const [personSearchSettings, setPersonSearchSettings] = useRecoilState(
    personSearchSettingsAtom
  )
  return {personSearchSettings, setPersonSearchSettings}
}

export const useCompanySearchSettings = () => {
  const [companySearchSettings, setCompanySearchSettings] = useRecoilState(
    companySearchSettingsAtom
  )
  return {companySearchSettings, setCompanySearchSettings}
}

export const useCompanyEmployeesValues = () => {
  const [companyEmployeesValues, setCompanyEmployeesValues] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'employees.values'
    })
  )
  return [companyEmployeesValues, setCompanyEmployeesValues]
}

export const useCompanyCountriesValues = () => {
  const [companyCountriesValues, setCompanyCountriesValues] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'countries.values'
    })
  )
  return [companyCountriesValues, setCompanyCountriesValues]
}

export const useCompanyRevenuesValues = () => {
  const [companyRevenuesValues, setCompanyRevenuesValues] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'revenues.values'
    })
  )
  return [companyRevenuesValues, setCompanyRevenuesValues]
}

export const useCompanyTechnologiesValues = () => {
  const [companyTechnologiesValues, setCompanyTechnologiesValues] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'technologies.values'
    })
  )
  return [companyTechnologiesValues, setCompanyTechnologiesValues]
}

export const useCompanyIndustriesValues = () => {
  const [companyIndustriesValues, setCompanyIndustriesValues] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'industries.values'
    })
  )
  return [companyIndustriesValues, setCompanyIndustriesValues]
}

export const useCompanyNameValues = () => {
  const [companyNameValues, setCompanyNameValues] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'name.values'
    })
  )
  return [companyNameValues, setCompanyNameValues]
}

export const useCompanyLinkedinIdValues = () => {
  const [companyLinkedinIdValues, setCompanyLinkedinIdValues] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'linkedinId.values'
    })
  )
  return [companyLinkedinIdValues, setCompanyLinkedinIdValues]
}

export const useCompanyDomainValues = () => {
  const [companyDomainValues, setCompanyDomainValues] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'domain.values'
    })
  )
  return [companyDomainValues, setCompanyDomainValues]
}

export const useCompanyLinkedinIdSign = () => {
  const [companyLinkedinIdSign, setCompanyLinkedinIdSign] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'linkedinId.sign'
    })
  )
  return [companyLinkedinIdSign, setCompanyLinkedinIdSign]
}

export const useCompanyDomainSign = () => {
  const [companyDomainSign, setCompanyDomainSign] = useRecoilState(
    nestedSelectorFamily({
      atom: companySearchSettingsAtom,
      path: 'domain.sign'
    })
  )
  return [companyDomainSign, setCompanyDomainSign]
}
