export const MatchesFoundRounder = (number) => {
  number = parseInt(number, 10)
  if (number > 9999) {
    return '10.000+'
  } else if (number > 999) {
    const thousands = Math.floor(number / 1000) * 1000
    const remainder = number % 1000
    const rounded = remainder >= 500 ? thousands + 500 : thousands
    return `${rounded}+`
  } else if (number > 99) {
    const hundreds = Math.floor(number / 100) * 100
    const remainder = number % 100
    const rounded = remainder >= 50 ? hundreds + 50 : hundreds
    return `${rounded}+`
  } else {
    return `${number}`
  }
}

export function calculateMaxPeopleOutreach({
  maxOutreachMessages,
  maxOutreachPerPerson
}) {
  maxOutreachMessages = parseInt(maxOutreachMessages, 10)
  maxOutreachPerPerson = parseInt(maxOutreachPerPerson, 10)
  return Math.floor(maxOutreachMessages / maxOutreachPerPerson)
}
export function calculatePeoplePerCompany({peopleInAudience, companiesInAudience}) {
  peopleInAudience = parseInt(peopleInAudience, 10)
  companiesInAudience = parseInt(companiesInAudience, 10)
  return peopleInAudience / companiesInAudience
}

export function calculateCompaniesToReach({
  maxOutreachPeoplePerCompany,
  maxPeopleOutreach,
  peoplePerCompany,
  companiesInAudience
}) {
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)
  maxPeopleOutreach = parseInt(maxPeopleOutreach, 10)
  peoplePerCompany = parseFloat(peoplePerCompany)
  companiesInAudience = parseInt(companiesInAudience, 10)

  const effectivePeopleOutreachPerCompany = Math.min(
    maxOutreachPeoplePerCompany,
    peoplePerCompany
  )

  const potentialCompaniesReached = Math.floor(
    maxPeopleOutreach / effectivePeopleOutreachPerCompany
  )

  return Math.min(
    potentialCompaniesReached,
    companiesInAudience,
    Math.floor(companiesInAudience * peoplePerCompany)
  )
}

export function calculatePeopleToReach({
  companiesToReach,
  maxOutreachPeoplePerCompany,
  maxPeopleOutreach,
  peopleInAudience
}) {
  companiesToReach = parseInt(companiesToReach, 10)
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)
  maxPeopleOutreach = parseInt(maxPeopleOutreach, 10)
  peopleInAudience = parseInt(peopleInAudience, 10)

  return Math.min(
    companiesToReach * maxOutreachPeoplePerCompany,
    maxPeopleOutreach,
    peopleInAudience
  )
}

export function calculateLogDiscrepancyRatio({
  maxOutreachPeoplePerCompany,
  peoplePerCompany
}) {
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)
  peoplePerCompany = parseFloat(peoplePerCompany)
  return Math.log(maxOutreachPeoplePerCompany + 1) - Math.log(peoplePerCompany + 1)
}

export function checkMaxPeopleOutreach({
  maxPeopleOutreach,
  maxOutreachMessagesPerDay,
  maxOutreachPeoplePerCompany
}) {
  maxPeopleOutreach = parseInt(maxPeopleOutreach, 10)
  maxOutreachMessagesPerDay = parseInt(maxOutreachMessagesPerDay, 10)
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)

  if (maxPeopleOutreach < 1 || maxPeopleOutreach > 10000) {
    return 'Total outreach must be a value between 1 and 10,000'
  }

  return null
}

export function checkMaxPeopleOutreachPerDay({maxOutreachMessagesPerDay}) {
  maxOutreachMessagesPerDay = parseInt(maxOutreachMessagesPerDay, 10)

  if (maxOutreachMessagesPerDay < 1 || maxOutreachMessagesPerDay > 50) {
    return 'Outreach per day must a value between 1 and 50'
  }

  return null
}

export function checkMaxPeopleOutreachPerCompany({maxOutreachPeoplePerCompany}) {
  maxOutreachPeoplePerCompany = parseInt(maxOutreachPeoplePerCompany, 10)

  if (maxOutreachPeoplePerCompany < 1 || maxOutreachPeoplePerCompany > 20) {
    return 'Outreach per company must be a value between 1 and 20'
  }

  return null
}

export function checkCompaniesReach({companiesToReach, companiesInAudience}) {
  companiesToReach = parseInt(companiesToReach, 10)
  companiesInAudience = parseInt(companiesInAudience, 10)

  if (companiesToReach / companiesInAudience < 0.25) {
    return 'With the current settings you will reach less than 25% of companies in your audience'
  }

  return null
}

export function checkPeopleReach({
  peopleToReach,
  peopleInAudience,
  logDiscrepancyRatio
}) {
  peopleToReach = parseInt(peopleToReach, 10)
  peopleInAudience = parseInt(peopleInAudience, 10)
  logDiscrepancyRatio = parseFloat(logDiscrepancyRatio)

  if (peopleToReach / peopleInAudience < 0.2) {
    return 'With the current settings you will reach less than 20% of the people in your audience'
  }

  if (logDiscrepancyRatio < -1.5) {
    return 'With the current settings you will reach too few people per company'
  }

  return null
}

export function checkAudiencePeople({peopleInAudience, companiesInAudience}) {
  companiesInAudience = parseInt(companiesInAudience, 10)
  peopleInAudience = parseInt(peopleInAudience, 10)

  if (peopleInAudience > 9999) {
    return 'We only support campaigns with less than 10.000 people currently'
  }

  if (peopleInAudience / companiesInAudience < 0.1) {
    return 'Your Audience contains too few people per company'
  }

  return null
}

export function checkAudienceCompanies({companiesInAudience}) {
  companiesInAudience = parseInt(companiesInAudience, 10)

  if (companiesInAudience > 9999) {
    return 'We only support campaigns with less than 10.000 people companies'
  }

  return null
}
