import {Container, Col, Row} from 'react-bootstrap'
import {Paper} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'
import {Button} from '../../atoms/Button.js'
import {InfiniteLooper} from '../../organisms/InfiniteLooper.js'
import {Masonry} from '@mui/lab'
import {useBreakpoints} from '../../../utils/helpers.js'

export const Data = () => {
  const navigate = useNavigate()
  const {isXs, isSm} = useBreakpoints()

  const contentColClass = 'd-flex flex-column justify-content-center align-items-center'
  const contentColBreakpoints = {
    xxl: 8,
    xl: 10,
    md: 11,
    xs: 12
  }

  const cardsData = [
    {
      header: 'Daily Enriched',
      body: 'To ensure the highest level of accuracy, our data is enriched daily. This continuous updating process allows us to provide our clients with the most current and reliable information, empowering them to make informed decisions with confidence.',
      icon: 'verified'
    },
    {
      header: 'Trusted Partners',
      body: 'We work only with partners who provide accurate, ethically sourced data that complies with EU laws. This ensures our services are reliable, transparent, and aligned with the highest standards of privacy and legality.',
      icon: 'partner_exchange'
    },
    {
      header: 'AI-Powered',
      body: 'With the intelligent use of AI, we put you in control. Our technology allows you to decide where AI makes the biggest impact, ensuring your instructions shape the quality and relevance of your results. This empowers you to focus on what matters most while achieving optimal outcomes.',
      icon: 'speed'
    }
  ]

  const sectionsData = [
    {
      header: 'Increase Revenue',
      body: 'Our AI-powered prospecting helps you identify and engage with the most relevant contacts at your target companies, ensuring that your sales efforts are focused on the opportunities with the highest potential for revenue growth.',
      icon: 'trending_up'
    },
    {
      header: 'Reduce Costs',
      body: 'Save time and money by avoiding complex, time-consuming software. With our streamlined AI-powered solution, you only pay for what you use, ensuring efficiency without unnecessary expenses.',
      icon: 'price_check'
    },
    {
      header: 'Decrease Risk',
      body: 'Minimize the chances of missed opportunities or targeting the wrong prospects by leveraging AI-driven insights. Our system ensures that your outreach efforts are focused on high-potential leads, reducing the risk of wasted resources and maximizing your return on investment.',
      icon: 'thumb_up'
    }
  ]

  const industries = [
    'Services',
    'Information Technology',
    'NEC',
    'Marketing',
    'Advertising',
    'Construction',
    'Internet',
    'Health Care',
    'Hospital',
    'Computer Software',
    'Communication Services',
    'Real Estate',
    'Restaurants',
    'Health',
    'Consumers',
    'Personal Services',
    'Wellness',
    'Management Consulting',
    'Financial Services',
    'Finance',
    'Fitness',
    'Enterprise Software',
    'Data Processing',
    'Computer Programming',
    'Design'
  ]

  const rotatingTexts = [
    {
      header: '54M',
      body: 'Company\nRecords',
      icon: 'domain'
    },
    {
      header: '260M',
      body: 'Employee\nRecords',
      icon: 'groups'
    },
    {
      header: '1M',
      body: 'Weekly Enriched\nRecords',
      icon: 'monitoring'
    },
    {
      header: '5K',
      body: 'Market Standard\nIndustries',
      icon: 'factory'
    },
    {
      header: '190+',
      body: 'Available\nCountries',
      icon: 'globe'
    },
    {
      header: '300+',
      body: 'Company\nTechnologies',
      icon: 'developer_board'
    },
    {
      header: '16',
      body: 'Custom Employee\nSearch Filters',
      icon: 'tune'
    }
  ]

  return (
    <InfoPageTemplate>
      <Container
        id='data'
        fluid
        className='background--p-light d-flex justify-content-center align-items-center'
        style={{paddingTop: '13rem', paddingBottom: '13rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h1 className='d-text__font--heading text__placement--center color--p-dark d-flex align-items-center'>
            Data
          </h1>
          <h3
            style={{padding: '1rem', textAlign: 'center'}}
            className='d-text__font--subheading color--s-dark'
          >
            Find and reach your ideal audience with relevant data.
          </h3>

          <div
            className='d-flex align-items-stretch justify-content-center'
            style={{padding: '2rem'}}
          >
            {cardsData.map((card, index) => (
              <Paper
                key={index}
                className='background--s-light'
                elevation={4}
                style={{padding: '2rem', margin: '1rem', width: '30%'}}
              >
                <h4
                  style={{paddingTop: '1rem'}}
                  className='d-text__font--subheading color--s-dark d-flex align-items-center'
                >
                  <span
                    className={'material-symbols-outlined color--accent'}
                    style={{marginRight: '0.5rem'}}
                  >
                    {card.icon}
                  </span>
                  {card.header}
                </h4>
                <p
                  className='d-text__font--body color--p-dark'
                  style={{textWrap: 'pretty'}}
                >
                  {card.body}
                </p>
              </Paper>
            ))}
          </div>
        </Col>
      </Container>
      <Container
        fluid
        className='background--p-dark flex-column d-flex justify-content-center align-items-center'
        style={{paddingTop: '10rem', paddingBottom: '10rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <h3
            style={{paddingLeft: '3rem', alignSelf: 'start'}}
            className='d-text__font--subheading color--s-light'
          >
            More than just a<span className='color--accent'>{' database'}</span>
          </h3>

          <p
            className='d-text__font--body color--p-light'
            style={{
              paddingLeft: '3rem',
              textWrap: 'balance',
              maxWidth: '80%',
              alignSelf: 'start'
            }}
          >
            Quantity is helpful, but quality is essential. That’s why our data quality
            engine blends AI with human verification to deliver verified B2B emails for
            your top prospects. The outcome? Enhanced targeting, faster connections, and
            more productive conversations.
          </p>

          <Row
            style={{
              justifyContent: 'space-around',
              paddingTop: '7rem',
              marginBottom: '7rem'
            }}
          >
            {sectionsData.map((section) => (
              <Col
                lg={3}
                md={4}
                sm={8}
                xs={10}
                className='d-flex flex-column align-items-center justify-content-start'
              >
                <span
                  className={'material-symbols-outlined color--accent'}
                  style={{fontSize: '1.728rem'}}
                >
                  {section.icon}
                </span>
                <h4 className='d-text__font--subheading color--s-light'>
                  {section.header}
                </h4>
                <p
                  className='d-text__font--body color--p-light'
                  style={{textWrap: 'pretty'}}
                >
                  {section.body}
                </p>
              </Col>
            ))}
          </Row>
        </Col>
        <InfiniteLooper
          speed={60}
          direction='left'
        >
          {rotatingTexts.map((text, index) => (
            <div
              key={index}
              className='d-flex align-items-center justify-content-start'
              style={{margin: '1rem', width: 'auto'}}
            >
              <span
                className={'material-symbols-outlined color--accent'}
                style={{fontSize: '3rem'}}
              >
                {text.icon}
              </span>
              <h2
                className='d-text__font--subheading color--s-light'
                style={{padding: 0, margin: 0, marginLeft: '0.5rem'}}
              >
                {text.header}
              </h2>
              <p
                className='d-text__font--body color--p-light'
                style={{
                  textWrap: 'balance',
                  whiteSpace: 'pre-wrap',
                  padding: 0,
                  marginBottom: 0,
                  lineHeight: '1rem',
                  height: '2rem',
                  marginLeft: '0.5rem'
                }}
              >
                {text.body}
              </p>
            </div>
          ))}
        </InfiniteLooper>
      </Container>

      <Container
        fluid
        className='background--p-light flex-column d-flex justify-content-center align-items-center'
        style={{paddingTop: '10rem', paddingBottom: '10rem'}}
      >
        <Col
          {...contentColBreakpoints}
          className={contentColClass}
        >
          <Paper
            className='background--s-light d-flex flex-column justify-content-centeralign-items-center'
            elevation={1}
            style={{padding: '1rem'}}
          >
            <Paper
              className='background--p-light d-flex flex-column justify-content-center align-items-center'
              variant={'outlined'}
              style={{padding: '1rem', marginBottom: '2rem', width: '100%'}}
            >
              <h3 className='d-text__font--subheading d-text__placement--center color--p-dark'>
                Target a wide array of industries
              </h3>
              <h5
                className='d-text__font--subheading d-text__placement--center color--s-dark'
                style={{textWrap: 'pretty'}}
              >
                Get high-quality lead information and email addresses in your target
                industry
              </h5>
            </Paper>
            <Masonry
              columns={isXs || isSm ? 3 : 5}
              spacing={4}
            >
              {industries.map((industry, index) => (
                <p
                  className='d-text__font--heading d-text__placement--center color--p-dark'
                  style={{textWrap: 'nowrap'}}
                  key={index}
                >
                  {industry}
                </p>
              ))}
            </Masonry>
            <h5 className='d-text__font--subheading d-text__placement--center color--accent'>
              And many more...
            </h5>
          </Paper>

          <Paper
            className='background--s-dark d-flex justify-content-around align-items-center'
            variant='outlined'
            style={{marginTop: '7rem', padding: '2rem'}}
          >
            <div
              className='d-flex flex-column justify-content-start align-items-start'
              style={{maxWidth: '50%'}}
            >
              <h3
                style={{alignSelf: 'start', textAlign: 'start'}}
                className='d-text__font--subheading color--s-light'
              >
                Ready to reach out?
              </h3>
              <p
                className='d-text__font--body color--p-light d-flex align-items-start'
                style={{textWrap: 'pretty'}}
              >
                Millions of companies at the reach of your fingertips. Reach out to us!
              </p>
            </div>
            <Button
              style={{margin: '1rem'}}
              children={
                <>
                  {'Schedule Demo'}
                  <span
                    className='material-symbols-outlined'
                    style={{marginLeft: '0.5rem'}}
                  >
                    {'arrow_right_alt'}
                  </span>
                </>
              }
              onClick={() => {
                navigate('/demo#schedule')
              }}
              variant='outline-secondary'
            />
          </Paper>
        </Col>
      </Container>
    </InfoPageTemplate>
  )
}
