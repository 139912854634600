// UTILS
import {MatchesFoundRounder} from '../../utils/calculations.js'

//ATOMS
import {
  Accordion,
  Container,
  Card,
  ToggleButton,
  ToggleButtonGroup,
  Stack
} from 'react-bootstrap'

//MOLECULES
import {MultiInputField} from '../molecules/MultiInputField.js'
import {LoadingButton} from '../molecules/LoadingButton.js'
import {DynamicCheckboxes} from '../molecules/DynamicCheckboxes.js'
import {DynamicSelector} from '../molecules/DynamicSelector.js'
import {FakeBreadcrumbs} from '../molecules/FakeBreadcrumbs.js'
import {FileUploader} from '../molecules/FileUploader.js'

//ORGANISM
export const AudienceSearchSidebar = ({
  resultsFound,
  searchCallback,
  searchIsLoading,
  accordionOnSelect,
  filters,
  workflowSteps,
  onWorkflowStepSelect
}) => {
  return (
    <Container
      fluid
      style={{
        display: 'flex',
        flexFlow: 'column wrap',
        padding: '0',
        alignItems: 'center'
      }}
    >
      <FakeBreadcrumbs
        workflowSteps={workflowSteps}
        onWorkflowStepSelect={onWorkflowStepSelect}
        style={{alignSelf: 'center', flexWrap: 'wrap'}}
      />
      <LoadingButton
        onClick={searchCallback}
        isLoading={searchIsLoading}
        style={{margin: '0.5em'}}
      />
      <Card.Header>
        <h4 className='d-text__font--subheading d-text__placement--center color--p-dark'>
          {`Results found: ${MatchesFoundRounder(resultsFound)}`}
        </h4>
      </Card.Header>
      <Accordion
        alwaysOpen
        onSelect={accordionOnSelect}
        style={{
          borderLeft: 'none',
          borderRight: 'none',
          alignSelf: 'stretch'
        }}
      >
        {filters.map((filter, index) => (
          <Accordion.Item
            eventKey={filter.eventKey}
            key={index}
            style={{borderLeft: 'none', borderRight: 'none'}}
          >
            <Accordion.Header>
              {
                <Stack
                  direction='horizontal'
                  gap={2}
                >
                  {<filter.icon style={{width: '1.5rem', height: 'inherit'}} />}
                  {filter.header}
                </Stack>
              }
            </Accordion.Header>
            <Accordion.Body>
              <Stack
                direction='vertical'
                gap={2}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  {filter.allowUpload && (
                    <FileUploader
                      id={`file-input-${index}`}
                      onFileLoaded={(newValues) => filter.onValuesChange(newValues)}
                    />
                  )}

                  {filter.signOptions && (
                    <ToggleButtonGroup
                      type='radio'
                      name={`bg-${filter.eventKey}`}
                      className='radioButtons'
                      onChange={filter.onSignChange}
                      value={filter.selectedSign}
                    >
                      {filter.signOptions.map((sign) => (
                        <ToggleButton
                          key={sign.value}
                          id={`toggle-${sign.value}-${filter.eventKey}`}
                          value={sign.value}
                          variant='outline-secondary'
                        >
                          {sign.label}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  )}
                </div>
                {filter.type === 'selector' && (
                  <DynamicSelector
                    options={filter.availableValues}
                    optionsLoading={filter.availableValuesLoading}
                    optionsLoadingError={filter.availableValuesLoadingError}
                    selectedValues={filter.selectedValues}
                    onChange={filter.onValuesChange}
                  />
                )}
                {filter.type === 'checkboxes' && (
                  <DynamicCheckboxes
                    options={filter.availableValues}
                    optionsLoading={filter.availableValuesLoading}
                    optionsLoadingError={filter.availableValuesLoadingError}
                    selectedValues={filter.selectedValues}
                    onChange={filter.onValuesChange}
                  />
                )}
                {filter.type === 'multiInput' && (
                  <MultiInputField
                    selectedValues={filter.selectedValues}
                    onChange={filter.onValuesChange}
                  />
                )}
              </Stack>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  )
}
