//Atoms
import {Stack, Modal, Form, Col, FloatingLabel} from 'react-bootstrap'

import {Spinner} from '../atoms/Spinner.js'

//MOLECULES
import {ActionWithConfirmation} from '../molecules/ActionWithConfirmation.js'

//Utils
import {formatDate} from '../../utils/helpers.js'

export const MessageModal = ({
  show,
  onHide,
  label,
  emailSubject,
  emailBody,
  updatedAt,
  onDelete,
  onDeleteIsPending
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
    >
      <Modal.Header>
        <Stack
          direction='vertical'
          gap={0}
        >
          <h4>{label}</h4>
          <Stack
            direction='horizontal'
            gap={1}
          >
            <span className={'material-symbols-outlined color--s-dark'}>
              {'calendar_today'}
            </span>
            <small
              style={{
                fontStyle: 'italic',
                opacity: '0.7'
              }}
            >
              {formatDate(updatedAt)}
            </small>
          </Stack>
        </Stack>
        {onDelete && !onDeleteIsPending && (
          <ActionWithConfirmation
            callback={() => onDelete()}
            disabled={false}
          />
        )}
        {onDeleteIsPending && <Spinner size='sm' />}
      </Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          {!!emailSubject && (
            <Form.Group as={Col}>
              <FloatingLabel label='Email subject'>
                <Form.Control
                  value={emailSubject}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>
          )}
          <Form.Group as={Col}>
            <div dangerouslySetInnerHTML={{__html: emailBody}} />
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
