import {Image} from '../atoms/Image.js'

export const ImageWithHyperlink = ({
  src,
  href,
  style = {},
  isLoading = false
}) => {
  return (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Image
        src={src}
        style={style}
        isLoading={isLoading}
      />
    </a>
  )
}
