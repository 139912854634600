import {useCallback, useReducer, useEffect} from 'react'

import {SidebarTemplate} from '../templates/SidebarTemplate.js'

import {AudienceSearchSidebar} from '../organisms/AudienceSearchSidebar.js'
import {CompanySearchResults} from '../organisms/CompanySearchResults.js'
import {PeopleSearchResults} from '../organisms/PeopleSearchResults.js'
import {AudienceSummarySidebar} from '../organisms/AudienceSummarySidebar.js'
import {AudienceSearchSummary} from '../organisms/AudienceSearchSummary.js'
import {CenteredLoadingSpinner} from '../molecules/CenteredLoadingSpinner.js'
import {ReactComponent as Linkedin} from '../atoms/assets/linkedin.svg'
import {ReactComponent as CompanyPlaceholder} from '../atoms/assets/CompanyPlaceholder.svg'
import {ReactComponent as Employees} from '../atoms/assets/Employees.svg'
import {ReactComponent as Industry} from '../atoms/assets/Industry.svg'
import {ReactComponent as Keywords} from '../atoms/assets/Keywords.svg'
import {ReactComponent as Location} from '../atoms/assets/Location.svg'
import {ReactComponent as Revenue} from '../atoms/assets/Revenue.svg'
import {ReactComponent as Seniority} from '../atoms/assets/Seniority.svg'
import {ReactComponent as Technologies} from '../atoms/assets/Technologies.svg'
import {ReactComponent as Globe} from '../atoms/assets/globe.svg'

import * as AudiencesServerState from '../../hooks/server/useAudiencesServerState.js'
import * as ClientState from '../../hooks/client/useAudiencesClientState.js'

const uiInitialState = {
  workflowSteps: {
    companies: {
      selected: true,
      enabled: true,
      label: 'Companies'
    },
    people: {
      selected: false,
      enabled: false,
      label: 'People'
    },
    summary: {
      selected: false,
      enabled: false,
      label: 'Summary'
    }
  },
  openAccordionTabs: {
    CompanyName: false,
    CompanyTechnologies: false,
    CompanyIndustries: false,
    CompanyCountries: false,
    CompanyEmployees: false,
    CompanyRevenues: false,
    PersonCountries: false,
    PersonDepartments: false,
    PersonSeniorities: false
  },
  companiesSearchSettings: {params: {size: 10}, payload: {}},
  peopleSearchSettings: {params: {}, payload: {}}
}

const uiStateReducer = (state, action) => {
  switch (action.type) {
    case 'SET_KEY_VALUE':
      const {key, value} = action.payload
      return {...state, [key]: value}
    default:
      return state
  }
}

export const AudienceBuilder = () => {
  //LOCAL CLIENT STATES
  const [uiState, dispatch] = useReducer(uiStateReducer, uiInitialState)

  //GLOBAL CLIENT STATES
  const {personSearchSettings, setPersonSearchSettings} =
    ClientState.usePersonSearchSettings()
  const {companySearchSettings} = ClientState.useCompanySearchSettings()
  const [companyEmployeesValues, setCompanyEmployeesValues] =
    ClientState.useCompanyEmployeesValues()
  const [companyCountriesValues, setCompanyCountriesValues] =
    ClientState.useCompanyCountriesValues()
  const [companyIndustriesValues, setCompanyIndustriesValues] =
    ClientState.useCompanyIndustriesValues()
  const [companyRevenuesValues, setCompanyRevenuesValues] =
    ClientState.useCompanyRevenuesValues()
  const [companyTechnologiesValues, setCompanyTechnologiesValues] =
    ClientState.useCompanyTechnologiesValues()
  const [companyNameValues, setCompanyNameValues] = ClientState.useCompanyNameValues()

  const [companyLinkedinIdValues, setCompanyLinkedinIdValues] =
    ClientState.useCompanyLinkedinIdValues()
  const [companyLinkedinIdSign, setCompanyLinkedinIdSign] =
    ClientState.useCompanyLinkedinIdSign()

  const [companyDomainValues, setCompanyDomainValues] =
    ClientState.useCompanyDomainValues()
  const [companyDomainSign, setCompanyDomainSign] = ClientState.useCompanyDomainSign()

  //SERVER STATES
  const {
    data: companyEmployeeOptions,
    isLoading: companyEmployeeOptionsLoading,
    error: companyEmployeeOptionsError
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'company_employees'},
    enabled: uiState.openAccordionTabs.CompanyEmployees
  })
  const {
    data: companyCountryOptions,
    isLoading: companyCountryOptionsLoading,
    error: companyCountryOptionsError
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'company_countries'},
    enabled: uiState.openAccordionTabs.CompanyCountries
  })
  const {
    data: companyIndustryOptions,
    isLoading: companyIndustryOptionsLoading,
    error: companyIndustryOptionsError
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'company_industries'},
    enabled: uiState.openAccordionTabs.CompanyIndustries
  })
  const {
    data: companyTechnologiesOptions,
    isLoading: companyTechnologiesOptionsLoading,
    error: companyTechnologiesOptionsError
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'company_technologies'},
    enabled: uiState.openAccordionTabs.CompanyTechnologies
  })
  const {
    data: companyRevenueOptions,
    isLoading: companyRevenueOptionsLoading,
    error: companyRevenueOptionsError
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'company_revenues'},
    enabled: uiState.openAccordionTabs.CompanyRevenues
  })
  const {
    data: personCountryOptions,
    isLoading: personCountryOptionsLoading,
    error: personCountryOptionsError
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'person_countries'},
    enabled: uiState.openAccordionTabs.PersonCountries
  })
  const {
    data: personSeniorityOptions,
    isLoading: personSeniorityOptionsLoading,
    error: personSeniorityOptionsError
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'person_seniorities'},
    enabled: uiState.openAccordionTabs.PersonSeniorities
  })
  const {
    data: personDepartmentOptions,
    isLoading: personDepartmentOptionsLoading,
    error: personDepartmentOptionsError
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'person_departments'},
    enabled: uiState.openAccordionTabs.PersonDepartments
  })
  const {
    data: companiesSearchResults,
    isLoading: companiesSearchResultsLoading,
    error: companiesSearchResultsError,
    fetchNextPage: companiesSearchResultsNextPageFetch,
    hasNextPage: companiesSearchResultsNextPageExists,
    isFetchingNextPage: companiesSearchResultsNextPageLoading
  } = AudiencesServerState.usePostSearchCompanies({
    payload: uiState.companiesSearchSettings.payload,
    params: uiState.companiesSearchSettings.params
  })
  const {
    data: peopleSearchResults,
    isLoading: peopleSearchResultsLoading,
    error: peopleSearchResultsError,
    fetchNextPage: peopleSearchResultsNextPageFetch,
    hasNextPage: peopleSearchResultsNextPageExists,
    isFetchingNextPage: peopleSearchResultsNextPageLoading
  } = AudiencesServerState.usePostSearchPeople({
    payload: uiState.peopleSearchSettings.payload,
    params: uiState.peopleSearchSettings.params
  })
  const {
    mutate: addAudience,
    isPending: addAudienceLoading,
    error: addAudienceError
  } = AudiencesServerState.usePostAudience()

  const {
    mutate: updateAudience,
    isPending: updateAudienceLoading,
    error: updateAudienceError
  } = AudiencesServerState.usePatchAudience()

  const {
    data: audience,
    isLoading: audienceIsLoading,
    IsFetching: audienceIsFetching,
    error: audienceError
  } = AudiencesServerState.useGetAudiences()

  //   LISTENERS
  useEffect(() => {
    const newWorkflowSteps = {
      ...uiState.workflowSteps,
      summary: {
        ...uiState.workflowSteps.summary,
        enabled: false
      }
    }
    dispatch({
      type: 'SET_KEY_VALUE',
      payload: {
        key: 'workflowSteps',
        value: newWorkflowSteps
      }
    })
  }, [personSearchSettings])

  useEffect(() => {
    const newWorkflowSteps = {
      ...uiState.workflowSteps,
      people: {
        ...uiState.workflowSteps.people,
        enabled: false
      },
      summary: {
        ...uiState.workflowSteps.summary,
        enabled: false
      }
    }
    dispatch({
      type: 'SET_KEY_VALUE',
      payload: {
        key: 'workflowSteps',
        value: newWorkflowSteps
      }
    })
  }, [companySearchSettings])

  useEffect(() => {
    const companies =
      companiesSearchResults?.pages?.flatMap((page) => page.companies) ?? []
    const companyLinkedinIds =
      companies
        ?.map((company) => company?.socialNetworks?.linkedinIdAlpha)
        .filter((id) => id) || []
    const companiesFound = companiesSearchResults?.pages?.[0]?.meta?.total ?? 0
    setPersonSearchSettings((prevSettings) => ({
      ...prevSettings,
      linkedinIds: companyLinkedinIds,
      companiesCount: companiesFound
    }))
  }, [companiesSearchResults, setPersonSearchSettings])

  // HANDLERS
  const handleUpdateMessageInstructions = useCallback(
    (templateId, templateName) => {
      updateAudience({
        params: {audience_id: templateId},
        payload: {
          companies_in_audience: companiesSearchResults?.pages?.[0].meta?.total,
          company_search_filters: uiState.companiesSearchSettings.payload,
          label: templateName,
          people_in_audience: peopleSearchResults?.pages?.[0].total_search_results,
          person_search_filters: uiState.peopleSearchSettings.payload
        }
      })
    },
    [uiState, updateAudience, companiesSearchResults, peopleSearchResults]
  )

  const handleSaveMessageInstructions = useCallback(
    (name) => {
      addAudience({
        payload: {
          companies_in_audience: companiesSearchResults?.pages?.[0].meta?.total,
          company_search_filters: uiState.companiesSearchSettings.payload,
          label: name,
          people_in_audience: peopleSearchResults?.pages?.[0].total_search_results,
          person_search_filters: uiState.peopleSearchSettings.payload
        }
      })
    },
    [uiState, addAudience, companiesSearchResults, peopleSearchResults]
  )

  const handleSearchCompanies = useCallback(
    (size) => {
      const params = {
        size: size !== undefined ? size : companySearchSettings.size
      }
      const payload = {
        totalEmployees: {...companySearchSettings.employees},
        revenue: {...companySearchSettings.revenues},
        technologies: {...companySearchSettings.technologies},
        industryMain: {...companySearchSettings.industries},
        country_code: {...companySearchSettings.countries},
        linkedinId: {...companySearchSettings.linkedinId},
        domain: {...companySearchSettings.domain},
        name: {...companySearchSettings.name}
      }
      dispatch({
        type: 'SET_KEY_VALUE',
        payload: {
          key: 'companiesSearchSettings',
          value: {params, payload}
        }
      })

      const newWorkflowSteps = {
        ...uiState.workflowSteps,
        people: {
          ...uiState.workflowSteps.people,
          enabled: true
        }
      }
      dispatch({
        type: 'SET_KEY_VALUE',
        payload: {
          key: 'workflowSteps',
          value: newWorkflowSteps
        }
      })
    },
    [companySearchSettings, uiState.workflowSteps]
  )

  const handleWorkflowChange = useCallback(
    (workflowStep, newState) => {
      if (
        newState.label === 'People' &&
        !uiState.workflowSteps.people.selected &&
        newState.selected
      ) {
        handleSearchCompanies(100)
        handleSearchPeople()
      }
      const newWorkflowSteps = Object.keys(uiState.workflowSteps).reduce((acc, key) => {
        acc[key] = {
          ...uiState.workflowSteps[key],
          selected: key === workflowStep
        }
        return acc
      }, {})
      dispatch({
        type: 'SET_KEY_VALUE',
        payload: {
          key: 'workflowSteps',
          value: newWorkflowSteps
        }
      })
    },
    [uiState, handleSearchCompanies]
  )

  const handleSearchPeople = useCallback(() => {
    if (personSearchSettings?.linkedinIds.length > 0) {
      const params = {
        companies_found: personSearchSettings.companiesCount
      }
      const payload = {
        countries: personSearchSettings.countries,
        seniorities: personSearchSettings.seniorities,
        departments: personSearchSettings.departments,
        keywords: personSearchSettings.keywords,
        organization_linkedin_public_identifiers: personSearchSettings.linkedinIds
      }
      dispatch({
        type: 'SET_KEY_VALUE',
        payload: {
          key: 'peopleSearchSettings',
          value: {params, payload}
        }
      })

      const newWorkflowSteps = {
        ...uiState.workflowSteps,
        summary: {
          ...uiState.workflowSteps.summary,
          enabled: true
        }
      }
      dispatch({
        type: 'SET_KEY_VALUE',
        payload: {
          key: 'workflowSteps',
          value: newWorkflowSteps
        }
      })
    }
  }, [personSearchSettings, uiState.workflowSteps])

  const handleCheckboxChange = useCallback(
    (currentValues, setter, value, isChecked) => {
      const newValue = isChecked
        ? [...currentValues, value]
        : currentValues.filter((item) => item !== value)
      setter(newValue)
    },
    []
  )

  const handleAccordionExpansion = useCallback((allTabs, openTabs) => {
    const updatedAccordionTabs = Object.keys(allTabs).reduce((acc, key) => {
      acc[key] = openTabs.includes(key)
      return acc
    }, {})

    dispatch({
      type: 'SET_KEY_VALUE',
      payload: {key: 'openAccordionTabs', value: updatedAccordionTabs}
    })
  }, [])

  // COMPONENTS
  const companySearchFilters = [
    {
      eventKey: 'CompanyEmployees',
      header: 'Employees',
      icon: Employees,
      type: 'checkboxes',
      availableValues: companyEmployeeOptions,
      availableValuesLoading: companyEmployeeOptionsLoading,
      availableValuesLoadingError: companyEmployeeOptionsError,
      selectedValues: companyEmployeesValues,
      onValuesChange: (e) =>
        handleCheckboxChange(
          companyEmployeesValues,
          setCompanyEmployeesValues,
          e.target.value,
          e.target.checked
        )
    },
    {
      eventKey: 'CompanyRevenues',
      header: 'Revenue',
      type: 'checkboxes',
      icon: Revenue,
      availableValues: companyRevenueOptions,
      availableValuesLoading: companyRevenueOptionsLoading,
      availableValuesLoadingError: companyRevenueOptionsError,
      selectedValues: companyRevenuesValues,
      onValuesChange: (e) =>
        handleCheckboxChange(
          companyRevenuesValues,
          setCompanyRevenuesValues,
          e.target.value,
          e.target.checked
        )
    },
    {
      eventKey: 'CompanyCountries',
      header: 'Country',
      icon: Location,
      type: 'selector',
      availableValues: companyCountryOptions,
      availableValuesLoading: companyCountryOptionsLoading,
      availableValuesLoadingError: companyCountryOptionsError,
      selectedValues: companyCountriesValues,
      onValuesChange: (e) => setCompanyCountriesValues(e.map((obj) => obj.value))
    },
    {
      eventKey: 'CompanyIndustries',
      header: 'Industry',
      icon: Industry,
      type: 'selector',
      availableValues: companyIndustryOptions,
      availableValuesLoading: companyIndustryOptionsLoading,
      availableValuesLoadingError: companyIndustryOptionsError,
      selectedValues: companyIndustriesValues,
      onValuesChange: (e) => setCompanyIndustriesValues(e.map((obj) => obj.value))
    },
    {
      eventKey: 'CompanyTechnologies',
      header: 'Technologies',
      icon: Technologies,
      type: 'selector',
      availableValues: companyTechnologiesOptions,
      availableValuesLoading: companyTechnologiesOptionsLoading,
      availableValuesLoadingError: companyTechnologiesOptionsError,
      selectedValues: companyTechnologiesValues,
      onValuesChange: (e) => setCompanyTechnologiesValues(e.map((obj) => obj.value))
    },
    {
      eventKey: 'CompanyLinkedinId',
      header: 'Linkedin',
      icon: Linkedin,
      type: 'multiInput',
      selectedValues: companyLinkedinIdValues,
      onValuesChange: (e) => setCompanyLinkedinIdValues(e),
      signOptions: [{value: 'notEquals', label: 'Exclude'}],
      selectedSign: companyLinkedinIdSign,
      onSignChange: (e) => setCompanyLinkedinIdSign(e),
      allowUpload: true
    },
    {
      eventKey: 'CompanyDomain',
      header: 'Domain',
      icon: Globe,
      type: 'multiInput',
      selectedValues: companyDomainValues,
      onValuesChange: (e) => setCompanyDomainValues(e),
      signOptions: [{value: 'notEquals', label: 'Exclude'}],
      selectedSign: companyDomainSign,
      onSignChange: (e) => setCompanyDomainSign(e),
      allowUpload: true
    },
    {
      eventKey: 'CompanyName',
      header: 'Name',
      icon: CompanyPlaceholder,
      type: 'multiInput',
      selectedValues: companyNameValues,
      onValuesChange: (e) => setCompanyNameValues(e),
      allowUpload: true
    }
  ]

  const personSearchFilters = [
    {
      eventKey: 'PersonSeniorities',
      header: 'Seniority',
      icon: Seniority,
      type: 'checkboxes',
      availableValues: personSeniorityOptions,
      availableValuesLoading: personSeniorityOptionsLoading,
      availableValuesLoadingError: personSeniorityOptionsError,
      selectedValues: personSearchSettings.seniorities,
      onValuesChange: (e) =>
        handleCheckboxChange(
          personSearchSettings.seniorities,
          (val) =>
            setPersonSearchSettings({
              ...personSearchSettings,
              seniorities: val
            }),
          e.target.value,
          e.target.checked
        )
    },
    {
      eventKey: 'PersonDepartments',
      header: 'Department',
      icon: Employees,
      type: 'selector',
      availableValues: personDepartmentOptions,
      availableValuesLoading: personDepartmentOptionsLoading,
      availableValuesLoadingError: personDepartmentOptionsError,
      selectedValues: personSearchSettings.departments,
      onValuesChange: (e) => {
        setPersonSearchSettings({
          ...personSearchSettings,
          departments: e.map((obj) => obj.value)
        })
      }
    },
    {
      eventKey: 'PersonCountries',
      header: 'Country',
      icon: Location,
      type: 'selector',
      availableValues: personCountryOptions,
      availableValuesLoading: personCountryOptionsLoading,
      availableValuesLoadingError: personCountryOptionsError,
      selectedValues: personSearchSettings.countries,
      onValuesChange: (e) => {
        setPersonSearchSettings((prevSettings) => ({
          ...prevSettings,
          countries: e.map((obj) => obj.value)
        }))
      }
    },
    {
      eventKey: 'PersonKeywords',
      header: 'Keywords',
      icon: Keywords,
      type: 'multiInput',
      selectedValues: personSearchSettings.keywords,
      onValuesChange: (selectedValues) =>
        setPersonSearchSettings({
          ...personSearchSettings,
          keywords: selectedValues
        }),
      allowUpload: true
    }
  ]

  if (audienceIsLoading) {
    return (
      <SidebarTemplate
        sidebar={<CenteredLoadingSpinner />}
        content={<CenteredLoadingSpinner />}
      />
    )
  }

  if (uiState.workflowSteps.companies.selected) {
    return (
      <SidebarTemplate
        sidebar={
          <AudienceSearchSidebar
            workflowSteps={uiState.workflowSteps}
            onWorkflowStepSelect={handleWorkflowChange}
            resultsFound={companiesSearchResults?.pages?.[0].meta?.total || 0}
            searchCallback={() => handleSearchCompanies()}
            searchIsLoading={companiesSearchResultsLoading}
            accordionOnSelect={(openTabs) =>
              handleAccordionExpansion(uiState.openAccordionTabs, openTabs)
            }
            filters={companySearchFilters}
          />
        }
        content={
          <CompanySearchResults
            searchResults={
              companiesSearchResults?.pages?.flatMap((page) => page.companies) ?? []
            }
            isLoading={companiesSearchResultsLoading}
            error={companiesSearchResultsError}
            loadMoreCallback={companiesSearchResultsNextPageFetch}
            hasNextPage={companiesSearchResultsNextPageExists}
            isFetchingNextPage={companiesSearchResultsNextPageLoading}
          />
        }
      />
    )
  } else if (uiState.workflowSteps.people.selected) {
    return (
      <SidebarTemplate
        sidebar={
          <AudienceSearchSidebar
            workflowSteps={uiState.workflowSteps}
            onWorkflowStepSelect={handleWorkflowChange}
            resultsFound={peopleSearchResults?.pages?.[0].total_search_results || 0}
            searchCallback={handleSearchPeople}
            searchIsLoading={
              peopleSearchResultsLoading || companiesSearchResultsLoading
            }
            accordionOnSelect={(openTabs) =>
              handleAccordionExpansion(uiState.openAccordionTabs, openTabs)
            }
            filters={personSearchFilters}
          />
        }
        content={
          <PeopleSearchResults
            searchResults={
              peopleSearchResults?.pages?.flatMap((page) => page.search_results) ?? []
            }
            isLoading={peopleSearchResultsLoading}
            error={peopleSearchResultsError}
            loadMoreCallback={peopleSearchResultsNextPageFetch}
            hasNextPage={peopleSearchResultsNextPageExists}
            isFetchingNextPage={peopleSearchResultsNextPageLoading}
          />
        }
      />
    )
  } else if (uiState.workflowSteps.summary.selected) {
    return (
      <SidebarTemplate
        sidebar={
          <AudienceSummarySidebar
            workflowSteps={uiState.workflowSteps}
            onWorkflowStepSelect={handleWorkflowChange}
            filters={[...companySearchFilters, ...personSearchFilters]}
          />
        }
        content={
          <AudienceSearchSummary
            companiesFound={companiesSearchResults?.pages?.[0].meta?.total || 0}
            peopleFound={peopleSearchResults?.pages?.[0].total_search_results || 0}
            onUpdateOption={handleUpdateMessageInstructions}
            onUpdateOptionIsLoading={updateAudienceLoading}
            onUpdateOptionError={updateAudienceError}
            onSaveOption={handleSaveMessageInstructions}
            onSaveOptionIsLoading={addAudienceLoading}
            onSaveOptionError={addAudienceError}
            userTemplates={audience}
            audienceIsFetching={audienceIsFetching}
            userTemplatesError={audienceError}
          />
        }
      />
    )
  }
}
