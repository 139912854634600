import {atom} from 'recoil'
import {sessionStorageEffect} from './effects.js'

//ATOMS
export const campaignBuilderAtom = atom({
  key: 'campaignBuilder',
  default: {
    campaignName: null,
    campaignDescription: null,
    campaignSortingInstructions: null,
    maxOutreachMessages: null,
    maxOutreachMessagesPerDay: null,
    maxOutreachPeoplePerCompany: null,
    steps: [{index: 0, message_instructions_id: ''}],
    peopleInAudience: null,
    companiesInAudience: null,
    audienceId: null
  },
  effects: [sessionStorageEffect('campaignBuilderInput')]
})
