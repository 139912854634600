import {useEffect, useRef} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {usePutUserThirdPartyProvisions} from '../../hooks/server/useUserServerState.js'
import {useUserAtom} from '../../hooks/client/useUserClientState.js'
import {CenteredLoadingSpinner} from '../molecules/CenteredLoadingSpinner.js'

export const OAuthCallback = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const hasFetchedProvisionsRef = useRef(false)
  const {mutate: putUserTPP} = usePutUserThirdPartyProvisions()
  const [user] = useUserAtom()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const authorization_code = urlParams.get('code')

    const fetchProvisions = async () => {
      if (authorization_code && !hasFetchedProvisionsRef.current) {
        hasFetchedProvisionsRef.current = true
        putUserTPP({
          authorization_code: authorization_code,
          provider: user.thirdPartyProvisionsRequestProvider,
          scope: user.thirdPartyProvisionsRequestScope
        })
      }
    }
    fetchProvisions()
  }, [navigate, location, user, putUserTPP])

  return (
    <>
      <CenteredLoadingSpinner />
      <div>Processing authentication...</div>
    </>
  )
}
