import {Spinner} from '../components/atoms/Spinner.js'
import {useState, useEffect} from 'react'

export function findValueByKey(
  key, //key
  options //[[key, value]]
) {
  const option = options?.find(([optionKey]) => optionKey === key)
  return option ? option[1] : 'Unknown'
}

export function formatDashedString(str) {
  return str
    ?.split('-')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ')
}

export function formatUnderscoreString(str) {
  return str
    ?.split('_')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ')
}

export const randomizeNumberCols = () => {
  const totalCols = 11
  let remainingCols = totalCols
  const colsArray = []

  while (remainingCols > 0 && colsArray.length < 3) {
    const cols = Math.min(Math.floor(Math.random() * 4) + 1, remainingCols)
    colsArray.push(cols)
    remainingCols -= cols
  }
  return colsArray
}

export function formatDate(dateString, includeTime = true) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  if (includeTime) {
    options.hour = '2-digit'
    options.minute = '2-digit'
    options.second = '2-digit'
    options.hour12 = false
  }
  try {
    const date = new Date(dateString)
    return new Intl.DateTimeFormat('default', options).format(date)
  } catch {
    return ''
  }
}

export const getCheckedValues = (form, groupName) => {
  const checkboxes = form.querySelectorAll(`input[name="${groupName}"]:checked`)
  const checkedValues = Array.from(checkboxes)
    .map((box) => box.value)
    .join(',')
  return checkedValues
}

export const getOutreachStatusLabel = (status) => {
  const statusMessages = {
    skipped: 'Skipped',
    awaiting_people: 'In Queue',
    awaiting_message: 'In Queue',
    awaiting_outreach: 'In Queue',
    sent: 'Sent',
    received_response_from_recipient: 'Received Response',
    received_no_response: 'Sent',
    received_bounce: 'Received Bounce'
  }

  return statusMessages[status]
}

export const getSkipReasonLabel = (skipReason) => {
  const SkipReasonMessages = {
    missing_company_name: 'Insufficient Information',
    missing_relevant_people: 'Missing relevant people',
    missing_person_linkedin_id: 'Insufficient Information',
    missing_person_summary: 'Insufficient Information',
    missing_company_linkedin_id: 'Insufficient Information',
    missing_company_summary: 'Insufficient Information',
    missing_person_email: 'Missing Email',
    found_better_candidate: 'Found Better Candidate',
    failed_sending_email: 'Failed Sending Email',
    person_exists_for_multiple_companies: 'Found Better Candidate'
  }
  return SkipReasonMessages[skipReason]
}

export const getCampaignStatusMessage = (campaignStatus, campaignReviewReason) => {
  const statusMessages = {
    needs_review: {
      failed_retrieving_valid_access_token:
        'Email account required for email outreach has been disconnected. Please reconnect your email in the account page.',
      failed_due_to_insufficient_credits:
        'Insufficient credits to proceed with the campaign.',
      default:
        'Something went wrong with the execution of your campaign. We are looking into it. Sorry for the inconvenience.'
    },
    in_progress: 'Campaign is in progress',
    paused: 'Campaign is paused',
    completed: 'Campaign has been completed!'
  }
  if (campaignStatus === 'needs_review' && campaignReviewReason) {
    return (
      statusMessages[campaignStatus][campaignReviewReason] ||
      statusMessages[campaignStatus].default
    )
  }
  return statusMessages[campaignStatus]
}

export const getCampaignStatusIcon = (campaignStatus, campaignReviewReason = null) => {
  const icons = {
    needs_review: {
      failed_retrieving_valid_access_token: (
        <span
          className='material-symbols-outlined'
          style={{fontSize: '1.5rem', color: 'red'}}
        >
          {'mail_lock'}
        </span>
      ),
      failed_due_to_insufficient_credits: (
        <span
          className='material-symbols-outlined'
          style={{fontSize: '1.5rem', color: 'red'}}
        >
          {'money_off'}
        </span>
      ),
      default: (
        <span
          className='material-symbols-outlined'
          style={{fontSize: '1.5rem', color: 'red'}}
        >
          {'sync_problem'}
        </span>
      )
    },
    in_progress: (
      <span
        className='material-symbols-outlined'
        style={{fontSize: '1.5rem', color: 'orange'}}
      >
        {'play_circle'}
      </span>
    ),
    paused: (
      <span
        className='material-symbols-outlined'
        style={{fontSize: '1.5rem', color: 'gray'}}
      >
        {'pause'}
      </span>
    ),
    completed: (
      <span
        className='material-symbols-outlined'
        style={{fontSize: '1.5rem', color: 'green'}}
      >
        {'check_circle'}
      </span>
    )
  }

  if (campaignStatus === 'needs_review' && campaignReviewReason) {
    return icons[campaignStatus][campaignReviewReason] || icons[campaignStatus].default
  }
  return icons[campaignStatus]
}

export const getCampaignStatusControllerIcon = ({
  campaignId,
  campaignStatus,
  campaignReviewReason,
  patchCampaignCallback,
  patchCampaignIsPending
}) => {
  if (patchCampaignIsPending) {
    return <Spinner />
  }

  const iconProps = {
    className:
      'material-symbols-outlined color--p-dark clickable transition-fast t-scale--small t-color--accent',
    style: {fontSize: '2rem'}
  }
  const icons = {
    needs_review: {
      failed_retrieving_valid_access_token: (
        <span
          {...iconProps}
          onClick={() =>
            patchCampaignCallback({
              params: {campaign_id: campaignId},
              payload: {campaign_status: 'in_progress', campaign_review_reason: null}
            })
          }
        >
          {'play_circle'}
        </span>
      ),
      failed_due_to_insufficient_credits: (
        <span
          {...iconProps}
          onClick={() =>
            patchCampaignCallback({
              params: {campaign_id: campaignId},
              payload: {campaign_status: 'in_progress', campaign_review_reason: null}
            })
          }
        >
          {'play_circle'}
        </span>
      ),
      default: (
        <span
          className='material-symbols-outlined color--s-dark'
          style={{fontSize: '2rem'}}
        >
          {'sync_problem'}
        </span>
      )
    },
    in_progress: (
      <span
        {...iconProps}
        onClick={() =>
          patchCampaignCallback({
            params: {campaign_id: campaignId},
            payload: {campaign_status: 'paused'}
          })
        }
      >
        {'pause'}
      </span>
    ),
    paused: (
      <span
        {...iconProps}
        onClick={() =>
          patchCampaignCallback({
            params: {campaign_id: campaignId},
            payload: {campaign_status: 'in_progress'}
          })
        }
      >
        {'play_circle'}
      </span>
    ),
    completed: (
      <span
        style={{fontSize: '2rem', color: 'green'}}
        className='material-symbols-outlined'
      >
        {'check_circle'}
      </span>
    )
  }

  if (campaignStatus === 'needs_review' && campaignReviewReason) {
    return icons[campaignStatus][campaignReviewReason] || icons[campaignStatus].default
  }
  return icons[campaignStatus]
}

export function useBreakpoints() {
  const breakpoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400
  }

  const [breakpointsState, setBreakpointsState] = useState({
    isXs: window.innerWidth < breakpoints.xs,
    isSm: window.innerWidth >= breakpoints.xs && window.innerWidth < breakpoints.sm,
    isMd: window.innerWidth >= breakpoints.sm && window.innerWidth < breakpoints.md,
    isLg: window.innerWidth >= breakpoints.md && window.innerWidth < breakpoints.lg,
    isXl: window.innerWidth >= breakpoints.lg && window.innerWidth < breakpoints.xl,
    isXxl: window.innerWidth >= breakpoints.xl
  })

  useEffect(() => {
    const handleResize = () => {
      setBreakpointsState({
        isXs: window.innerWidth < breakpoints.xs,
        isSm: window.innerWidth >= breakpoints.xs && window.innerWidth < breakpoints.sm,
        isMd: window.innerWidth >= breakpoints.sm && window.innerWidth < breakpoints.md,
        isLg: window.innerWidth >= breakpoints.md && window.innerWidth < breakpoints.lg,
        isXl: window.innerWidth >= breakpoints.lg && window.innerWidth < breakpoints.xl,
        isXxl: window.innerWidth >= breakpoints.xl
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return breakpointsState
}
