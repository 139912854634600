//Atoms
import {Stack, Modal, Form, Col, Row, FloatingLabel} from 'react-bootstrap'
import {useRef} from 'react'

import {Spinner} from '../atoms/Spinner.js'

//MOLECULES
import {ActionWithConfirmation} from '../molecules/ActionWithConfirmation.js'

//Utils
import {findValueByKey, formatDashedString, formatDate} from '../../utils/helpers.js'
import {MatchesFoundRounder} from '../../utils/calculations.js'
import {useAutosizeTextArea} from '../../utils/hooks.js'

export const AudienceModal = ({
  show,
  onHide,
  label,
  companiesInAudience,
  peopleInAudience,
  companyEmployees,
  companyRevenue,
  companyCountryCode,
  companyCountryOptions,
  companyIndustryMain,
  companyTechnologies,
  companyName,
  personSeniorities,
  personDepartments,
  personCountries,
  personCountryOptions,
  personKeywords,
  updatedAt,
  onDelete,
  onDeleteIsPending,
  usedInCampaign,
  onEdit
}) => {
  const companyEmployeesRef = useRef(null)
  const companyRevenueRef = useRef(null)
  const companyCountryCodeRef = useRef(null)
  const companyIndustryMainRef = useRef(null)
  const companyTechnologiesRef = useRef(null)
  const companyNameRef = useRef(null)
  const personSenioritiesRef = useRef(null)
  const personDepartmentsRef = useRef(null)
  const personKeywordsRef = useRef(null)
  const personCountriesRef = useRef(null)

  useAutosizeTextArea(companyEmployeesRef, companyEmployees?.values, show)
  useAutosizeTextArea(companyRevenueRef, companyRevenue?.values, show)
  useAutosizeTextArea(companyCountryCodeRef, companyCountryCode?.values, show)
  useAutosizeTextArea(companyIndustryMainRef, companyIndustryMain?.values, show)
  useAutosizeTextArea(companyTechnologiesRef, companyTechnologies?.values, show)
  useAutosizeTextArea(companyNameRef, companyName?.values, show)
  useAutosizeTextArea(personCountriesRef, personCountries, show)
  useAutosizeTextArea(personSenioritiesRef, personSeniorities, show)
  useAutosizeTextArea(personDepartmentsRef, personDepartments, show)
  useAutosizeTextArea(personKeywordsRef, personKeywords, show)

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
    >
      <Modal.Header>
        <Stack
          direction='vertical'
          gap={0}
        >
          <h4>{label}</h4>
          <Stack
            direction='horizontal'
            gap={1}
          >
            <span className={'material-symbols-outlined color--s-dark'}>
              {'calendar_today'}
            </span>

            <small style={{fontStyle: 'italic', opacity: '0.7'}}>
              {formatDate(updatedAt)}
            </small>
          </Stack>
        </Stack>
        {onEdit && (
          <span
            className='material-symbols-outlined color--p-dark clickable transition-fast t-scale--small t-color--accent'
            onClick={onEdit}
          >
            {'edit_square'}
          </span>
        )}
        {usedInCampaign && (
          <span
            title='Audience is used in a campaign'
            style={{color: 'green'}}
            className='material-symbols-outlined'
          >
            {'campaign'}
          </span>
        )}
        {onDelete && !onDeleteIsPending && (
          <ActionWithConfirmation
            callback={() => onDelete()}
            disabled={usedInCampaign}
          />
        )}
        {onDeleteIsPending && <Spinner size='sm' />}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={6}>
            <Form className='d-form'>
              <h6>{`Companies: ${MatchesFoundRounder(companiesInAudience)}`}</h6>
              <Form.Group as={Col}>
                <FloatingLabel label='Employees'>
                  <Form.Control
                    as='textarea'
                    value={companyEmployees?.values?.join(', ') || 'Any'}
                    ref={companyEmployeesRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label='Revenue'>
                  <Form.Control
                    as='textarea'
                    value={
                      companyRevenue?.values?.map(formatDashedString)?.join(', ') ||
                      'Any'
                    }
                    ref={companyRevenueRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label='Country Code'>
                  <Form.Control
                    as='textarea'
                    value={
                      companyCountryCode?.values
                        ?.map((code) => findValueByKey(code, companyCountryOptions))
                        ?.join(', ') || 'Any'
                    }
                    ref={companyCountryCodeRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label='Industry Main'>
                  <Form.Control
                    as='textarea'
                    value={
                      companyIndustryMain?.values
                        ?.map(formatDashedString)
                        ?.join(', ') || 'Any'
                    }
                    ref={companyIndustryMainRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label='Technologies'>
                  <Form.Control
                    as='textarea'
                    value={
                      companyTechnologies?.values
                        ?.map(formatDashedString)
                        ?.join(', ') || 'Any'
                    }
                    ref={companyTechnologiesRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label='Name'>
                  <Form.Control
                    as='textarea'
                    value={companyName?.values?.join(', ') || 'Any'}
                    ref={companyNameRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={6}>
            <Form className='d-form'>
              <h6>{`People: ${MatchesFoundRounder(peopleInAudience)}`}</h6>
              <Form.Group as={Col}>
                <FloatingLabel label='Seniorities'>
                  <Form.Control
                    as='textarea'
                    value={
                      personSeniorities?.map(formatDashedString)?.join(', ') || 'Any'
                    }
                    ref={personSenioritiesRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label='Departments'>
                  <Form.Control
                    as='textarea'
                    value={
                      personDepartments?.map(formatDashedString)?.join(', ') || 'Any'
                    }
                    ref={personDepartmentsRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label='Country'>
                  <Form.Control
                    as='textarea'
                    value={
                      personCountries
                        ?.map((code) => findValueByKey(code, personCountryOptions))
                        ?.join(', ') || 'Any'
                    }
                    ref={personCountriesRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label='Keywords'>
                  <Form.Control
                    as='textarea'
                    value={personKeywords?.join(', ') || 'None'}
                    ref={personKeywordsRef}
                    readOnly
                  />
                </FloatingLabel>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
