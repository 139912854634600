// UTILS
import {MatchesFoundRounder} from '../../utils/calculations.js'

//ATOMS
import {useState} from 'react'
import {Container, Stack} from 'react-bootstrap'
import {Button} from '../atoms/Button.js'

//MOLECULES
import {SelectCreateOptions} from '../molecules/SelectCreateOptions.js'

export const AudienceSearchSummary = ({
  companiesFound,
  peopleFound,
  onSaveOption,
  onSaveOptionIsLoading,
  onSaveOptionError,
  onUpdateOption,
  onUpdateOptionIsLoading,
  onUpdateOptionError,
  userTemplates,
  audienceIsFetching,
  userTemplatesError
}) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const selectedOptionId = selectedOption?.value
  const selectedOptionName = selectedOption?.label
  const templateIds = userTemplates.map(
    (messageInstruction) => messageInstruction.audience_id
  )

  return (
    <Container fluid>
      <Stack
        direction={'vertical'}
        gap={3}
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <h3>Results</h3>
        <large>
          {`Found ${MatchesFoundRounder(
            companiesFound
          )} companies and within those ${MatchesFoundRounder(
            peopleFound
          )} people matching your criteria.`}
        </large>

        <Stack
          direction='horizontal'
          gap={2}
          style={{alignSelf: 'center'}}
        >
          <SelectCreateOptions
            options={userTemplates.map((messageInstruction) => ({
              label: messageInstruction.label,
              value: messageInstruction.audience_id
            }))}
            optionsLoading={audienceIsFetching}
            optionsLoadingError={userTemplatesError}
            onChange={(val) => setSelectedOption(val)}
            placeholder={'Save Audience As...'}
          />

          {templateIds.includes(selectedOptionId) ? (
            <Button
              children={'Update'}
              onClick={() => onUpdateOption(selectedOptionId, selectedOptionName)}
              disabled={onUpdateOptionIsLoading || !selectedOption}
              isLoading={onUpdateOptionIsLoading}
            />
          ) : (
            <Button
              children={'Save'}
              onClick={() => onSaveOption(selectedOptionName)}
              disabled={
                onSaveOptionIsLoading ||
                !selectedOption ||
                selectedOptionName?.length < 1
              }
              isLoading={onSaveOptionIsLoading}
            />
          )}
        </Stack>
      </Stack>
    </Container>
  )
}
