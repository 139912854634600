import {OverlayTrigger, Tooltip} from 'react-bootstrap'

export const WarningIconWithTooltip = ({message = 'Something went wrong!'}) => {
  return (
    <OverlayTrigger
      placement='right'
      overlay={<Tooltip id='tooltip-right'>{message}</Tooltip>}
    >
      <span
        className='material-symbols-outlined'
        style={{
          color: 'red',
          fontVariationSettings: '"FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24'
        }}
      >
        {'sync_problem'}
      </span>
    </OverlayTrigger>
  )
}
