import {atom} from 'recoil'
import {sessionStorageEffect} from './effects.js'

//ATOMS
export const companySearchSettingsAtom = atom({
  key: 'companySearchSettings',
  default: {
    employees: {values: [], sign: 'equals', operator: 'or'},
    countries: {values: [], sign: 'equals', operator: 'or'},
    revenues: {values: [], sign: 'equals', operator: 'or'},
    industries: {values: [], sign: 'equals', operator: 'or'},
    technologies: {values: [], sign: 'equals', operator: 'or'},
    linkedinId: {values: [], sign: 'notEquals', operator: 'or'},
    domain: {values: [], sign: 'notEquals', operator: 'or'},
    name: {values: [], sign: 'equals', operator: 'or'},
    size: 10
  },
  effects: [sessionStorageEffect('companySearchSettings')]
})

export const personSearchSettingsAtom = atom({
  key: 'personSearchSettings',
  default: {
    linkedinIds: [],
    countries: [],
    seniorities: [],
    departments: [],
    keywords: [],
    companiesCount: 0
  },
  effects: [sessionStorageEffect('personSearchSettings')]
})
