import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import * as AgentServerState from '../../hooks/server/useAgentServerState.js'
import * as AudiencesServerState from '../../hooks/server/useAudiencesServerState.js'
import * as CampaignsServerState from '../../hooks/server/useCampaignsServerState.js'

import * as AudiencesClientState from '../../hooks/client/useAudiencesClientState.js'
import {useMessageInstructionsAtom} from '../../hooks/client/useAgentClientState.js'

import {MainTemplate} from '../templates/MainTemplate.js'
import {AudienceModal} from '../organisms/AudienceModal.js'
import {MessageInstructionsModal} from '../organisms/MessageInstructionsModal.js'
import {MessageModal} from '../organisms/MessageModal.js'
import {ResourceListItem} from '../molecules/ResourceListItem.js'
import {CampaignListItem} from '../molecules/CampaignListItem.js'

import {Card, ListGroup, Stack, Row} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'
import {Paper} from '@mui/material'

export const Dashboard = () => {
  //SERVER STATES
  const {
    data: messageInstructions,
    isFetching: messageInstructionsIsFetching,
    isLoading: messageInstructionsIsLoading
  } = AgentServerState.useGetMessageInstructions()
  const {mutate: deleteMessageInstructions} =
    AgentServerState.useDeleteMessageInstructions()

  const {
    data: messages,
    isFetching: messagesIsFetching,
    isLoading: messagesIsLoading
  } = AgentServerState.useGetMessages()
  const {mutate: deleteMessage} = AgentServerState.useDeleteMessage()

  const {
    data: audiences,
    isFetching: audiencesIsFetching,
    isLoading: audiencesIsLoading
  } = AudiencesServerState.useGetAudiences()
  const {mutate: deleteAudience} = AudiencesServerState.useDeleteAudience()

  const {
    data: campaigns,
    isFetching: campaignsIsFetching,
    isLoading: campaignsIsLoading
  } = CampaignsServerState.useGetCampaigns({
    params: {compute_statistics: true, per_page: 10000, page: 0}
  })

  const {
    data: companyCountryOptions,
    isFetching: companyCountryOptionsIsFetching,
    isLoading: companyCountryOptionsIsLoading
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'company_countries'}
  })
  const {
    data: personCountryOptions,
    isFetching: personCountryOptionsIsFetching,
    isLoading: personCountryOptionsIsLoading
  } = AudiencesServerState.useGetSearchFilterOptions({
    params: {field: 'person_countries'}
  })

  //GLOBAL UI STATES
  const {setPersonSearchSettings} = AudiencesClientState.usePersonSearchSettings()
  const {setCompanySearchSettings} = AudiencesClientState.useCompanySearchSettings()
  const {setMessageInstructionsAtom} = useMessageInstructionsAtom()

  //LOCAL UI STATES
  const navigate = useNavigate()

  const [itemsBeingDeleted, setItemsBeingDeleted] = useState([])
  const [setMessageInstructionsModal, setShowMessageInstructionsModal] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [showAudienceModal, setShowAudienceModal] = useState(false)

  const [activeMessageInstructions, setActiveMessageInstructions] = useState(null)
  const [activeMessage, setActiveMessage] = useState(null)
  const [activeAudience, setActiveAudience] = useState(null)

  return (
    <>
      <AudienceModal
        show={showAudienceModal}
        onHide={() => {
          setShowAudienceModal(false)
          setActiveAudience(null)
        }}
        label={activeAudience?.label || ''}
        companiesInAudience={activeAudience?.companies_in_audience || ''}
        peopleInAudience={activeAudience?.people_in_audience || ''}
        companyEmployees={activeAudience?.company_search_filters?.totalEmployees || {}}
        companyRevenue={activeAudience?.company_search_filters?.revenue || {}}
        companyCountryCode={activeAudience?.company_search_filters?.country_code || {}}
        companyCountryOptions={companyCountryOptions || {}}
        companyIndustryMain={activeAudience?.company_search_filters?.industryMain || {}}
        companyTechnologies={activeAudience?.company_search_filters?.technologies || {}}
        companyName={activeAudience?.company_search_filters?.name || {}}
        personSeniorities={activeAudience?.person_search_filters?.seniorities || []}
        personDepartments={activeAudience?.person_search_filters?.departments || []}
        personCountries={activeAudience?.person_search_filters?.countries || []}
        personCountryOptions={personCountryOptions || []}
        personKeywords={activeAudience?.person_search_filters?.keywords || []}
        updatedAt={activeAudience?.updated_at || ''}
        usedInCampaign={activeAudience?.used_in_campaign || false}
        onDelete={() => {
          setItemsBeingDeleted((currentItems) => [
            ...currentItems,
            activeAudience?.audience_id
          ])
          deleteAudience(
            {
              params: {audience_id: activeAudience?.audience_id}
            },
            {
              onSettled: () => {
                setItemsBeingDeleted(
                  itemsBeingDeleted.filter((i) => i !== activeAudience?.audience_id)
                )
              },
              onSuccess: () => {
                setShowAudienceModal(false)
                setActiveAudience(null)
              }
            }
          )
        }}
        onDeleteIsPending={itemsBeingDeleted.includes(activeAudience?.audience_id)}
        onEdit={() => {
          setCompanySearchSettings({
            employees: activeAudience?.company_search_filters?.totalEmployees,
            countries: activeAudience?.company_search_filters?.country_code,
            revenues: activeAudience?.company_search_filters?.revenue,
            industries: activeAudience?.company_search_filters?.industryMain,
            technologies: activeAudience?.company_search_filters?.technologies,
            linkedinId: activeAudience?.company_search_filters?.linkedinId || {
              values: [],
              sign: 'notEquals',
              operator: 'or'
            },
            domain: activeAudience?.company_search_filters?.domain || {
              values: [],
              sign: 'notEquals',
              operator: 'or'
            },
            name: activeAudience?.company_search_filters?.name,
            size: 10
          })

          setPersonSearchSettings({
            linkedinIds:
              activeAudience?.person_search_filters
                ?.organization_linkedin_public_identifiers,
            countries: activeAudience?.person_search_filters?.countries,
            seniorities: activeAudience?.person_search_filters?.seniorities,
            departments: activeAudience?.person_search_filters?.departments,
            keywords: activeAudience?.person_search_filters?.keywords,
            companiesCount: activeAudience?.companies_in_audience
          })

          navigate(`/audience-builder`)
        }}
      />
      <MessageModal
        show={showMessageModal}
        onHide={() => {
          setShowMessageModal(false)
          setActiveMessage(null)
        }}
        label={activeMessage?.label}
        emailSubject={activeMessage?.message_header || ''}
        emailBody={activeMessage?.message_body || ''}
        updatedAt={activeMessage?.updated_at || ''}
        onDelete={() => {
          setItemsBeingDeleted((currentItems) => [
            ...currentItems,
            activeMessage?.message_id
          ])
          deleteMessage(
            {
              params: {message_id: activeMessage?.message_id}
            },
            {
              onSettled: () => {
                setItemsBeingDeleted(
                  itemsBeingDeleted.filter((i) => i !== activeMessage?.message_id)
                )
              },
              onSuccess: () => {
                setShowMessageModal(false)
                setActiveMessage(null)
              }
            }
          )
        }}
        onDeleteIsPending={itemsBeingDeleted.includes(activeMessage?.message_id)}
      />
      <MessageInstructionsModal
        show={setMessageInstructionsModal}
        onHide={() => {
          setShowMessageInstructionsModal(false)
          setActiveMessageInstructions(null)
        }}
        label={activeMessageInstructions?.label || ''}
        title={activeMessageInstructions?.title || ''}
        companyName={activeMessageInstructions?.company_name || ''}
        intention={activeMessageInstructions?.intention || ''}
        language={activeMessageInstructions?.language || ''}
        outreachMethod={activeMessageInstructions?.outreach_method || ''}
        outreachStage={activeMessageInstructions?.outreach_stage || ''}
        messageSignature={activeMessageInstructions?.message_signature || ''}
        preferences={activeMessageInstructions?.message_preferences || []}
        usedInCampaign={activeMessageInstructions?.used_in_campaign || false}
        valueProposition={activeMessageInstructions?.value_proposition || ''}
        updatedAt={activeMessageInstructions?.updated_at || ''}
        onDelete={() => {
          setItemsBeingDeleted((currentItems) => [
            ...currentItems,
            activeMessageInstructions?.message_instructions_id
          ])
          deleteMessageInstructions(
            {
              params: {
                message_instructions_id:
                  activeMessageInstructions?.message_instructions_id
              }
            },
            {
              onSettled: () => {
                setItemsBeingDeleted(
                  itemsBeingDeleted.filter(
                    (i) => i !== activeMessageInstructions?.message_instructions_id
                  )
                )
              },
              onSuccess: () => {
                setShowMessageInstructionsModal(false)
                setActiveMessageInstructions(null)
              }
            }
          )
        }}
        onDeleteIsPending={itemsBeingDeleted.includes(
          activeMessageInstructions?.message_instructions_id
        )}
        onEdit={() => {
          setMessageInstructionsAtom({
            messageInstructionsId: activeMessageInstructions?.message_instructions_id,
            label: activeMessageInstructions?.label,
            language: activeMessageInstructions?.language,
            title: activeMessageInstructions?.title,
            companyName: activeMessageInstructions?.company_name,
            valueProposition: activeMessageInstructions?.value_proposition,
            intention: activeMessageInstructions?.intention,
            preferences: activeMessageInstructions?.message_preferences,
            outreachMethod: activeMessageInstructions?.outreach_method,
            outreachStage: activeMessageInstructions?.outreach_stage
          })
          navigate(`/message-builder`)
        }}
      />
      <MainTemplate>
        <Stack
          direction='horizontal'
          style={{width: '100%', justifyContent: 'space-evenly', alignItems: 'start'}}
        >
          <Paper
            className='d-flex flex-column align-items-center background--s-light transition-medium t-background--p-light t-border-color--accent'
            elevation={1}
            style={{
              padding: '1rem',
              margin: '0.8rem',
              border: '1px solid var(--secondary-light)',
              width: '30%',
              height: '100%'
            }}
          >
            <h3
              className='d-text__font--heading d-text__placement--center color--s-dark'
              style={{paddingBottom: '2rem'}}
            >
              Instructions
            </h3>
            {(messageInstructionsIsFetching || messageInstructionsIsLoading) && (
              <Spinner style={{paddingBottom: '-2rem'}} />
            )}
            <ListGroup style={{width: '100%'}}>
              {messageInstructions?.map((messageInstruction) => (
                <ResourceListItem
                  label={messageInstruction?.label}
                  labelIcon={messageInstruction?.outreach_method}
                  usedInCampaign={messageInstruction?.used_in_campaign}
                  onClick={() => {
                    setActiveMessageInstructions(messageInstruction)
                    setShowMessageInstructionsModal(true)
                  }}
                  key={messageInstruction?.message_instructions_id}
                />
              ))}
            </ListGroup>
          </Paper>

          <Paper
            className='d-flex flex-column align-items-center background--s-light transition-medium t-background--p-light t-border-color--accent'
            elevation={1}
            style={{
              padding: '1rem',
              margin: '0.8rem',
              border: '1px solid var(--secondary-light)',
              width: '30%',
              height: '100%'
            }}
          >
            <h3
              className='d-text__font--heading d-text__placement--center color--s-dark'
              style={{paddingBottom: '2rem'}}
            >
              Messages
            </h3>
            {(messagesIsFetching || messagesIsLoading) && (
              <Spinner style={{paddingBottom: '-2rem'}} />
            )}
            <ListGroup style={{width: '100%'}}>
              {messages?.map((message) => (
                <ResourceListItem
                  label={message?.label}
                  labelIcon={message?.outreach_method}
                  onClick={() => {
                    setActiveMessage(message)
                    setShowMessageModal(true)
                  }}
                  key={message?.message_id}
                />
              ))}
            </ListGroup>
          </Paper>

          <Paper
            className='d-flex flex-column align-items-center background--s-light transition-medium t-background--p-light t-border-color--accent'
            elevation={1}
            style={{
              padding: '1rem',
              margin: '0.8rem',
              border: '1px solid var(--secondary-light)',
              width: '30%',
              height: '100%'
            }}
          >
            <h3
              className='d-text__font--heading d-text__placement--center color--s-dark'
              style={{paddingBottom: '2rem'}}
            >
              Audiences
            </h3>
            {(audiencesIsFetching ||
              audiencesIsLoading ||
              personCountryOptionsIsFetching ||
              personCountryOptionsIsLoading ||
              companyCountryOptionsIsFetching ||
              companyCountryOptionsIsLoading) && (
              <Spinner style={{paddingBottom: '-2rem'}} />
            )}
            <ListGroup style={{width: '100%'}}>
              {audiences?.map((audience) => (
                <ResourceListItem
                  label={audience?.label}
                  labelIcon={'people'}
                  usedInCampaign={audience?.used_in_campaign}
                  onClick={() => {
                    setActiveAudience(audience)
                    setShowAudienceModal(true)
                  }}
                  key={audience?.audience_id}
                />
              ))}
            </ListGroup>
          </Paper>
        </Stack>

        <Row
          style={{
            padding: '2rem',
            width: '100%',
            justifyContent: 'space-evenly',
            alignItems: 'start'
          }}
        >
          <Paper
            className='d-flex flex-column align-items-center justify-content-center background--s-light transition-medium t-background--p-light t-border-color--accent'
            elevation={1}
            style={{
              padding: '1rem',
              margin: '0.8rem',
              border: '1px solid var(--secondary-light)'
            }}
          >
            <h3
              className='d-text__font--heading d-text__placement--center color--s-dark'
              style={{paddingBottom: '2rem'}}
            >
              Campaigns
            </h3>
            {(campaignsIsLoading || campaignsIsFetching) && (
              <Spinner style={{paddingBottom: '-2rem'}} />
            )}
            <ListGroup style={{width: '100%'}}>
              {campaigns?.items?.map((campaign) => (
                <CampaignListItem
                  key={campaign?.campaign_id}
                  onClick={() =>
                    navigate(`/campaign-insights/?campaign_id=${campaign?.campaign_id}`)
                  }
                  campaignId={campaign?.campaign_id}
                  campaignName={campaign?.campaign_name}
                  campaignStatus={campaign?.campaign_status}
                  campaignReviewReason={campaign?.campaign_review_reason}
                  companiesSent={
                    campaign?.statistics?.outreach_tracking_meta_data
                      ?.company_status_aggregates?.sent +
                    campaign?.statistics?.outreach_tracking_meta_data
                      ?.company_status_aggregates?.received_response_from_recipient +
                    campaign?.statistics?.outreach_tracking_meta_data
                      ?.company_status_aggregates?.received_no_response +
                    campaign?.statistics?.outreach_tracking_meta_data
                      ?.company_status_aggregates?.received_bounce
                  }
                  companiesSkipped={
                    campaign?.statistics?.outreach_tracking_meta_data
                      ?.company_status_aggregates?.skipped
                  }
                  companiesInAudience={campaign?.companies_in_audience}
                />
              ))}
            </ListGroup>
          </Paper>

          {/* <Card>
            <Card.Header>
              <Stack
                direction='vertical'
                style={{display: 'flex', alignItems: 'center'}}
                gap={1}
              >
                Campaigns
                {(campaignsIsLoading || campaignsIsFetching) && (
                  <Spinner
                    size='sm'
                    animation='grow'
                    style={{marginBottom: '-1rem', padding: 0}}
                  />
                )}
                <ListGroup style={{width: '100%'}}>
                  {campaigns?.items?.map((campaign) => (
                    <CampaignListItem
                      key={campaign?.campaign_id}
                      onClick={() =>
                        navigate(
                          `/campaign-insights/?campaign_id=${campaign?.campaign_id}`
                        )
                      }
                      campaignId={campaign?.campaign_id}
                      campaignName={campaign?.campaign_name}
                      campaignStatus={campaign?.campaign_status}
                      campaignReviewReason={campaign?.campaign_review_reason}
                      companiesSent={
                        campaign?.statistics?.outreach_tracking_meta_data
                          ?.company_status_aggregates?.sent +
                        campaign?.statistics?.outreach_tracking_meta_data
                          ?.company_status_aggregates
                          ?.received_response_from_recipient +
                        campaign?.statistics?.outreach_tracking_meta_data
                          ?.company_status_aggregates?.received_no_response +
                        campaign?.statistics?.outreach_tracking_meta_data
                          ?.company_status_aggregates?.received_bounce
                      }
                      companiesSkipped={
                        campaign?.statistics?.outreach_tracking_meta_data
                          ?.company_status_aggregates?.skipped
                      }
                      companiesInAudience={campaign?.companies_in_audience}
                    />
                  ))}
                </ListGroup>
              </Stack>
            </Card.Header>
            <Card.Body>
              <ListGroup style={{width: '100%'}}>
                {campaigns?.items?.map((campaign) => (
                  <CampaignListItem
                    key={campaign?.campaign_id}
                    onClick={() =>
                      navigate(
                        `/campaign-insights/?campaign_id=${campaign?.campaign_id}`
                      )
                    }
                    campaignId={campaign?.campaign_id}
                    campaignName={campaign?.campaign_name}
                    campaignStatus={campaign?.campaign_status}
                    campaignReviewReason={campaign?.campaign_review_reason}
                    companiesSent={
                      campaign?.statistics?.outreach_tracking_meta_data
                        ?.company_status_aggregates?.sent +
                      campaign?.statistics?.outreach_tracking_meta_data
                        ?.company_status_aggregates?.received_response_from_recipient +
                      campaign?.statistics?.outreach_tracking_meta_data
                        ?.company_status_aggregates?.received_no_response +
                      campaign?.statistics?.outreach_tracking_meta_data
                        ?.company_status_aggregates?.received_bounce
                    }
                    companiesSkipped={
                      campaign?.statistics?.outreach_tracking_meta_data
                        ?.company_status_aggregates?.skipped
                    }
                    companiesInAudience={campaign?.companies_in_audience}
                  />
                ))}
              </ListGroup>
            </Card.Body>
          </Card> */}
        </Row>
      </MainTemplate>
    </>
  )
}
