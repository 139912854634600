import {ListGroup, Stack} from 'react-bootstrap'

export const ResourceListItem = ({label, labelIcon, usedInCampaign, onClick}) => {
  return (
    <ListGroup.Item
      as='li'
      className='transition-fast clickable t-scale--large t-box-shadow--shallow'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
      onClick={onClick}
    >
      <Stack
        direction='horizontal'
        gap={1}
        style={{width: '100%'}}
      >
        {labelIcon === 'email' && (
          <span
            className={'material-symbols-outlined color--s-dark'}
            style={{marginRight: '0.5rem'}}
          >
            {'mail'}
          </span>
        )}
        {labelIcon === 'people' && (
          <span
            className={'material-symbols-outlined color--s-dark'}
            style={{marginRight: '0.5rem'}}
          >
            {'groups'}
          </span>
        )}
        <p
          className='d-text__font--heading d-text__placement--center color--p-dark'
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {label}
        </p>
        <div className='p-1 ms-auto'></div>
        {usedInCampaign && (
          <span
            className={'material-symbols-outlined'}
            style={{color: 'green'}}
            title='Used in a campaign!'
          >
            {'campaign'}
          </span>
        )}
      </Stack>
    </ListGroup.Item>
  )
}
