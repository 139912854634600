import {useEffect, useState} from 'react'
import {
  Form,
  Row,
  Stack,
  Col,
  Card,
  FloatingLabel,
  ListGroup,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'

import {formatDate} from '../../utils/helpers.js'

import {useUserAtom} from '../../hooks/client/useUserClientState.js'
import * as UserServerState from '../../hooks/server/useUserServerState.js'
import {useGetClientConfig} from '../../hooks/server/useUtilitiesServerState.js'

import {MainTemplate} from '../templates/MainTemplate.js'

import {Button} from '../atoms/Button.js'
import {Spinner} from '../atoms/Spinner.js'

import {ReactComponent as MicrosoftSignInWithIcon} from '../atoms/assets/MicrosoftSignInWith.svg'
import {ReactComponent as GoogleContinueWithIcon} from '../atoms/assets/GoogleContinueWith.svg'
import {ReactComponent as OutlookIcon} from '../atoms/assets/Outlook.svg'
import GmailIcon from '../atoms/assets/Gmail.png'

export const getPlanLabel = (userCredit) => {
  if (userCredit?.refill_amount === 350) return 'Starter'
  if (userCredit?.refill_amount === 1300) return 'Standard'
  if (userCredit?.refill_amount === 3200) return 'Premium'
  return 'Custom'
}

export const getConnectedEmail = (userTPP) => {
  if (userTPP?.provider === 'google') return 'Google'
  if (userTPP?.provider === 'microsoft') return 'Microsoft'
  return 'Please Connect An Account!'
}

const renderTooltip = (props, text) => (
  <Tooltip
    id='button-tooltip'
    {...props}
  >
    {text}
  </Tooltip>
)

export const Profile = () => {
  //GLOBAL UI STATE
  const [user, setUser, setUserKey] = useUserAtom()

  //LOCAL UI STATE
  const [showConnectionModel, setShowConnectionModal] = useState(false)

  //SERVER STATE
  const {data: clientConfig} = useGetClientConfig()
  const {mutate: initiateGoogleOAuth} = UserServerState.useInitiateGoogleOAuth()
  const {mutate: initiateMicrosoftOAuth} = UserServerState.useInitiateMicrosoftOAuth()
  const {data: userTPP, isFetching: userTPPIsFetching} =
    UserServerState.useGetUserThirdPartyProvisions()

  const {data: firebaseUserId, isLoading: firebaseUserIdIsLoading} =
    UserServerState.useFirebaseUserId()
  const {
    data: userIdentity,
    isFetching: userIdentityIsFetching,
    isLoading: userIdentityIsLoading
  } = UserServerState.useGetUserIdentity({
    params: {firebase_user_id: firebaseUserId},
    enabled: !firebaseUserIdIsLoading
  })

  const {mutate: patchUserProfile, isPending: patchUserProfileIsPending} =
    UserServerState.usePatchUserProfile()
  const {
    data: userProfile,
    isFetching: userProfileIsFetching,
    isLoading: userProfileIsLoading
  } = UserServerState.useGetUserProfile()

  const {
    data: userCredit,
    isFetching: userCreditIsFetching,
    isLoading: userCreditIsLoading
  } = UserServerState.useGetUserCredit()

  //HOOKS
  const handleConnectAccount = (account_type) => {
    if (account_type === 'google') {
      setUser((user) => ({
        ...user,
        thirdPartyProvisionsRequestProvider: 'google',
        thirdPartyProvisionsRequestScope:
          'https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly'
      }))
      initiateGoogleOAuth({googleClientId: clientConfig.googleClientId})
    } else if (account_type === 'microsoft') {
      setUser((user) => ({
        ...user,
        thirdPartyProvisionsRequestProvider: 'microsoft',
        thirdPartyProvisionsRequestScope:
          'https://graph.microsoft.com/Mail.Send https://graph.microsoft.com/Mail.ReadWrite offline_access'
      }))
      initiateMicrosoftOAuth({microsoftClientId: clientConfig.microsoftClientId})
    }
  }

  useEffect(() => {
    setUser((user) => ({
      ...user,
      firstName: userProfile?.first_name,
      lastName: userProfile?.last_name
    }))
  }, [userProfile, setUser])

  return (
    <MainTemplate>
      <Modal
        show={showConnectionModel}
        onHide={() => setShowConnectionModal(false)}
        size='md'
      >
        <Modal.Header style={{alignSelf: 'center'}}>Connect Email Account</Modal.Header>
        <Modal.Body>
          <Stack
            direction='horizontal'
            className='d-flex justify-content-around'
          >
            <GoogleContinueWithIcon
              className='clickable'
              style={{height: '3rem'}}
              onClick={() => {
                handleConnectAccount('google')
              }}
            />
            <MicrosoftSignInWithIcon
              className='clickable'
              style={{height: '2.5rem'}}
              onClick={() => {
                handleConnectAccount('microsoft')
              }}
            />
          </Stack>
        </Modal.Body>
      </Modal>
      <Col
        md={12}
        lg={11}
        xl={10}
        xxl={7}
      >
        <Card>
          <Card.Header>
            <h2 className='d-text__font--heading d-text__placement--center color--p-dark'>
              Account Information
            </h2>
          </Card.Header>
          <Card.Body>
            <ListGroup variant='flush'>
              <ListGroup.Item key={'profile'}>
                <Row style={{margin: '2rem'}}>
                  <Col xs={3}>
                    <h5>Profile</h5>
                  </Col>
                  <Col>
                    <Row style={{marginBottom: '1.5rem'}}>
                      <InputGroup>
                        <FloatingLabel label='Signed in as'>
                          <Form.Control
                            type='text'
                            value={userIdentity?.email || ''}
                            style={{borderRight: 'none'}}
                            readOnly
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          {(userIdentityIsFetching || userIdentityIsLoading) && (
                            <Spinner
                              size='sm'
                              animation='grow'
                            />
                          )}
                          {!userIdentityIsFetching && !userIdentityIsLoading && (
                            <span className='material-symbols-outlined color--accent'>
                              {'person'}
                            </span>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Row>
                    <Row>
                      <InputGroup as={Col}>
                        <FloatingLabel label='First Name'>
                          <Form.Control
                            type='text'
                            value={user?.firstName || ''}
                            onChange={(e) => setUserKey('firstName', e.target.value)}
                            onBlur={() => {
                              if (user?.firstName !== userProfile?.first_name) {
                                patchUserProfile({
                                  payload: {
                                    ...userProfile,
                                    first_name: user?.firstName
                                  }
                                })
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault()
                                e.target.blur()
                              }
                            }}
                            style={{borderRight: 'none'}}
                            readOnly={
                              userProfileIsFetching ||
                              userProfileIsLoading ||
                              patchUserProfileIsPending
                            }
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          {(userProfileIsFetching ||
                            userProfileIsLoading ||
                            patchUserProfileIsPending) && (
                            <Spinner
                              size='sm'
                              animation='grow'
                            />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <InputGroup as={Col}>
                        <FloatingLabel label='Last Name'>
                          <Form.Control
                            type='text'
                            value={user?.lastName || ''}
                            onChange={(e) => setUserKey('lastName', e.target.value)}
                            onBlur={() => {
                              if (user?.lastName !== userProfile?.last_name) {
                                patchUserProfile({
                                  payload: {...userProfile, last_name: user?.lastName}
                                })
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault()
                                e.target.blur()
                              }
                            }}
                            style={{borderRight: 'none'}}
                            readOnly={
                              userProfileIsFetching ||
                              userProfileIsLoading ||
                              patchUserProfileIsPending
                            }
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          {(userProfileIsFetching ||
                            userProfileIsLoading ||
                            patchUserProfileIsPending) && (
                            <Spinner
                              size='sm'
                              animation='grow'
                            />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Row>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item key={'email'}>
                <Row style={{margin: '2rem'}}>
                  <Col xs={3}>
                    <h5>Email</h5>
                  </Col>
                  <Col>
                    <Row>
                      <InputGroup as={Col}>
                        <FloatingLabel label='Connected Email Account'>
                          <Form.Control
                            type='text'
                            value={getConnectedEmail(userTPP)}
                            style={{borderRight: 'none'}}
                            readOnly
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          {userTPPIsFetching && (
                            <Spinner
                              size='sm'
                              animation='grow'
                              style={{marginRight: '0.5em'}}
                            />
                          )}
                          {!userTPPIsFetching && !userTPP?.is_valid && (
                            <OverlayTrigger
                              key='review-overlay'
                              placement='top'
                              overlay={(props) =>
                                renderTooltip(
                                  props,
                                  'Cannot connect to your account. Please connect your account again.'
                                )
                              }
                            >
                              <span
                                className='material-symbols-outlined'
                                style={{
                                  color: 'red',
                                  marginRight: '0.5rem',
                                  fontVariationSettings:
                                    '"FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24'
                                }}
                              >
                                {'sync_problem'}
                              </span>
                            </OverlayTrigger>
                          )}
                          {!userTPPIsFetching && userTPP?.provider === 'google' && (
                            <img
                              src={GmailIcon}
                              alt='Gmail Icon'
                              style={{maxWidth: '1.5rem', maxHeight: '1.5rem'}}
                            />
                          )}
                          {!userTPPIsFetching && userTPP?.provider === 'microsoft' && (
                            <OutlookIcon
                              style={{maxWidth: '1.75rem', maxHeight: '1.75rem'}}
                            />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <Col
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Button
                          children={
                            <>
                              {'Connect Account'}
                              <span className='material-symbols-outlined'>
                                {'link'}
                              </span>
                            </>
                          }
                          onClick={() => setShowConnectionModal(true)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item key={'credits'}>
                <Row style={{margin: '2rem'}}>
                  <Col xs={3}>
                    <h5>Credits</h5>
                  </Col>
                  <Col>
                    <Row style={{marginBottom: '1.5rem'}}>
                      <InputGroup as={Col}>
                        <FloatingLabel label='Remaining credits'>
                          <Form.Control
                            type='text'
                            value={userCredit?.available_credit}
                            style={{borderRight: 'none'}}
                            readOnly
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          {(userCreditIsFetching || userCreditIsLoading) && (
                            <Spinner
                              size='sm'
                              animation='grow'
                            />
                          )}
                          {!userCreditIsFetching && !userCreditIsLoading && (
                            <span className='material-symbols-outlined color--accent'>
                              {'paid'}
                            </span>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Row>
                    <Row>
                      <InputGroup as={Col}>
                        <FloatingLabel label='Current Plan'>
                          <Form.Control
                            type='text'
                            value={getPlanLabel(userCredit)}
                            style={{borderRight: 'none'}}
                            readOnly
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          {(userCreditIsFetching || userCreditIsLoading) && (
                            <Spinner
                              size='sm'
                              animation='grow'
                            />
                          )}
                          {!userCreditIsFetching && !userCreditIsLoading && (
                            <span className='material-symbols-outlined color--accent'>
                              {'bolt'}
                            </span>
                          )}
                        </InputGroup.Text>
                      </InputGroup>

                      <InputGroup as={Col}>
                        <FloatingLabel label='Next Refill'>
                          <Form.Control
                            type='text'
                            value={formatDate(userCredit?.refill_date, false)}
                            style={{borderRight: 'none'}}
                            readOnly
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          {(userCreditIsFetching || userCreditIsLoading) && (
                            <Spinner
                              size='sm'
                              animation='grow'
                            />
                          )}
                          {!userCreditIsFetching && !userCreditIsLoading && (
                            <span className='material-symbols-outlined color--accent'>
                              {'update'}
                            </span>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <InputGroup as={Col}>
                        <FloatingLabel label='Refill Amount'>
                          <Form.Control
                            type='text'
                            value={userCredit?.refill_amount}
                            style={{borderRight: 'none'}}
                            readOnly
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          {(userCreditIsFetching || userCreditIsLoading) && (
                            <Spinner
                              size='sm'
                              animation='grow'
                            />
                          )}
                          {!userCreditIsFetching && !userCreditIsLoading && (
                            <span className='material-symbols-outlined color--accent'>
                              {'keyboard_double_arrow_up'}
                            </span>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Row>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
    </MainTemplate>
  )
}
