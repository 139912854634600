import {useEffect, useState} from 'react'
import {Col} from 'react-bootstrap'

import * as AgentServerState from '../../hooks/server/useAgentServerState.js'
import * as UserServerState from '../../hooks/server/useUserServerState.js'
import * as AudiencesServerState from '../../hooks/server/useAudiencesServerState.js'
import {usePostCampaignEmailsSend} from '../../hooks/server/useCampaignsServerState.js'

import {
  useInputMessagesAtom,
  useGeneratedMessageAtom,
  useMessageInstructionsAtom,
  useEnrichmentPersonUrlAtom,
  useEnrichmentCompanyUrlAtom
} from '../../hooks/client/useAgentClientState.js'
import {useToast} from '../../hooks/client/useToastsClientState.js'

import {MainTemplate} from '../templates/MainTemplate.js'

import {MessageInstructionsInput} from '../organisms/MessageInstructionsInput.js'
import {MessageInstructionsOutput} from '../organisms/MessageInstructionsOutput.js'

import {UserCreditWarningModal} from '../molecules/UserCreditWarningModal.js'
import {UserProfileWarningInfoModal} from '../molecules/UserProfileWarningInfoModal.js'

import {useDebounce, validateField, isValidUrl} from '../../utils/hooks.js'

export const MessageBuilder = () => {
  //CLIENT GLOBAL STATES
  const {addToast} = useToast()
  const {
    messageInstructionsAtom,
    setMessageInstructionsAtom,
    setMessageInstructionsKey
  } = useMessageInstructionsAtom()
  const [generatedMessage, setGeneratedMessage] = useGeneratedMessageAtom()
  const [messageList, setMessageList] = useInputMessagesAtom()

  const [enrichmentPersonUrl, setEnrichmentPersonUrl] = useEnrichmentPersonUrlAtom()
  const [enrichmentCompanyUrl, setEnrichmentCompanyUrl] = useEnrichmentCompanyUrlAtom()

  //CLIENT LOCAL STATES
  const [showProfileInfoModal, setShowProfileInfoModal] = useState(false)
  const [showCreditInfoModal, setShowCreditInfoModal] = useState(false)
  const [debouncedEnrichmentPersonUrl, enrichmentPersonUrlIsDebouncing] = useDebounce(
    enrichmentPersonUrl,
    1000
  )
  const [debouncedEnrichmentCompanyUrl, enrichmentCompanyUrlIsDebouncing] = useDebounce(
    enrichmentCompanyUrl,
    1000
  )
  const modelToEnforce = 'gpt-4o-mini-2024-07-18'

  //SERVER STATES
  const {data: messages} = AgentServerState.useGetMessages()
  const {data: userTPP} = UserServerState.useGetUserThirdPartyProvisions()
  const {mutate: postCampaignEmailsSend, isPending: postCampaignEmailsSendIsPending} =
    usePostCampaignEmailsSend()
  const {
    data: messageInstructions,
    isLoading: messageInstructionsIsLoading,
    isFetching: messageInstructionsIsFetching
  } = AgentServerState.useGetMessageInstructions()
  const {mutate: postMessageInstructions, isPending: postMessageInstructionsIsPending} =
    AgentServerState.usePostMessageInstructions()
  const {
    mutate: patchMessageInstructions,
    isPending: patchMessageInstructionsIsPending
  } = AgentServerState.usePatchMessageInstructions()
  const {mutate: postMessage, isPending: postMessageIsPending} =
    AgentServerState.usePostMessage()
  const {mutate: postMessageGenerate, isPending: postMessageGenerateIsPending} =
    AgentServerState.usePostMessageGenerate()
  const {
    data: userProfile,
    isFetching: userProfileIsFetching,
    isLoading: userProfileIsLoading
  } = UserServerState.useGetUserProfile()
  const {
    data: supportedLanguages,
    isLoading: supportedLanguagesIsLoading,
    isFetching: supportedLanguagesIsFetching
  } = AgentServerState.useGetSupportedLanguages()
  const {data: userCredits, isSuccess: userCreditsIsSuccess} =
    UserServerState.useGetUserCredit()
  const {
    data: enrichmentPerson,
    status: enrichmentPersonQueryStatus,
    fetchStatus: enrichmentPersonFetchStatus
  } = AudiencesServerState.useGetEnrichmentPerson({
    enabled: isValidUrl(debouncedEnrichmentPersonUrl),
    params: {url: debouncedEnrichmentPersonUrl}
  })
  const {
    data: enrichmentCompany,
    status: enrichmentCompanyQueryStatus,
    fetchStatus: enrichmentCompanyFetchStatus
  } = AudiencesServerState.useGetEnrichmentCompany({
    enabled: isValidUrl(debouncedEnrichmentCompanyUrl),
    params: {url: debouncedEnrichmentCompanyUrl}
  })

  //DERIVED LOCAL STATES
  const firstNameError = validateField(userProfile?.first_name, 'First Name')
  const companyNameError = validateField(
    messageInstructionsAtom?.companyName,
    'Company Name'
  )
  const intentionError = validateField(messageInstructionsAtom?.intention, 'Intention')
  const lastNameError = validateField(userProfile?.last_name, 'Last Name')
  const preferencesErrors = messageInstructionsAtom?.preferences?.some((p) =>
    validateField(p, '')
  )
  const titleError = validateField(messageInstructionsAtom?.title, 'Title')
  const valuePropositionError = validateField(
    messageInstructionsAtom?.valueProposition,
    'Value Proposition'
  )
  const personInformationInputIsCorrect =
    !firstNameError && !lastNameError && !titleError
  const companyInformationInputIsCorrect = !companyNameError && !valuePropositionError
  const messageInformationInputIsCorrect = !intentionError && !preferencesErrors
  const allInputInformationIsCorrect =
    personInformationInputIsCorrect &&
    companyInformationInputIsCorrect &&
    messageInformationInputIsCorrect

  //HANDLERS
  const handlePostEmailSend = (recipientEmail) => {
    postCampaignEmailsSend({
      params: {
        access_token: userTPP.access_token,
        provider: userTPP.provider
      },
      payload: {
        email_body: generatedMessage?.body,
        email_subject: generatedMessage?.header,
        recipient_email: recipientEmail
      }
    })
  }

  const handleLoadMessageInstructions = () => {
    const _messageInstructions = messageInstructions.find(
      (t) =>
        t?.message_instructions_id === messageInstructionsAtom?.messageInstructionsId
    )
    setMessageInstructionsAtom({
      messageInstructionsId: _messageInstructions.message_instructions_id,
      label: _messageInstructions.label,
      language: _messageInstructions.language,
      title: _messageInstructions.title,
      companyName: _messageInstructions.company_name,
      valueProposition: _messageInstructions.value_proposition,
      intention: _messageInstructions.intention,
      preferences: _messageInstructions.message_preferences,
      outreachMethod: _messageInstructions.outreach_method,
      outreachStage: _messageInstructions.outreach_stage,
      messageSignature: _messageInstructions.message_signature
    })
    addToast({
      header: `Message instructions "${_messageInstructions.label}" Loaded`,
      body: 'Your message instructions has been successfully loaded!'
    })
  }

  const handlePostMessageInstructions = () => {
    postMessageInstructions(
      {
        payload: {
          company_name: messageInstructionsAtom.companyName,
          first_name: userProfile.first_name,
          intention: messageInstructionsAtom.intention,
          label: messageInstructionsAtom.label,
          language: messageInstructionsAtom.language,
          last_name: userProfile.last_name,
          outreach_method: messageInstructionsAtom.outreachMethod,
          outreach_stage: messageInstructionsAtom.outreachStage,
          title: messageInstructionsAtom.title,
          message_preferences: messageInstructionsAtom.preferences,
          value_proposition: messageInstructionsAtom.valueProposition,
          temperature: 0,
          model_name: modelToEnforce,
          message_signature: messageInstructionsAtom.messageSignature
        }
      },
      {
        onSuccess: () => {
          setMessageInstructionsKey('label', null)
        }
      }
    )
  }

  const handlePatchMessageInstructions = () => {
    patchMessageInstructions({
      params: {
        message_instructions_id: messageInstructionsAtom.messageInstructionsId
      },
      payload: {
        company_name: messageInstructionsAtom.companyName,
        first_name: userProfile.first_name,
        intention: messageInstructionsAtom.intention,
        label: messageInstructionsAtom.label,
        language: messageInstructionsAtom.language,
        last_name: userProfile.last_name,
        outreach_method: messageInstructionsAtom.outreachMethod,
        outreach_stage: messageInstructionsAtom.outreachStage,
        title: messageInstructionsAtom.title,
        message_preferences: messageInstructionsAtom.preferences,
        value_proposition: messageInstructionsAtom.valueProposition,
        temperature: 0,
        model_name: modelToEnforce,
        message_signature: messageInstructionsAtom.messageSignature
      }
    })
  }

  const handlePostMessageGenerate = () => {
    let payload = {
      language: messageInstructionsAtom.language,
      outreach_method: messageInstructionsAtom.outreachMethod,
      outreach_stage: messageInstructionsAtom.outreachStage,
      sender_employer_name: messageInstructionsAtom.companyName,
      sender_first_name: userProfile.first_name,
      sender_intention: messageInstructionsAtom.intention,
      sender_last_name: userProfile.last_name,
      sender_preferences: messageInstructionsAtom.preferences,
      sender_title: messageInstructionsAtom.title,
      sender_value_proposition: messageInstructionsAtom.valueProposition,
      recipient_summary: enrichmentPerson.cleaned_summary,
      recipient_employer_summary: enrichmentPerson.cleaned_summary,
      message_signature: messageInstructionsAtom.messageSignature,
      temperature: 0,
      model_name: modelToEnforce
    }
    if (
      messageInstructionsAtom.outreachStage === 'follow_up_message' &&
      messageList?.length > 0
    ) {
      const baseDate = new Date()
      baseDate.setHours(baseDate.getHours() - messageList.length)

      payload.message_thread = messageList.map((m, index) => ({
        sender: userProfile.first_name,
        recipient: enrichmentPerson?.first_name,
        date: new Date(baseDate.getTime() + index * 3600000).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }),
        body: m?.message_body_raw || m?.message_body
      }))
    }

    postMessageGenerate(
      {
        payload: payload
      },
      {
        onSuccess: (response) => {
          setGeneratedMessage({
            header: response.message_header,
            body: response.message_body,
            bodyRaw: response.message_body_raw
          })
        }
      }
    )
  }

  const handlePostMessage = (label) => {
    postMessage({
      payload: {
        language: messageInstructionsAtom.language,
        temperature: 0,
        model_name: modelToEnforce,
        outreach_method: messageInstructionsAtom.outreachMethod,
        outreach_stage: messageInstructionsAtom.outreachStage,
        sender_employer_name: messageInstructionsAtom.companyName,
        sender_first_name: userProfile.first_name,
        sender_intention: messageInstructionsAtom.intention,
        sender_last_name: userProfile.last_name,
        sender_preferences: messageInstructionsAtom.preferences,
        sender_title: messageInstructionsAtom.title,
        sender_value_proposition: messageInstructionsAtom.valueProposition,
        recipient_summary: enrichmentPerson.cleaned_summary,
        recipient_employer_summary: enrichmentPerson.cleaned_summary,
        message_body_raw: generatedMessage.bodyRaw,
        message_body: generatedMessage.body,
        message_header: generatedMessage.header,
        message_signature: messageInstructionsAtom.messageSignature,
        label: label
      }
    })
  }

  //Listeners
  useEffect(() => {
    if (!!enrichmentPerson?.employer_url) {
      setEnrichmentCompanyUrl(enrichmentPerson?.employer_url)
    }
  }, [enrichmentPerson, setEnrichmentCompanyUrl])

  useEffect(() => {
    if (
      !userProfileIsFetching &&
      !userProfileIsLoading &&
      (userProfile?.first_name === '' || userProfile?.last_name === '')
    ) {
      setShowProfileInfoModal(true)
    }
  }, [userProfile, userProfileIsFetching, userProfileIsLoading])

  useEffect(() => {
    if (userCreditsIsSuccess && userCredits?.available_credit === 0) {
      setShowCreditInfoModal(true)
    }
  }, [userCredits, userCreditsIsSuccess])

  return (
    <>
      <UserCreditWarningModal
        show={showCreditInfoModal}
        onHide={() => setShowCreditInfoModal(false)}
      />
      <UserProfileWarningInfoModal
        show={showProfileInfoModal}
        onHide={() => setShowProfileInfoModal(false)}
      />
      <MainTemplate>
        <Col xs={6}>
          <MessageInstructionsInput
            allInputInformationIsCorrect={allInputInformationIsCorrect}
            companyInformationInputIsCorrect={companyInformationInputIsCorrect}
            messageSignatureOnChange={(e) =>
              setMessageInstructionsKey('messageSignature', e?.target?.value)
            }
            messageSignatureValue={messageInstructionsAtom?.messageSignature}
            companyNameError={companyNameError}
            companyNameOnChange={(e) =>
              setMessageInstructionsKey('companyName', e?.target?.value)
            }
            companyNameValue={messageInstructionsAtom?.companyName}
            firstName={userProfile?.first_name}
            firstNameError={firstNameError}
            firstNameIsFetching={userProfileIsFetching}
            idValue={messageInstructionsAtom?.messageInstructionsId}
            inputValidator={validateField}
            intentionError={intentionError}
            intentionOnChange={(e) =>
              setMessageInstructionsKey('intention', e?.target?.value)
            }
            intentionValue={messageInstructionsAtom?.intention}
            labelOnChange={(e) => {
              setMessageInstructionsKey('label', e?.label)
              setMessageInstructionsKey('messageInstructionsId', e?.value)
            }}
            labelValue={messageInstructionsAtom?.label}
            languageOnChange={(e) =>
              setMessageInstructionsKey('language', e?.target?.value)
            }
            languageValue={messageInstructionsAtom?.language}
            lastName={userProfile?.last_name}
            lastNameError={lastNameError}
            lastNameIsFetching={userProfileIsFetching}
            loadMessageInstructions={handleLoadMessageInstructions}
            messageInformationInputIsCorrect={messageInformationInputIsCorrect}
            outreachMethodOnChange={(e) =>
              setMessageInstructionsKey('outreachMethod', e.target.value)
            }
            outreachMethodValue={messageInstructionsAtom?.outreachMethod}
            outreachStageOnChange={(e) =>
              setMessageInstructionsKey('outreachStage', e.target.value)
            }
            outreachStageValue={messageInstructionsAtom?.outreachStage}
            patchMessageInstructions={handlePatchMessageInstructions}
            patchMessageInstructionsIsPending={patchMessageInstructionsIsPending}
            personInformationInputIsCorrect={personInformationInputIsCorrect}
            postMessageGenerate={handlePostMessageGenerate}
            postMessageGenerateIsPending={postMessageGenerateIsPending}
            postMessageInstructions={handlePostMessageInstructions}
            postMessageInstructionsIsPending={postMessageInstructionsIsPending}
            preferencesValues={messageInstructionsAtom?.preferences}
            preferencesValuesOnChange={(newPreferences) =>
              setMessageInstructionsKey('preferences', newPreferences)
            }
            supportedLanguages={supportedLanguages}
            supportedLanguagesIsFetching={
              supportedLanguagesIsFetching || supportedLanguagesIsLoading
            }
            messageInstructions={messageInstructions}
            messageInstructionsIsFetching={
              messageInstructionsIsFetching || messageInstructionsIsLoading
            }
            titleError={titleError}
            titleOnChange={(e) => setMessageInstructionsKey('title', e?.target?.value)}
            titleValue={messageInstructionsAtom?.title}
            valuePropositionError={valuePropositionError}
            valuePropositionOnChange={(e) =>
              setMessageInstructionsKey('valueProposition', e?.target?.value)
            }
            valuePropositionValue={messageInstructionsAtom?.valueProposition}
          />
        </Col>
        <Col>
          <MessageInstructionsOutput
            allInputInformationIsCorrect={allInputInformationIsCorrect}
            enrichmentCompany={enrichmentCompany}
            enrichmentCompanyFetchStatus={enrichmentCompanyFetchStatus}
            enrichmentCompanyQueryStatus={enrichmentCompanyQueryStatus}
            enrichmentCompanyUrl={enrichmentCompanyUrl}
            enrichmentCompanyUrlIsDebouncing={enrichmentCompanyUrlIsDebouncing}
            enrichmentCompanyUrlOnChange={(e) =>
              setEnrichmentCompanyUrl(e.target.value)
            }
            enrichmentPerson={enrichmentPerson}
            enrichmentPersonFetchStatus={enrichmentPersonFetchStatus}
            enrichmentPersonQueryStatus={enrichmentPersonQueryStatus}
            enrichmentPersonUrl={enrichmentPersonUrl}
            enrichmentPersonUrlIsDebouncing={enrichmentPersonUrlIsDebouncing}
            enrichmentPersonUrlOnChange={(e) => setEnrichmentPersonUrl(e.target.value)}
            handlePostMessage={handlePostMessage}
            handlePostMessageGenerate={handlePostMessageGenerate}
            handlePostEmailSend={handlePostEmailSend}
            outreachMethod={messageInstructionsAtom?.outreachMethod}
            outreachStage={messageInstructionsAtom?.outreachStage}
            generatedMessage={generatedMessage}
            postMessageGenerateIsPending={postMessageGenerateIsPending}
            postMessageIsPending={postMessageIsPending}
            postCampaignEmailsSendIsPending={postCampaignEmailsSendIsPending}
            userFirstName={userProfile?.first_name}
            messages={messages}
            messageList={messageList}
            setMessageList={setMessageList}
          />
        </Col>
      </MainTemplate>
    </>
  )
}
