import {Navbar, Nav, Button, Col, Dropdown, Stack, Row} from 'react-bootstrap'
import {NavLink, useNavigate} from 'react-router-dom'

import {useSignOut, useGetUserCredit} from '../../hooks/server/useUserServerState.js'

import {ReactComponent as PitchrIcon} from '../atoms/assets/PitchrLogo.svg'
import {Spinner} from '../atoms/Spinner.js'

export const NavigationBar = () => {
  const {mutate: signOut} = useSignOut()
  const {
    data: userCredit,
    isFetching: userCreditIsFetching,
    isLoading: userCreditIsLoading
  } = useGetUserCredit()
  const navigate = useNavigate()

  return (
    <Navbar
      fixed='top'
      className='d-navbar'
      style={{padding: '0.25rem'}}
    >
      <Col
        xs={3}
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          paddingLeft: '2rem'
        }}
      >
        <PitchrIcon style={{height: '2rem', width: 'auto'}} />
      </Col>
      <Col
        xs={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Nav>
          <Row className='d-flex justify-content-center'>
            <Col
              xs={3}
              className='d-flex justify-content-center'
            >
              <Nav.Link
                as={NavLink}
                to='/audience-builder'
                children='Audience'
                className='color--p-light transition-medium t-color--accent'
              />
            </Col>
            <Col xs={1}>
              <hr
                className='vr'
                style={{
                  margin: 0,
                  padding: 0,
                  height: '100%',
                  color: 'var(--secondary-light)'
                }}
              />
            </Col>
            <Col
              xs='auto'
              className='d-flex justify-content-center'
            >
              <Nav.Link
                as={NavLink}
                to='/message-builder'
                children='Message'
                className='color--p-light transition-medium t-color--accent'
              />
            </Col>
            <Col xs={1}>
              <hr
                className='vr'
                style={{
                  margin: 0,
                  padding: 0,
                  height: '100%',
                  color: 'var(--secondary-light)'
                }}
              />
            </Col>
            <Col
              xs={3}
              className='d-flex justify-content-center'
            >
              <Nav.Link
                as={NavLink}
                to='/campaign-builder'
                children='Campaign'
                className='color--p-light transition-medium t-color--accent'
              />
            </Col>
          </Row>
        </Nav>
      </Col>
      <Col
        xs={3}
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          paddingRight: '2rem'
        }}
      >
        <Dropdown drop='start'>
          <Dropdown.Toggle
            variant='none'
            bsPrefix='dropdownToggle'
            style={{border: 'none'}}
            className='d-flex align-items-center justify-content-center'
            children={
              <span
                className={
                  'material-symbols-outlined color--p-light transition-fast clickable t-color--accent'
                }
                style={{fontSize: '2rem'}}
              >
                {'account_circle'}
              </span>
            }
          />
          <Dropdown.Menu>
            <Dropdown.Item
              style={{display: 'flex', justifyContent: 'center'}}
              children={
                <Button
                  variant='outline-primary'
                  className='transition-fast t-background--p-light color--accent'
                  style={{borderRadius: '1rem', borderColor: 'var(--accent)'}}
                  onClick={() => navigate('/profile')}
                >
                  <Stack
                    direction='horizontal'
                    gap={2}
                  >
                    {userCreditIsFetching || userCreditIsLoading ? (
                      <>
                        <small>Credits: </small>
                        <Spinner
                          size='sm'
                          animation='grow'
                        />
                      </>
                    ) : (
                      <small>{`Credits: ${userCredit?.available_credit}`}</small>
                    )}
                    <span className='material-symbols-outlined color--accent'>
                      {'paid'}
                    </span>
                  </Stack>
                </Button>
              }
            />
            <Dropdown.Divider />
            <Dropdown.Item
              as={NavLink}
              to='/profile'
              children='Profile'
            />
            <Dropdown.Item
              as={NavLink}
              to='/dashboard'
              children={['Dashboard']}
            />
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={signOut}
              children='Sign out'
            />
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Navbar>
  )
}
