import {ProgressBar, OverlayTrigger, Tooltip} from 'react-bootstrap'

const renderTooltip = (props, text) => (
  <Tooltip
    id='button-tooltip'
    {...props}
  >
    {text}
  </Tooltip>
)

export const AudienceProgressBar = ({skipped, sent, audienceSize}) => {
  return (
    <ProgressBar>
      <OverlayTrigger
        key='segment-1'
        placement='top'
        overlay={(props) => renderTooltip(props, `Skipped: ${skipped}`)}
      >
        <ProgressBar
          animated
          key={1}
          isChild={true}
          now={skipped}
          max={audienceSize}
          style={{backgroundColor: 'orange'}}
        />
      </OverlayTrigger>
      <OverlayTrigger
        key='segment-2'
        placement='top'
        overlay={(props) => renderTooltip(props, `Sent: ${sent}`)}
      >
        <ProgressBar
          animated
          key={2}
          isChild={true}
          now={sent}
          max={audienceSize}
          style={{backgroundColor: 'green'}}
        />
      </OverlayTrigger>
      <OverlayTrigger
        key='segment-3'
        placement='top'
        overlay={(props) =>
          renderTooltip(props, `Remaining: ${audienceSize - sent - skipped}`)
        }
      >
        <ProgressBar
          key={3}
          isChild={true}
          now={audienceSize - sent - skipped}
          max={audienceSize}
          style={{backgroundColor: 'lightgray'}}
        />
      </OverlayTrigger>
    </ProgressBar>
  )
}
