import {useEffect, useState} from 'react'
import {Col, Container, Collapse} from 'react-bootstrap'

import {MainTemplate} from '../templates/MainTemplate.js'
import {
  CadenceDetails,
  AudienceDetails,
  CampaignDetails,
  SettingsDetails,
  SavingDetails
} from '../organisms/CampaignBuilderInput.js'
import {UserCreditWarningModal} from '../molecules/UserCreditWarningModal.js'

import {useCampaignBuilderAtom} from '../../hooks/client/useCampaignsClientState.js'

import * as AudienceSS from '../../hooks/server/useAudiencesServerState.js'
import * as AgentSS from '../../hooks/server/useAgentServerState.js'
import {
  usePostCampaign,
  useGetCampaigns
} from '../../hooks/server/useCampaignsServerState.js'
import {useGetUserCredit} from '../../hooks/server/useUserServerState.js'
import {useQueryClient} from '@tanstack/react-query'

import {useDebounce, isTruthy} from '../../utils/hooks.js'
import {
  checkMaxPeopleOutreach,
  checkMaxPeopleOutreachPerDay,
  checkMaxPeopleOutreachPerCompany,
  calculatePeoplePerCompany,
  calculateLogDiscrepancyRatio,
  calculatePeopleToReach,
  calculateCompaniesToReach,
  checkCompaniesReach,
  checkPeopleReach,
  checkAudiencePeople,
  checkAudienceCompanies,
  calculateMaxPeopleOutreach
} from '../../utils/calculations.js'

export const CampaignBuilder = () => {
  //GLOBAL CLIENT STATES
  const {campaignClientState, setCampaignClientStateKey} = useCampaignBuilderAtom()
  const {
    campaignSortingInstructions,
    maxOutreachMessages,
    maxOutreachMessagesPerDay,
    maxOutreachPeoplePerCompany
  } = campaignClientState

  //LOCAL CLIENT STATES
  const [sortingInstructionsDebounce, sortingInstructionsIsDebouncing] = useDebounce(
    campaignSortingInstructions,
    3000
  )

  const [selectedAudience, setSelectedAudience] = useState({})
  const peopleInAudience = selectedAudience?.people_in_audience
  const companiesInAudience = selectedAudience?.companies_in_audience
  const [peopleSearchPayload, setPeopleSearchPayload] = useState({})
  const [peopleToRank, setPeopleToRank] = useState([])
  const [rankedPeopleSearchResults, setRankedPeopleSearchResults] = useState([])
  const [showCreditInfoModal, setShowCreditInfoModal] = useState(false)

  const audienceAlreadyInUse =
    isTruthy(selectedAudience) && isTruthy(selectedAudience?.used_in_campaign)
  const audienceCompaniesError = checkAudienceCompanies({companiesInAudience})
  const audiencePeopleError = checkAudiencePeople({
    companiesInAudience,
    peopleInAudience
  })
  const audienceIsValid =
    isTruthy(selectedAudience) &&
    !audienceAlreadyInUse &&
    !isTruthy(audienceCompaniesError) &&
    !isTruthy(audiencePeopleError)
  const audienceDetailsAreValid =
    isTruthy(campaignSortingInstructions) && audienceIsValid

  const stepsAreValid =
    isTruthy(campaignClientState?.steps) &&
    campaignClientState?.steps?.every(
      (step) => isTruthy(step?.message_instructions_id) && isTruthy(step?.index)
    )

  const maxPeopleOutreach = calculateMaxPeopleOutreach({
    maxOutreachMessages,
    maxOutreachPerPerson: campaignClientState?.steps?.length
  })
  const peoplePerCompany = calculatePeoplePerCompany({
    peopleInAudience,
    companiesInAudience
  })
  const companiesToReach = calculateCompaniesToReach({
    maxOutreachPeoplePerCompany,
    maxPeopleOutreach,
    peoplePerCompany,
    companiesInAudience
  })
  const peopleToReach = calculatePeopleToReach({
    companiesToReach,
    maxOutreachPeoplePerCompany,
    maxPeopleOutreach,
    peopleInAudience
  })
  const logDiscrepancyRatio = calculateLogDiscrepancyRatio({
    maxOutreachPeoplePerCompany,
    peoplePerCompany
  })
  const maxPeopleOutreachError = checkMaxPeopleOutreach({
    maxPeopleOutreach,
    maxOutreachMessagesPerDay,
    maxOutreachPeoplePerCompany
  })
  const maxPeopleOutreachPerDayError = checkMaxPeopleOutreachPerDay({
    maxOutreachMessagesPerDay
  })
  const maxPeopleOutreachPerCompanyError = checkMaxPeopleOutreachPerCompany({
    maxOutreachPeoplePerCompany
  })

  const companiesToReachError = checkCompaniesReach({
    companiesToReach,
    companiesInAudience
  })
  const peopleToReachError = checkPeopleReach({
    peopleToReach,
    peopleInAudience,
    logDiscrepancyRatio
  })
  const outreachInputIsValid =
    isTruthy(maxOutreachMessages) &&
    isTruthy(maxOutreachMessagesPerDay) &&
    isTruthy(maxOutreachPeoplePerCompany) &&
    !isTruthy(maxPeopleOutreachError) &&
    !isTruthy(maxPeopleOutreachPerDayError) &&
    !isTruthy(maxPeopleOutreachPerCompanyError)

  const outreachIsValid =
    outreachInputIsValid &&
    !isTruthy(companiesToReachError) &&
    !isTruthy(peopleToReachError)

  //SERVER STATES
  const queryClient = useQueryClient()

  const {mutateAsync: postCampaign, isPending: postCampaignIsPending} =
    usePostCampaign()
  const {mutateAsync: patchAudience, isPending: patchAudienceIsPending} =
    AudienceSS.usePatchAudience({showToast: false})
  const {
    mutateAsync: patchMessageInstructions,
    isPending: patchMessageInstructionsIsPending
  } = AgentSS.usePatchMessageInstructions({
    showToast: false,
    invalidateQuery: false
  })

  const {data: campaigns} = useGetCampaigns({params: {per_page: 10000, page: 0}})
  const {
    data: userCredit,
    isFetching: userCreditIsFetching,
    isLoading: userCreditIsLoading
  } = useGetUserCredit()
  const {
    data: messageInstructions,
    isLoading: messageInstructionsIsLoading,
    isFetching: messageInstructionsIsFetching
  } = AgentSS.useGetMessageInstructions()
  const {
    data: audiences,
    isLoading: audiencesIsLoading,
    isFetching: audiencesIsFetching
  } = AudienceSS.useGetAudiences()
  const {
    data: companiesSearchResults,
    isFetching: companiesSearchResultsIsFetching,
    isLoading: companiesSearchResultsIsLoading
  } = AudienceSS.usePostSearchCompanies({
    enabled: isTruthy(selectedAudience) && audienceIsValid,
    payload: selectedAudience?.company_search_filters,
    params: {page: 0, size: 40}
  })
  const {
    data: peopleSearchResults,
    isFetching: peopleSearchResultsIsFetching,
    isLoading: peopleSearchResultsIsLoading
  } = AudienceSS.usePostSearchPeople({
    enabled:
      isTruthy(selectedAudience) &&
      isTruthy(peopleSearchPayload) &&
      !companiesSearchResultsIsFetching &&
      !companiesSearchResultsIsLoading &&
      audienceIsValid,
    payload: peopleSearchPayload
  })
  const {
    data: leadsRankResults,
    isFetching: leadsRankResultsFetching,
    isLoading: leadsRankResultsLoading
  } = AgentSS.usePostLeadsRank({
    payload: {
      role_titles: peopleToRank,
      instruction: sortingInstructionsDebounce
    },
    enabled:
      isTruthy(selectedAudience) &&
      isTruthy(peopleSearchPayload) &&
      isTruthy(peopleToRank) &&
      isTruthy(sortingInstructionsDebounce) &&
      !companiesSearchResultsIsFetching &&
      !companiesSearchResultsIsLoading &&
      !peopleSearchResultsIsFetching &&
      !peopleSearchResultsIsLoading &&
      audienceDetailsAreValid
  })

  //Listeners
  useEffect(() => {
    if (
      !userCreditIsLoading &&
      !userCreditIsFetching &&
      userCredit?.available_credit === 0
    ) {
      setShowCreditInfoModal(true)
    }
  }, [userCredit, userCreditIsLoading, userCreditIsFetching])

  useEffect(() => {
    let foundAudience = {}
    if (isTruthy(audiences) && isTruthy(campaignClientState?.audienceId)) {
      foundAudience = audiences?.find(
        (a) => a.audience_id === campaignClientState?.audienceId
      )
    }
    setSelectedAudience(foundAudience)
  }, [audiences, campaignClientState])

  useEffect(() => {
    let payload = {}
    if (isTruthy(companiesSearchResults)) {
      const linkedinIds =
        companiesSearchResults?.pages?.[0]?.companies
          ?.map((company) => company?.socialNetworks?.linkedinIdAlpha)
          ?.filter((id) => id) || []
      payload = {
        ...selectedAudience?.person_search_filters,
        organization_linkedin_public_identifiers: linkedinIds
      }
    }
    setPeopleSearchPayload(payload)
  }, [companiesSearchResults])

  useEffect(() => {
    let newPeopleToRank = []
    if (isTruthy(peopleSearchResults?.pages?.[0]?.search_results)) {
      newPeopleToRank = peopleSearchResults.pages[0].search_results.map(
        (result, index) => ({
          id: index,
          role_title: result?.role_title || result?.person?.headline
        })
      )
    }
    setPeopleToRank(newPeopleToRank)
  }, [peopleSearchResults])

  useEffect(() => {
    let updatedSearchResults = []
    if (isTruthy(leadsRankResults) && isTruthy(peopleSearchResults)) {
      updatedSearchResults = {...peopleSearchResults}
      updatedSearchResults.pages = updatedSearchResults.pages.map((page) => {
        return {
          ...page,
          search_results: page.search_results
            .map((person) => {
              const rankedPerson = leadsRankResults.role_titles.find((ranked) => {
                return (
                  person.role_title === ranked.role_title ||
                  person.person?.headline === ranked.role_title
                )
              })

              return rankedPerson ? {...person, score: rankedPerson.score} : person
            })
            .sort((a, b) => b.score - a.score)
        }
      })
    }
    setRankedPeopleSearchResults(updatedSearchResults)
  }, [leadsRankResults, peopleSearchResults])

  //Handlers
  const handlePostCampaign = async ({status = 'paused'}) => {
    const campaignPayload = {
      audience_id: campaignClientState.audienceId,
      steps: campaignClientState.steps,
      campaign_status: status,
      campaign_name: campaignClientState.campaignName,
      campaign_description: campaignClientState.campaignDescription,
      max_people_outreach: campaignClientState.maxOutreachMessages,
      max_people_outreach_per_company: campaignClientState.maxOutreachPeoplePerCompany,
      max_people_outreach_per_day: campaignClientState.maxOutreachMessagesPerDay,
      campaign_sorting_instructions: campaignClientState.campaignSortingInstructions,
      companies_in_audience: companiesInAudience,
      people_in_audience: peopleInAudience
    }

    const audienceParams = {audience_id: campaignClientState.audienceId}
    const miInUse = new Set()
    campaigns.items.forEach((c) => {
      c.steps.forEach((step) => {
        miInUse.add(step.message_instructions_id)
      })
    })
    let agentParams = []
    campaignClientState.steps.forEach((step) => {
      if (!miInUse.has(step.message_instructions_id)) {
        agentParams.push({message_instructions_id: step.message_instructions_id})
      }
    })

    try {
      await postCampaign({payload: campaignPayload})
      const tasks = [
        patchAudience({params: audienceParams, payload: {used_in_campaign: true}})
      ].concat(
        agentParams.map((params) =>
          patchMessageInstructions({params, payload: {used_in_campaign: true}})
        )
      )
      await Promise.all(tasks)
      queryClient.invalidateQueries({queryKey: ['messageInstructions']})
    } catch (e) {
      console.error('Error during campaign addition:', e)
    }
  }

  return (
    <>
      <UserCreditWarningModal
        show={showCreditInfoModal}
        onHide={() => setShowCreditInfoModal(false)}
      />
      <MainTemplate>
        <Col
          xs={12}
          xl={11}
          xxl={9}
          className='d-flex flex-column justify-content-center align-items-center'
          style={{padding: '2rem'}}
        >
          <h2>Campaign</h2>

          <Collapse in={true}>
            <div style={{width: '100%'}}>
              <CampaignDetails
                campaignClientState={campaignClientState}
                setCampaignClientStateKey={setCampaignClientStateKey}
              />
            </div>
          </Collapse>

          <Collapse
            in={
              isTruthy(campaignClientState?.campaignName) &&
              isTruthy(campaignClientState?.campaignDescription)
            }
          >
            <div style={{width: '100%'}}>
              <AudienceDetails
                audiences={audiences}
                audiencesIsFetching={audiencesIsFetching}
                audiencesIsLoading={audiencesIsLoading}
                audienceAlreadyInUse={audienceAlreadyInUse}
                audienceCompaniesError={audienceCompaniesError}
                audiencePeopleError={audiencePeopleError}
                audienceIsValid={audienceIsValid}
                campaignClientState={campaignClientState}
                companiesSearchResultsIsFetching={companiesSearchResultsIsFetching}
                companiesSearchResultsIsLoading={companiesSearchResultsIsLoading}
                peopleSearchResults={peopleSearchResults}
                peopleSearchResultsIsFetching={peopleSearchResultsIsFetching}
                peopleSearchResultsIsLoading={peopleSearchResultsIsLoading}
                rankedPeopleSearchResults={rankedPeopleSearchResults}
                leadsRankResultsFetching={leadsRankResultsFetching}
                leadsRankResultsLoading={leadsRankResultsLoading}
                selectedAudience={selectedAudience}
                setCampaignClientStateKey={setCampaignClientStateKey}
                sortingInstructionsIsDebouncing={sortingInstructionsIsDebouncing}
              />
            </div>
          </Collapse>

          <Collapse in={audienceDetailsAreValid}>
            <div style={{width: '100%'}}>
              <CadenceDetails
                stepsAreValid={stepsAreValid}
                campaignClientState={campaignClientState}
                setCampaignClientStateKey={setCampaignClientStateKey}
                audiences={audiences}
                messageInstructions={messageInstructions}
                messageInstructionsIsLoading={messageInstructionsIsLoading}
                messageInstructionsIsFetching={messageInstructionsIsFetching}
              />
            </div>
          </Collapse>
          <Collapse in={audienceDetailsAreValid && stepsAreValid}>
            <div style={{width: '100%'}}>
              <SettingsDetails
                campaignClientState={campaignClientState}
                setCampaignClientStateKey={setCampaignClientStateKey}
                maxPeopleOutreachError={maxPeopleOutreachError}
                maxPeopleOutreachPerDayError={maxPeopleOutreachPerDayError}
                maxPeopleOutreachPerCompanyError={maxPeopleOutreachPerCompanyError}
                outreachInputIsValid={outreachInputIsValid}
                companiesToReach={companiesToReach}
                peopleToReach={peopleToReach}
                companiesToReachError={companiesToReachError}
                peopleToReachError={peopleToReachError}
                peopleInAudience={peopleInAudience}
                companiesInAudience={companiesInAudience}
              />
            </div>
          </Collapse>
          <Collapse in={audienceDetailsAreValid && stepsAreValid && outreachIsValid}>
            <div style={{width: '100%'}}>
              <SavingDetails
                handlePostCampaign={handlePostCampaign}
                postCampaignIsPending={
                  postCampaignIsPending ||
                  patchAudienceIsPending ||
                  patchMessageInstructionsIsPending
                }
              />
            </div>
          </Collapse>
        </Col>
      </MainTemplate>
    </>
  )
}
