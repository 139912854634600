// UTILS
import {findValueByKey} from '../../utils/helpers.js'

//ATOMS
import {Container} from 'react-bootstrap'
import {Heading} from '../atoms/Heading.js'
import {Text} from '../atoms/Text.js'

//MOLECULES
import {FakeBreadcrumbs} from '../molecules/FakeBreadcrumbs.js'

const FilterHeading = ({filter}) => (
  <Heading size={4}>
    {filter.eventKey.includes('Company') ? 'Company' : 'Person'} {filter.header}
  </Heading>
)

const FilterText = ({filter}) => {
  if (filter.options) {
    return filter.selectedValues.map((value) => (
      <Text key={value}>{findValueByKey(value, filter.options)}</Text>
    ))
  } else {
    return filter.selectedValues.map((value) => (
      <Text key={value}>{value}</Text>
    ))
  }
}

//ORGANISM
export const AudienceSummarySidebar = ({
  filters,
  workflowSteps,
  onWorkflowStepSelect
}) => {
  return (
    <Container
      fluid
      style={{
        display: 'flex',
        flexFlow: 'column wrap',
        padding: '0',
        alignItems: 'center'
      }}
    >
      <FakeBreadcrumbs
        workflowSteps={workflowSteps}
        onWorkflowStepSelect={onWorkflowStepSelect}
        style={{alignSelf: 'center', flexWrap: 'wrap'}}
      />
      {filters.map((filter, index) =>
        filter?.defaultValue || filter?.selectedValues?.length > 0 ? (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <FilterHeading filter={filter} />
            <FilterText filter={filter} />
          </div>
        ) : null
      )}
    </Container>
  )
}
