import {NavigationBar} from '../organisms/NavigationBar.js'
import {Row} from 'react-bootstrap'
import {BaseTemplate} from './BaseTemplate.js'

export const MainTemplate = ({children}) => {
  return (
    <BaseTemplate>
      <Row className='navBarRow'>
        <NavigationBar />
      </Row>
      <Row className='contentRow justify-content-md-center'>{children}</Row>
    </BaseTemplate>
  )
}
