//ATOMS
import {Form} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'
import {Text} from '../atoms/Text.js'

//MOLECULES
import {WarningIconWithTooltip} from './WarningIconWithTooltip.js'

export const DynamicCheckboxes = ({
  options = [], //[[value, label],]
  optionsLoading,
  optionsLoadingError,
  selectedValues = [],
  onChange
}) => {
  if (optionsLoading) {
    return <Spinner />
  }

  if (optionsLoadingError) {
    return <WarningIconWithTooltip message={optionsLoadingError.message} />
  }

  return (
    <Form>
      {options.map(([value, label]) => (
        <Form.Check
          key={value}
          label={
            <Text
              children={label}
              size={4}
            />
          }
          name={value}
          type='checkbox'
          checked={selectedValues.includes(value)}
          value={value}
          onChange={onChange}
          id={`checkbox-${value}`}
        />
      ))}
    </Form>
  )
}
