import {ListGroup} from 'react-bootstrap'

export const AudienceProgressBarLegend = ({skipped, sent, audienceSize}) => {
  const items = [
    {count: skipped || 0, label: 'Skipped', color: 'orange'},
    {count: sent || 0, label: 'Sent', color: 'green'},
    {count: audienceSize - sent - skipped || 0, label: 'Remaining', color: 'lightgray'}
  ]

  return (
    <ListGroup
      horizontal
      style={{display: 'flex', justifyContent: 'flex-end'}}
    >
      {items.map((item, index) => (
        <ListGroup.Item
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <p
            className='d-text__placement--center'
            style={{color: item.color}}
          >
            {item.count}
          </p>
          <p className='d-text__placement--center color--p-dark'>{item.label}</p>
        </ListGroup.Item>
      ))}
    </ListGroup>
  )
}
