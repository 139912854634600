import {Placeholder} from './Placeholder.js'

export const Heading = ({
  size = 4,
  maxLines = 1,
  children,
  style = {},
  isLoading = false
}) => {
  const Tag = `h${size}`

  const _style = {
    fontWeight: 'bold',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: maxLines,
    overflow: 'hidden',
    ...style
  }

  if (isLoading) {
    return <Placeholder style={{..._style, margin: '0.25em'}} />
  }

  return (
    <Tag
      className={`heading-size-${size}`}
      style={_style}
      children={children}
    />
  )
}
