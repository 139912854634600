import {selectorFamily} from 'recoil'
import {produce} from 'immer'

const splitPath = (path) => {
  if (typeof path !== 'string') {
    console.error('Path must be a string, received: ', path)
    return []
  }
  const pathParts = path.split('.')
  if (pathParts.length === 0) {
    console.error('Path length must be at least 1', path)
    return []
  }
  return pathParts
}

const traverseObjectWithPath = (obj, pathParts) => {
  return pathParts.reduce((acc, part, index) => {
    if (acc === undefined) {
      console.error(`Path resolution failed at '${pathParts.slice(0, index).join('.')}'`)
      return undefined
    }
    if (!(part in acc)) {
      console.error(`Key '${part}' not found. Scanning '${pathParts}'`)
      return undefined
    }
    return acc[part]
  }, obj)
}

const getNestedValue = (obj, path) => {
  const pathParts = splitPath(path)
  return traverseObjectWithPath(obj, pathParts)
}

const mutateNestedValue = (obj, path, newValue) => {
  const pathParts = splitPath(path)

  return produce(obj, (draft) => {
    let current = draft
    const lastKey = pathParts.pop()

    for (const key of pathParts) {
      if (!(key in current) || typeof current[key] !== 'object') {
        current[key] = {}
      }
      current = current[key]
    }

    current[lastKey] = newValue
  })
}

export const nestedSelectorFamily = selectorFamily({
  key: 'nestedSelector',
  get:
    ({atom, path}) =>
    ({get}) => {
      const state = get(atom)
      return getNestedValue(state, path)
    },
  set:
    ({atom, path}) =>
    ({get, set}, newValue) => {
      const currentState = get(atom)
      const nextState = mutateNestedValue(currentState, path, newValue)
      set(atom, nextState)
    }
})
