//Atoms
import {Stack, Modal, Form, Col, Row, FloatingLabel} from 'react-bootstrap'
import {useRef} from 'react'

import {Spinner} from '../atoms/Spinner.js'

//MOLECULES
import {ActionWithConfirmation} from '../molecules/ActionWithConfirmation.js'

//Utils
import {formatDate, formatUnderscoreString} from '../../utils/helpers.js'
import {useAutosizeTextArea} from '../../utils/hooks.js'

export const MessageInstructionsModal = ({
  show,
  onHide,
  label,
  title,
  companyName,
  valueProposition,
  intention,
  preferences,
  outreachMethod,
  outreachStage,
  messageSignature,
  language,
  updatedAt,
  onDelete,
  onDeleteIsPending,
  usedInCampaign,
  onEdit
}) => {
  const valuePropositionRef = useRef(null)
  const intentionRef = useRef(null)
  const preferencesRef = useRef(null)
  const messageSignatureRef = useRef(null)
  useAutosizeTextArea(messageSignatureRef, messageSignature, show)
  useAutosizeTextArea(valuePropositionRef, valueProposition, show)
  useAutosizeTextArea(intentionRef, intention, show)
  useAutosizeTextArea(preferencesRef, preferences, show)

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
    >
      <Modal.Header>
        <Stack
          direction='vertical'
          gap={0}
        >
          <h4>{label}</h4>
          <Stack
            direction='horizontal'
            gap={1}
          >
            <span className={'material-symbols-outlined color--s-dark'}>
              {'calendar_today'}
            </span>
            <small
              style={{
                fontStyle: 'italic',
                opacity: '0.7'
              }}
            >
              {formatDate(updatedAt)}
            </small>
          </Stack>
        </Stack>
        {onEdit && (
          <span
            className='material-symbols-outlined color--p-dark clickable transition-fast t-scale--small t-color--accent'
            onClick={onEdit}
          >
            {'edit_square'}
          </span>
        )}
        {usedInCampaign && (
          <span
            title='Message instructions are used in a campaign'
            style={{color: 'green'}}
            className='material-symbols-outlined'
          >
            {'campaign'}
          </span>
        )}
        {onDelete && !onDeleteIsPending && (
          <ActionWithConfirmation
            callback={() => onDelete()}
            disabled={usedInCampaign}
          />
        )}
        {onDeleteIsPending && <Spinner size='sm' />}
      </Modal.Header>
      <Modal.Body>
        <Form className='d-form'>
          <Form.Group
            className='stretch-stack'
            as={Row}
          >
            <Form.Group as={Col}>
              <FloatingLabel label='Your Title'>
                <Form.Control
                  value={title}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col}>
              <FloatingLabel label='Your Company Name'>
                <Form.Control
                  value={companyName}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>
          </Form.Group>

          <Form.Group as={Col}>
            <FloatingLabel label='Your value proposition'>
              <Form.Control
                as='textarea'
                value={valueProposition}
                ref={valuePropositionRef}
                readOnly
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col}>
            <FloatingLabel label={"What's your intention?"}>
              <Form.Control
                as='textarea'
                value={intention}
                ref={intentionRef}
                readOnly
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col}>
            <FloatingLabel label={'What are your preferences?'}>
              <Form.Control
                as='textarea'
                value={` - ${preferences?.join('\n - ')}`}
                ref={preferencesRef}
                readOnly
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group as={Col}>
            <FloatingLabel
              label={'Language'}
              required
            >
              <Form.Control
                value={language}
                readOnly
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group
            className='stretch-stack'
            as={Row}
          >
            <Form.Group as={Col}>
              <FloatingLabel label={'Outreach Method'}>
                <Form.Control
                  value={formatUnderscoreString(outreachMethod)}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col}>
              <FloatingLabel label={'Outreach Stage'}>
                <Form.Control
                  value={formatUnderscoreString(outreachStage)}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>
          </Form.Group>

          <Form.Group
            className='stretch-stack'
            as={Row}
          >
            <Form.Group as={Col}>
              <FloatingLabel label={'Message Signature'}>
                <Form.Control
                  as='textarea'
                  ref={messageSignatureRef}
                  value={messageSignature}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
