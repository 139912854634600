export const Text = ({size = 4, children, maxLines = 3, style = {}}) => {
  return (
    <p
      className={`text-size-${size}`}
      style={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxLines,
        overflow: 'hidden',
        margin: '0px',
        textOverflow: 'ellipsis',
        ...style
      }}
    >
      {children}
    </p>
  )
}
