//ATOMS
import {Stack, ListGroup} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'

import {ReactComponent as LinkedInIcon} from '../atoms/assets/linkedin.svg'
//HOOKS
import {getOutreachStatusLabel, getSkipReasonLabel} from '../../utils/helpers.js'

export const PersonSearchResults = ({
  personOutreachTracking,
  isFetching,
  isLoading,
  hasNextPage,
  fetchNextPage,
  setActivePerson
}) => {
  return (
    <ListGroup
      variant='flush'
      className='results'
    >
      {isLoading && (
        <ListGroup.Item
          key={'company-results-fetching'}
          className='d-flex justify-content-center align-items-center'
        >
          <Spinner
            size='sm'
            animation='grow'
          />
        </ListGroup.Item>
      )}
      {personOutreachTracking?.map((person) => (
        <ListGroup.Item
          key={person?.outreach_id}
          className='d-flex align-items-center'
        >
          <Stack
            direction='vertical'
            gap={0}
          >
            <h6
              className='d-text__font--heading color--s-dark'
              style={{margin: 0}}
            >{`${person?.first_name} ${person?.last_name}`}</h6>
            <small>
              {person?.skip_reason
                ? `${getOutreachStatusLabel(
                    person?.outreach_status
                  )} - ${getSkipReasonLabel(person?.skip_reason)}`
                : getOutreachStatusLabel(person?.outreach_status)}
            </small>
          </Stack>
          <div className='p-2 ms-auto' />
          {person?.message_body && (
            <span
              className={'material-symbols-outlined color--accent clickable'}
              onClick={() => setActivePerson(person)}
              style={{marginRight: '1rem'}}
            >
              {'outgoing_mail'}
            </span>
          )}
          {person?.person_linkedin_id && (
            <LinkedInIcon
              style={{
                cursor: 'pointer',
                fill: '#0a66c2',
                maxWidth: '1rem',
                minWidth: '1rem',
                opacity: '0.9'
              }}
              onClick={() =>
                window.open(
                  `https://www.linkedin.com/in/${person?.person_linkedin_id}`,
                  '_blank'
                )
              }
            />
          )}
        </ListGroup.Item>
      ))}
      {isLoading ? null : (
        <ListGroup.Item
          className={hasNextPage ? 'clickable transition-fast t-color--accent' : ''}
          key='company-results-load-more'
          style={{
            textAlign: 'center',
            textDecoration: 'underline',
            textDecorationColor: 'var(--accent)'
          }}
          disabled={!hasNextPage}
          onClick={fetchNextPage}
        >
          {isFetching ? (
            <Spinner
              size='sm'
              animation='grow'
            />
          ) : (
            'Load More'
          )}
        </ListGroup.Item>
      )}
    </ListGroup>
  )
}

export const CompanySearchResults = ({
  companyOutreachTracking,
  isFetching,
  isLoading,
  hasNextPage,
  fetchNextPage
}) => {
  return (
    <ListGroup
      variant='flush'
      className='results'
    >
      {isLoading && (
        <ListGroup.Item
          key={'company-results-fetching'}
          className='d-flex justify-content-center align-items-center'
        >
          <Spinner
            size='sm'
            animation='grow'
          />
        </ListGroup.Item>
      )}
      {companyOutreachTracking?.map((company) => (
        <ListGroup.Item
          key={company?.outreach_id}
          className='d-flex align-items-center'
        >
          <Stack
            direction='vertical'
            gap={0}
          >
            <h6
              className='d-text__font--heading color--s-dark'
              style={{margin: 0}}
            >
              {company?.name || ''}
            </h6>
            <small>
              {company?.skip_reason
                ? `${getOutreachStatusLabel(
                    company?.outreach_status
                  )} - ${getSkipReasonLabel(company?.skip_reason)}`
                : getOutreachStatusLabel(company?.outreach_status)}
            </small>
          </Stack>
          <div className='p-2 ms-auto' />
          {company?.domain && (
            <span
              className={
                'material-symbols-outlined color--s-dark clickable transition-fast t-color--accent'
              }
              onClick={() => window.open(`https://www.${company?.domain}`, '_blank')}
              style={{marginRight: '1rem'}}
            >
              {'travel_explore'}
            </span>
          )}
          {company?.linkedin_id && (
            <LinkedInIcon
              style={{
                cursor: 'pointer',
                maxWidth: '1rem',
                minWidth: '1rem',
                fill: '#0a66c2',
                opacity: '0.9'
              }}
              onClick={() =>
                window.open(
                  `https://www.linkedin.com/company/${company?.linkedin_id}`,
                  '_blank'
                )
              }
            />
          )}
        </ListGroup.Item>
      ))}
      {isLoading ? null : (
        <ListGroup.Item
          className={hasNextPage ? 'clickable transition-fast t-color--accent' : ''}
          key='company-results-load-more'
          style={{
            textAlign: 'center',
            textDecoration: 'underline',
            textDecorationColor: 'var(--accent)'
          }}
          disabled={!hasNextPage}
          onClick={fetchNextPage}
        >
          {isFetching ? (
            <Spinner
              size='sm'
              animation='grow'
            />
          ) : (
            'Load More'
          )}
        </ListGroup.Item>
      )}
    </ListGroup>
  )
}
