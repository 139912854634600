import {Image as BootstrapImage} from 'react-bootstrap'
import {useState} from 'react'

export const Image = ({
  src,
  className,
  fluid = true,
  thumbnail = false,
  rounded = false,
  style = {}
}) => {
  const [imageSrc, setImageSrc] = useState(src)

  return (
    <BootstrapImage
      src={imageSrc}
      alt={imageSrc}
      fluid={fluid}
      className={className}
      thumbnail={thumbnail}
      rounded={rounded}
      style={{
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        ...style
      }}
    />
  )
}
