//ATOMS
import {
  Form,
  Row,
  Col,
  Stack,
  FloatingLabel,
  Container,
  Nav,
  OverlayTrigger,
  Collapse,
  Tooltip,
  InputGroup
} from 'react-bootstrap'
import {useRef, useState, useEffect} from 'react'
import {Button} from '../atoms/Button.js'
import {Spinner} from '../atoms/Spinner.js'

//MOLECULES
import {SelectCreateOptions} from '../molecules/SelectCreateOptions.js'
import {DynamicInputGroup} from '../molecules/DynamicInputGroup.js'

//UTILS
import {useAutosizeTextArea} from '../../utils/hooks.js'
import {InputWrapper} from '../../utils/hooks.js'

const PersonInformationForm = ({
  messageSignatureOnChange,
  messageSignatureValue,
  firstName,
  firstNameIsFetching,
  firstNameError,
  languageOnChange,
  languageValue,
  lastName,
  lastNameError,
  lastNameIsFetching,
  patchMessageInstructionsIsPending,
  postMessageInstructionsIsPending,
  supportedLanguages,
  supportedLanguagesIsFetching,
  titleOnChange,
  titleValue,
  titleError
}) => {
  const [showMessageSignature, setShowMessageSignature] = useState(false)
  const messageSignatureRef = useRef(null)
  useAutosizeTextArea(messageSignatureRef, messageSignatureValue)

  useEffect(() => {
    if (messageSignatureValue?.length > 0) {
      setShowMessageSignature(true)
    }
  }, [messageSignatureValue, setShowMessageSignature])

  return (
    <Form>
      <Row>
        <InputWrapper
          tooltipMessage={firstNameError}
          tooltipId={'first-name-tooltip'}
        >
          <InputGroup as={Col}>
            <FloatingLabel label='First Name'>
              <Form.Control
                type='text'
                value={firstName || ''}
                className={!!firstNameError ? 'invalidInput' : ''}
                style={{borderRight: 'none'}}
                readOnly
              />
            </FloatingLabel>
            <InputGroup.Text className={!!firstNameError ? 'invalidInput' : ''}>
              {firstNameIsFetching && (
                <Spinner
                  size='sm'
                  animation='grow'
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        </InputWrapper>
        <InputWrapper
          tooltipMessage={lastNameError}
          tooltipId={'last-name-tooltip'}
        >
          <InputGroup as={Col}>
            <FloatingLabel label='Last Name'>
              <Form.Control
                type='text'
                value={lastName || ''}
                className={!!lastNameError ? 'invalidInput' : ''}
                style={{borderRight: 'none'}}
                readOnly
              />
            </FloatingLabel>
            <InputGroup.Text className={!!lastNameError ? 'invalidInput' : ''}>
              {lastNameIsFetching && (
                <Spinner
                  size='sm'
                  animation='grow'
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        </InputWrapper>
      </Row>

      <Row>
        <InputWrapper
          tooltipMessage={titleError}
          tooltipId={'job-title-tooltip'}
        >
          <InputGroup as={Col}>
            <FloatingLabel label='Your Job Title'>
              <Form.Control
                className={!!titleError ? 'invalidInput' : ''}
                type='text'
                value={titleValue || ''}
                placeholder=''
                onChange={titleOnChange}
                style={{borderRight: 'none'}}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
              />
            </FloatingLabel>
            <InputGroup.Text
              style={{borderLeft: 'none'}}
              className={!!titleError ? 'invalidInput' : ''}
            >
              {(patchMessageInstructionsIsPending ||
                postMessageInstructionsIsPending) && (
                <Spinner
                  size='sm'
                  animation='grow'
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        </InputWrapper>
        <InputGroup as={Col}>
          <InputWrapper isLoading={supportedLanguagesIsFetching}>
            <FloatingLabel label='Preferred Language'>
              <Form.Select
                value={languageValue}
                onChange={languageOnChange}
              >
                {supportedLanguages?.map((i) => (
                  <option
                    key={i.language}
                    value={i.language}
                  >
                    {i.language}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </InputWrapper>
        </InputGroup>
      </Row>

      <Row>
        <InputGroup as={Col}>
          <Form.Check
            type='switch'
            id='custom-switch'
            label='Custom Email Signature'
            reverse
            checked={showMessageSignature}
            onChange={() => {
              setShowMessageSignature(!showMessageSignature)
              messageSignatureOnChange(null)
            }}
          />
        </InputGroup>
      </Row>
      <Collapse in={showMessageSignature}>
        <Row>
          <InputGroup as={Col}>
            <FloatingLabel label='Email Signature'>
              <Form.Control
                as='textarea'
                ref={messageSignatureRef}
                name='messageSignature'
                placeholder=''
                value={messageSignatureValue || ''}
                onChange={messageSignatureOnChange}
              />
            </FloatingLabel>
          </InputGroup>
        </Row>
      </Collapse>
    </Form>
  )
}

const CompanyInformationForm = ({
  companyNameOnChange,
  companyNameValue,
  companyNameError,
  patchMessageInstructionsIsPending,
  postMessageInstructionsIsPending,
  valuePropositionOnChange,
  valuePropositionValue,
  valuePropositionError
}) => {
  const valuePropositionRef = useRef(null)
  useAutosizeTextArea(valuePropositionRef, valuePropositionValue)

  return (
    <Form>
      <Row>
        <InputWrapper
          tooltipMessage={companyNameError}
          tooltipId={'company-name-tooltip'}
        >
          <InputGroup as={Col}>
            <FloatingLabel label='Your Company Name'>
              <Form.Control
                type='text'
                value={companyNameValue || ''}
                placeholder=''
                onChange={companyNameOnChange}
                className={!!companyNameError ? 'invalidInput' : ''}
                style={{borderRight: 'none'}}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
              />
            </FloatingLabel>
            <InputGroup.Text className={!!companyNameError ? 'invalidInput' : ''}>
              {(patchMessageInstructionsIsPending ||
                postMessageInstructionsIsPending) && (
                <Spinner
                  size='sm'
                  animation='grow'
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        </InputWrapper>
      </Row>

      <Row>
        <InputWrapper
          tooltipMessage={valuePropositionError}
          tooltipId={'value-proposition-tooltip'}
        >
          <InputGroup as={Col}>
            <FloatingLabel label='Your Value Proposition'>
              <Form.Control
                as='textarea'
                ref={valuePropositionRef}
                name='valueProposition'
                placeholder=''
                value={valuePropositionValue || ''}
                onChange={valuePropositionOnChange}
                className={!!valuePropositionError ? 'invalidInput' : ''}
                style={{borderRight: 'none'}}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
              />
            </FloatingLabel>
            <InputGroup.Text className={!!valuePropositionError ? 'invalidInput' : ''}>
              {(patchMessageInstructionsIsPending ||
                postMessageInstructionsIsPending) && (
                <Spinner
                  size='sm'
                  animation='grow'
                />
              )}
            </InputGroup.Text>
            <InputGroup.Text
              style={{borderLeft: 'none'}}
              className={!!valuePropositionError ? 'invalidInput' : ''}
            >
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='value-proposition-help-tooltip'>
                    What value do you provide your customers?
                    <br /> Example: Pitchr is a B2B sales automation platform, enabling
                    businesses to reach their ideal customers at scale through
                    leveraging latest technologies.
                  </Tooltip>
                }
              >
                <span className='material-symbols-outlined color--s-dark'>
                  {'help'}
                </span>
              </OverlayTrigger>
            </InputGroup.Text>
          </InputGroup>
        </InputWrapper>
      </Row>
    </Form>
  )
}

const MessageInformationForm = ({
  intentionOnChange,
  intentionValue,
  intentionError,
  inputValidator,
  outreachMethodOnChange,
  outreachMethodValue,
  outreachStageOnChange,
  outreachStageValue,
  patchMessageInstructionsIsPending,
  postMessageInstructionsIsPending,
  preferencesValues,
  preferencesValuesOnChange
}) => {
  const intentionRef = useRef(null)
  useAutosizeTextArea(intentionRef, intentionValue)

  return (
    <Form>
      <Row>
        <InputGroup as={Col}>
          <FloatingLabel label='Outreach Method'>
            <Form.Select
              placeholder=''
              onChange={outreachMethodOnChange}
              value={outreachMethodValue}
            >
              <option value='email'>Email</option>
            </Form.Select>
          </FloatingLabel>
        </InputGroup>
        <InputGroup as={Col}>
          <FloatingLabel label='Outreach Stage'>
            <Form.Select
              placeholder=''
              onChange={outreachStageOnChange}
              value={outreachStageValue}
            >
              <option value='initial_message'>Initial Message</option>
              <option value='follow_up_message'>Follow-Up Message</option>
            </Form.Select>
          </FloatingLabel>
        </InputGroup>
      </Row>

      <Row>
        <InputWrapper
          tooltipMessage={intentionError}
          tooltipId={'intention-tooltip'}
        >
          <InputGroup as={Col}>
            <FloatingLabel label='Your Intention'>
              <Form.Control
                as='textarea'
                ref={intentionRef}
                name='intention'
                placeholder=''
                value={intentionValue || ''}
                onChange={intentionOnChange}
                style={{borderRight: 'none'}}
                className={!!intentionError ? 'invalidInput' : ''}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    e.target.blur()
                  }
                }}
              />
            </FloatingLabel>
            <InputGroup.Text className={!!intentionError ? 'invalidInput' : ''}>
              {(patchMessageInstructionsIsPending ||
                postMessageInstructionsIsPending) && (
                <Spinner
                  size='sm'
                  animation='grow'
                />
              )}
            </InputGroup.Text>
            <InputGroup.Text
              style={{borderLeft: 'none'}}
              className={!!intentionError ? 'invalidInput' : ''}
            >
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip id='intention-help-tooltip'>
                    What would you like to achieve with the message?
                    <br /> Example: Invite the prospect to a product demonstration.
                  </Tooltip>
                }
              >
                <span className='material-symbols-outlined color--s-dark'>
                  {'help'}
                </span>
              </OverlayTrigger>
            </InputGroup.Text>
          </InputGroup>
        </InputWrapper>
      </Row>

      <Container
        fluid
        style={{
          marginTop: '0.5rem',
          border: 'none'
        }}
      >
        <h5 style={{textAlign: 'center', paddingBottom: 0, marginBottom: 0}}>
          Your Message Preferences
        </h5>
        <Container
          fluid
          className='formContainer'
          style={{
            maxHeight: '25rem',
            paddingTop: 0,
            marginTop: 0,
            border: 'none'
          }}
        >
          <InputGroup
            as={Row}
            style={{
              paddingTop: 0,
              marginTop: 0
            }}
          >
            <DynamicInputGroup
              onChange={preferencesValuesOnChange}
              values={preferencesValues}
              inputValidator={inputValidator}
            />
          </InputGroup>
        </Container>
      </Container>
    </Form>
  )
}

const InstructionsInformationForm = ({
  idValue,
  labelValue,
  labelOnChange,
  patchMessageInstructions,
  patchMessageInstructionsIsPending,
  postMessageInstructions,
  postMessageInstructionsIsPending,
  messageInstructions,
  messageInstructionsIsFetching,
  loadMessageInstructions,
  allInputInformationIsCorrect
}) => {
  const messageInstructionsOptions = messageInstructions?.map((m) => ({
    label: m?.label,
    value: m?.message_instructions_id
  }))
  const messageInstructionsValue = {value: idValue, label: labelValue}
  const messageInstructionsExist = messageInstructions?.some(
    (messageInstruction) => messageInstruction?.label === labelValue
  )

  return (
    <Form>
      <InputGroup as={Row}>
        <Form.Label
          column
          xs='auto'
        >
          Manage Your Instructions
        </Form.Label>
        <Col>
          <SelectCreateOptions
            options={messageInstructionsOptions}
            optionsLoading={messageInstructionsIsFetching}
            onChange={labelOnChange}
            value={messageInstructionsValue}
          />
        </Col>
        <Form.Group
          as={Col}
          xs='auto'
        >
          {messageInstructionsExist ? (
            <Stack
              direction='horizontal'
              gap={2}
            >
              <Button
                children={'Update'}
                onClick={patchMessageInstructions}
                disabled={!allInputInformationIsCorrect}
                disabledText={
                  !allInputInformationIsCorrect
                    ? 'Please review your instructions'
                    : null
                }
                isLoading={patchMessageInstructionsIsPending}
              />
              <Button
                children={'Load'}
                onClick={() => {
                  loadMessageInstructions()
                }}
              />
            </Stack>
          ) : (
            <Button
              children={'Save'}
              disabledText={
                !allInputInformationIsCorrect
                  ? 'Please review your instructions'
                  : !messageInstructionsValue?.label ||
                    messageInstructionsValue.label.length < 1
                  ? 'Saving your instructions requires providing a name'
                  : null
              }
              disabled={
                !allInputInformationIsCorrect ||
                !messageInstructionsValue?.label ||
                messageInstructionsValue?.label?.length < 1
              }
              onClick={postMessageInstructions}
              isLoading={postMessageInstructionsIsPending}
            />
          )}
        </Form.Group>
      </InputGroup>
    </Form>
  )
}

export const MessageInstructionsInput = ({
  allInputInformationIsCorrect,
  companyInformationInputIsCorrect,
  companyNameError,
  companyNameOnChange,
  companyNameValue,
  messageSignatureOnChange,
  messageSignatureValue,
  firstName,
  firstNameError,
  firstNameIsFetching,
  idValue,
  inputValidator,
  intentionError,
  intentionOnChange,
  intentionValue,
  labelOnChange,
  labelValue,
  languageOnChange,
  languageValue,
  lastName,
  lastNameError,
  lastNameIsFetching,
  loadMessageInstructions,
  messageInformationInputIsCorrect,
  outreachMethodOnChange,
  outreachMethodValue,
  outreachStageOnChange,
  outreachStageValue,
  patchMessageInstructions,
  patchMessageInstructionsIsPending,
  personInformationInputIsCorrect,
  postMessageInstructions,
  postMessageInstructionsIsPending,
  preferencesValues,
  preferencesValuesOnChange,
  supportedLanguages,
  supportedLanguagesIsFetching,
  messageInstructions,
  messageInstructionsIsFetching,
  titleError,
  titleOnChange,
  titleValue,
  valuePropositionError,
  valuePropositionOnChange,
  valuePropositionValue
}) => {
  const [activeTab, setActiveTab] = useState('personInformation')

  return (
    <Container
      fluid
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '1rem'
      }}
    >
      <h2>Instructions</h2>
      <Container
        fluid
        className='searchContainer'
        style={{padding: 0}}
      >
        <Nav
          variant='tabs'
          activeKey={activeTab}
          onSelect={(k) => {
            setActiveTab(k)
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey='personInformation'>
              <span
                className='material-symbols-outlined color--s-dark'
                style={{fontSize: '2rem'}}
              >
                {'person'}
              </span>
              {personInformationInputIsCorrect && (
                <span
                  className='material-symbols-outlined'
                  style={{fontSize: '2rem', color: 'green'}}
                >
                  {'check'}
                </span>
              )}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='companyInformation'>
              <span
                className='material-symbols-outlined color--s-dark'
                style={{fontSize: '2rem'}}
              >
                {'category'}
              </span>
              {companyInformationInputIsCorrect && (
                <span
                  className='material-symbols-outlined'
                  style={{fontSize: '2rem', color: 'green'}}
                >
                  {'check'}
                </span>
              )}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='messageInformation'>
              <span
                className='material-symbols-outlined color--s-dark'
                style={{fontSize: '2rem'}}
              >
                {'tune'}
              </span>
              {messageInformationInputIsCorrect && (
                <span
                  className='material-symbols-outlined'
                  style={{fontSize: '2rem', color: 'green'}}
                >
                  {'check'}
                </span>
              )}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='instructionsInformation'>
              <span
                className='material-symbols-outlined color--s-dark'
                style={{fontSize: '2rem'}}
              >
                {'bookmark'}
              </span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Container
          fluid
          className='formContainer'
          style={{borderTop: 'none'}}
        >
          {activeTab === 'personInformation' && (
            <>
              <PersonInformationForm
                messageSignatureOnChange={messageSignatureOnChange}
                messageSignatureValue={messageSignatureValue}
                firstName={firstName}
                firstNameIsFetching={firstNameIsFetching}
                firstNameError={firstNameError}
                lastName={lastName}
                lastNameIsFetching={lastNameIsFetching}
                lastNameError={lastNameError}
                titleOnChange={titleOnChange}
                titleValue={titleValue}
                titleError={titleError}
                supportedLanguages={supportedLanguages}
                supportedLanguagesIsFetching={supportedLanguagesIsFetching}
                languageOnChange={languageOnChange}
                languageValue={languageValue}
              />
            </>
          )}
          {activeTab === 'companyInformation' && (
            <CompanyInformationForm
              companyNameOnChange={companyNameOnChange}
              companyNameValue={companyNameValue}
              companyNameError={companyNameError}
              valuePropositionOnChange={valuePropositionOnChange}
              valuePropositionValue={valuePropositionValue}
              valuePropositionError={valuePropositionError}
            />
          )}
          {activeTab === 'messageInformation' && (
            <MessageInformationForm
              intentionOnChange={intentionOnChange}
              intentionValue={intentionValue}
              intentionError={intentionError}
              outreachMethodOnChange={outreachMethodOnChange}
              outreachMethodValue={outreachMethodValue}
              outreachStageOnChange={outreachStageOnChange}
              outreachStageValue={outreachStageValue}
              preferencesValues={preferencesValues}
              preferencesValuesOnChange={preferencesValuesOnChange}
              inputValidator={inputValidator}
            />
          )}
          {activeTab === 'instructionsInformation' && (
            <InstructionsInformationForm
              idValue={idValue}
              labelValue={labelValue}
              labelOnChange={labelOnChange}
              patchMessageInstructions={patchMessageInstructions}
              patchMessageInstructionsIsPending={patchMessageInstructionsIsPending}
              postMessageInstructions={postMessageInstructions}
              postMessageInstructionsIsPending={postMessageInstructionsIsPending}
              messageInstructions={messageInstructions}
              messageInstructionsIsFetching={messageInstructionsIsFetching}
              loadMessageInstructions={loadMessageInstructions}
              allInputInformationIsCorrect={allInputInformationIsCorrect}
            />
          )}
        </Container>
      </Container>
    </Container>
  )
}
