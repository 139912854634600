import {useState} from 'react'

//ATOMS
import {Container, ListGroup} from 'react-bootstrap'
import BuildingIcon from '../atoms/assets/building.svg'
import {Link} from '../atoms/Link.js'

//Molecules
import {WarningIconWithTooltip} from '../molecules/WarningIconWithTooltip.js'
import {PlaceholderRow} from '../molecules/PlaceholderRow.js'
import {CompanyRowItem} from '../molecules/CompanyRowItem.js'
import {MoreInfoCanvas} from '../molecules/MoreInfoCanvas.js'

export const CompanySearchResults = ({
  isLoading,
  error,
  searchResults,
  loadMoreCallback,
  hasNextPage,
  isFetchingNextPage
}) => {
  const [showCanvas, setShowCanvas] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(false)
  if (isLoading) {
    return (
      <ListGroup variant='flush'>
        {[...Array(10)].map((_, index) => (
          <ListGroup.Item key={index}>
            <PlaceholderRow
              minHeight={'5rem'}
              maxHeight={'8rem'}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    )
  }

  if (error) {
    return <WarningIconWithTooltip message={error.message} />
  }

  if (searchResults) {
    return (
      <Container
        fluid
        style={{display: 'flex', flexDirection: 'column', padding: 0}}
      >
        <ListGroup variant='flush'>
          {searchResults.map((entry) => (
            <ListGroup.Item key={entry.id}>
              <CompanyRowItem
                companylogo={entry?.logo || BuildingIcon}
                companyName={entry?.name}
                companyDescriptionShort={entry?.descriptionShort}
                companyRevenue={entry?.revenue}
                companyEmployees={entry?.totalEmployeesExact || entry?.totalEmployees}
                companyCountry={entry?.country?.name}
                companyIndustry={entry?.industryMain}
                companyDomain={entry?.domain}
                companyFacebook={entry?.socialNetworks?.facebook}
                companyInstagram={entry?.socialNetworks?.instagram}
                companyLinkedin={entry?.socialNetworks?.linkedin}
                companyTwitter={entry?.socialNetworks?.twitter}
                companyYoutube={entry?.socialNetworks?.youtube}
                onClick={() => {
                  setShowCanvas(true)
                  setSelectedCompany(entry)
                }}
              />
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Link
          isLoading={isFetchingNextPage}
          children={'Load More'}
          onClick={loadMoreCallback}
          disabled={!hasNextPage}
          disabledMessage='No more results available'
          styles={{
            textDecoration: 'underline',
            textDecorationColor: 'var(--accent)'
          }}
        />
        <MoreInfoCanvas
          show={showCanvas}
          onHide={() => setShowCanvas(false)}
          headerImage={selectedCompany?.logo || BuildingIcon}
          headerText={selectedCompany?.name}
          headerSubText={
            selectedCompany?.yearFounded
              ? `Founded ${selectedCompany?.yearFounded}`
              : ''
          }
          bodyText={selectedCompany?.description}
        />
      </Container>
    )
  }
}
