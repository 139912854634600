import {useState} from 'react'
import {useNavigate, Link} from 'react-router-dom'

import {Form, Row, Col, FloatingLabel, InputGroup, Stack} from 'react-bootstrap'
import {Paper, Divider} from '@mui/material'

import {Button} from '../../atoms/Button.js'

import {InfoPageTemplate} from '../../templates/InfoPageTemplate.js'

import {useSendPasswordResetEmail} from '../../../hooks/server/useUserServerState.js'

export const ForgotPassword = () => {
  const navigate = useNavigate()

  const [emailAddress, setEmailAddress] = useState('')
  const {mutate: sendPasswordResetEmail, isPending: sendPasswordResetEmailIsPending} =
    useSendPasswordResetEmail()

  return (
    <InfoPageTemplate
      showBanner={false}
      showFooter={false}
      showNavBar={false}
    >
      <Row
        className='w-100 vh-100'
        id='sign-in'
      >
        <Col
          xs={12}
          sm={6}
          className='background--s-light d-flex justify-content-center align-items-center'
        >
          <Paper
            elevation={1}
            style={{
              padding: '3rem',
              minWidth: '24rem',
              border: '1px solid var(--primary-light)'
            }}
            className='background--p-light d-flex flex-column justify-content-center transition-slow t-border-color--accent'
          >
            <h2 className='d-text__font--heading color--p-dark'>Reset Password</h2>
            <hr className='border-color--s-dark' />
            <Form className='d-form'>
              <InputGroup>
                <FloatingLabel label={'Email'}>
                  <Form.Control
                    controlId='emailAddress'
                    type='email'
                    placeholder='hello@pitchr.co'
                    value={emailAddress}
                    required
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </FloatingLabel>
              </InputGroup>
            </Form>

            <hr className='border-color--s-dark' />

            <Button
              children={'Reset Password'}
              isLoading={sendPasswordResetEmailIsPending}
              type='button'
              onClick={() => {
                sendPasswordResetEmail({emailAddress})
              }}
              variant='outline-primary'
              style={{margin: '1rem'}}
            />

            <Divider
              className='color--s-dark'
              style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}
            >
              OR
            </Divider>

            <Stack
              direction='horizontal'
              gap={1}
            >
              <Link
                to='/sign-in'
                children={'Sign In'}
              />
              <p className='d-text__font--subheading d-text__placement--center color--s-dark'>
                /
              </p>
              <Link
                to='/sign-up'
                children={'Register'}
              />
            </Stack>
          </Paper>
        </Col>
        <Col
          xs={12}
          sm={6}
          className='background--p-light d-flex flex-column justify-content-center align-items-center align-items-sm-start'
        >
          <h1 className='d-text__font--heading color--p-dark'>
            Don't Worry, It Happens...
          </h1>
          <h3
            className='d-text__font--subheading color--p-dark clickable transition-fast t-color--accent d-flex align-items-center'
            onClick={() => navigate('/contact#contact-us')}
          >
            Stuck? Feel free to reach out
            <span
              className={'material-symbols-outlined'}
              style={{fontSize: '2.074rem'}}
            >
              {'arrow_right_alt'}
            </span>
          </h3>
        </Col>
      </Row>
    </InfoPageTemplate>
  )
}
