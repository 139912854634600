import {Row, Col, Container} from 'react-bootstrap'
import {Spinner} from '../atoms/Spinner.js'
import {Placeholder} from '../atoms/Placeholder.js'

export const PlaceholderRow = ({minHeight, maxHeight}) => {
  return (
    <Container fluid>
      <Row style={{maxHeight, minHeight}}>
        <Col
          xs={1}
          style={{display: 'flex'}}
        >
          <Spinner style={{alignSelf: 'center'}} />
        </Col>
        <Col style={{display: 'flex'}}>
          <Placeholder style={{alignSelf: 'center'}} />
        </Col>
      </Row>
    </Container>
  )
}
