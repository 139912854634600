import {Row, Col} from 'react-bootstrap'
import {NavigationBar} from '../organisms/NavigationBar.js'
import {BaseTemplate} from './BaseTemplate.js'

export const SidebarTemplate = ({sidebar, content}) => {
  return (
    <BaseTemplate>
      <Row className='navBarRow'>
        <NavigationBar />
      </Row>

      <Row className='contentRow'>
        <Col
          xs={4}
          lg={3}
          xxl={2}
          className='contentCol'
        >
          {sidebar}
        </Col>
        <Col
          xs={'auto'}
          className='contentCol'
        >
          <div
            className='vr'
            style={{height: '100%'}}
          />
        </Col>

        <Col className='contentCol'>{content}</Col>
      </Row>
    </BaseTemplate>
  )
}
